import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { MaintenanceProvider } from "./context/MaintenanceContext";

ReactDOM.render(
  <React.StrictMode>
    <MaintenanceProvider>
      <App />
    </MaintenanceProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
