import React, { useContext, useRef, useMemo } from "react";
import { DayUseContext } from "../../../../context/DayUseContext";
import Button from "../../../../shared/Button/Button";
import Input from "../../../../components/Input/Input";
import SingleSelect from "../../../../components/SingleSelect/SingleSelect";
import LoginFormLogic from "./LoginFormLogic";
import WindowWidth from "../../../../hooks/WindowWidth";
import { Logos } from "../../../../assets/SVGIcons";
import BlurryWrapper from "../../../../components/BlurryWrapper/BlurryWrapper";

import styles from "./LoginForm.module.scss";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";

const BlurryWrapperStyle = {
  desktop: {
    width: "490px",
    left: "0px",
    top: "0px",
    bottom: "0px",
  },
  mobile: {
    left: "5%",
    right: "5%",
    top: "20px",
  },
};

const LoginForm = () => {
  const { width } = WindowWidth();
  const ref = useRef(null);
  const { loginPageData, dayUseHotelData, dayUseTexts } = useContext(DayUseContext);

  const {
    setDayUseHotel,
    showExperiences,
    showViewCancelDayUseBooking,
    dayUseHotel,
  } = LoginFormLogic();

  const filteredAndSortedHotels = useMemo(() => {
    return dayUseHotelData
      ?.filter((hotel) => hotel.AllowDayUseAppearance)
      .sort((a, b) => a.Title.localeCompare(b.Title));
  }, [dayUseHotelData]);

  const getDayUseView = () => {
    if (dayUseHotelData?.length === 1) {
      if (!dayUseHotel) {
        setDayUseHotel(dayUseHotelData[0]);
        showExperiences(dayUseHotelData[0]);
      }
    } else {
      return (
        <div
          className={styles.LoginForm}
          style={{
            marginTop: "0",
          }}
          ref={ref}
        >
          <Input
            txt={"Hotel"}
            icon={"Icon awesome-hotel.svg"}
            labelNewStyles={{ paddingBottom: "8px" }}
            tooltipContent={loginPageData.HotelTooltip}
            tooltipNewStyles={{
              left: width < 500 ? "-75px" : "0",
              width: width < 375 ? "235px" : "300px",
            }}
          >
            <SingleSelect
              data={filteredAndSortedHotels}
              placeholder="Select your location"
              value={dayUseHotel}
              onChange={(event) => setDayUseHotel(event.target.value)}
            />
          </Input>
          <div
            className={styles.LoginForm__Submit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginLeft: "0",
            }}
          >
            <div
              className={`${styles.LoginForm__Submit_btn}`}
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                txt={"Show experiences"}
                onClick={() => showExperiences()}
                backgroundColor={"var(--ion-color-primary)"}
                newStyles={{ border: "none" }}
                minHeight={55}
                height={55}
                opacity={!dayUseHotel ? "0.4" : 1}
                pointerEvents={!dayUseHotel && "none"}
              />
            </div>

            <div
              className={styles.LoginForm__Submit_btn}
              style={{ justifyContent: "center", marginTop: "20px" }}
            >
              <p
                style={{
                  color: "var(--ion-color-primary)",
                  font: "normal normal 600 18px/22px Arial",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => showViewCancelDayUseBooking()}
              >
                {"View / cancel existing reservation"}
              </p>
            </div>

            <div className={styles.LoginForm__link}>
              <img src="img/icons/login/help.svg" alt="help img" /> Need some
              help? Click{" "}
              <div
                style={{
                  paddingLeft: "5px",
                  textDecoration: "underline",
                  color: "var(--ion-color-primary)",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window
                    .open("https://www.warnerleisurehotels.co.uk/day-experience", "_blank")
                    .focus()
                }
                target="_blank"
              >
                here
              </div>
            </div>
            <div
              style={{
                display: "flex",
                paddingTop: "16px",
                paddingBottom: "16px",
                maxWidth: "350px"
              }}
            >
              <BLParagraph
                txt={dayUseTexts?.Paragraph_AdultOnlyDisclaimer2}
                markDown
                newStyles={{ textAlign: "center" }}
                color="primary"
              />
            </div>
          </div>
        </div>
      );
    }
  };

  let isInDesktop = width > 1300;

  const styleKey = isInDesktop ? "desktop" : "mobile";

  return (
    <>
      <BlurryWrapper
        new_styles={{
          position: width > 750 ? "absolute" : "",
          backdropFilter: "blur(15px)",
          margin: width > 750 ? "" : "20px",
          justifyContent: "flex-start",
          zIndex: "10",
          height: "auto",
          ...BlurryWrapperStyle[styleKey],
        }}
        whiteShadowStyle={{
          padding: isInDesktop ? "20px 30px 0px 35px" : "20px 20px 20px 15px",
          background: "rgba(255, 255, 255, 0.7)",
        }}
        isLoginForm
      >
        <div style={{display: "flex", justifyContent: "center", paddingBottom: "20px"}}>
          {Logos.DayUseLoginPageLogoWarnerHotels(isInDesktop ? "240px" : "150px")}
        </div>
        <form className={styles.LoginForm}>
          <div
            style={{
              maxHeight: "3000px",
              transition: "max-height 0.5s, opacity 3s",
              opacity: "1",
              position: "relative",
            }}
          >
            {dayUseHotelData && getDayUseView()}
          </div>
        </form>
      </BlurryWrapper>
    </>
  );
};

export default LoginForm;
