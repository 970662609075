import React from "react";
import Thumbnail from "./components/Thumbnail";
import { DayUseServiceIcons } from "../../../../../assets/SVGIcons";
import styles from "./RegistrationScreenNav.module.scss";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../../../../../constants/constants";

const RegistrationScreenNav = () => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div className={styles.container}>
      <div className={styles.item}>
        <Thumbnail
          subTitle={"Register your details"}
          size={{
            width: "64px",
            height: "64px",
          }}
          icon={DayUseServiceIcons.List}
          onClick={() => history.push(ROUTES.RESERVATION_REGISTER)}
          active
        />
      </div>
      <div className={styles.item}>
        <Thumbnail
          subTitle={"Secure your reservation"}
          size={{
            width: "64px",
            height: "64px",
          }}
          icon={DayUseServiceIcons.CreditCard}
          active={
            location.pathname.includes(ROUTES.RESERVATION_CONFIRM)|| 
            location.pathname.includes(ROUTES.RESERVATION_SECURE)
          }
        />
      </div>
      <div className={styles.item}>
        <Thumbnail
          subTitle={"Reserve"}
          size={{
            width: "64px",
            height: "64px",
          }}
          icon={DayUseServiceIcons.Basket(48, "var(--ion-color-secondary)")}
          active={location.pathname.includes(ROUTES.RESERVATION_CONFIRM)}
        />
      </div>
    </div>
  );
};

export default RegistrationScreenNav;
