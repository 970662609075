import React from "react";
import Heading from "../../../../../../shared/Heading/Heading";

const ThumbnailDescriptiveBox = ({ title, desciption, isActive }) => {
  return (
    <div
      style={{
        background: "white",
        width: "calc(100% - 35px)",
        position: "absolute",
        height: "104px",
        left: "35px",
        padding: "16px 8px 16px 85px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      <div>
        <Heading
          txt={title}
          fontSize={"18px"}
          new_styles={{ color: "var(--ion-color-primary)", paddingBottom: "10px", font: "normal normal bold 22px/24px Arial" }}
        />
        <p style={{ font: "normal normal normal 14px/20px Arial"}}>{desciption}</p>
      </div>
      {isActive && (
        <img
          style={{ width: "15px", height: "15px", marginLeft: "5px" }}
          src={"/img/day_use_icons/rightArrow.svg"}
          alt="no img"
        />
      )}
    </div>
  );
};

export default ThumbnailDescriptiveBox;
