import React, { useContext, useEffect } from "react";
import LocationDetailsDesktop from "./Platforms/Desktop/LocationDetailsDesktop";
import LocationDetailsMobile from "./Platforms/Mobile/LocationDetailsMobile";
import { DayUseContext } from "../../../../context/DayUseContext";
import { useParams, useHistory } from "react-router-dom";
import WindowWidth from "../../../../hooks/WindowWidth";
import { ROUTES } from "../../../../constants/constants";

const LocationDetailsView = () => {
  
  const { width } = WindowWidth();
  const isDesktop = width >= 1024;

  const {
    updateHotelSelection,
    dayUseHotelDetailsLocation,
    setDayUseHotelDetailsLocation,
    dayUseHotelData,
  } = useContext(DayUseContext);

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (dayUseHotelData) {
      const hotel = dayUseHotelData.find(hotel => hotel.HotelId === id);
      if (hotel) {
        setDayUseHotelDetailsLocation({
          Title: hotel.Title,
          Address: hotel.Address,
          LongDescription: hotel.Description,
          Images: hotel.Images,
          id: hotel.id,
          AllowDayUseAppearance: hotel.AllowDayUseAppearance,
          HotelId: hotel.HotelId,
        });
      } else {
        history.push(ROUTES.HOTEL_LOCATIONS);
      }
    }
  }, [dayUseHotelData]);

  

  return (
    <>
      {isDesktop ? (
        <LocationDetailsDesktop
          hotelDetailsLocation={dayUseHotelDetailsLocation}
          setHotelDetailsLocation={setDayUseHotelDetailsLocation}
          updateHotelSelection={updateHotelSelection}
        />
      ) : (
        <LocationDetailsMobile
          hotelDetailsLocation={dayUseHotelDetailsLocation}
          setHotelDetailsLocation={setDayUseHotelDetailsLocation}
          updateHotelSelection={updateHotelSelection}
        />
      )}
    </>
  );
};

export default LocationDetailsView;
