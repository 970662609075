import React, { useContext, useEffect } from "react";
import DayUseParamsSelSection from "../SectionComponents/DayUseParamsSelSection";
import DayUseActivityDetailsSection from "../SectionComponents/DayUseActivityDetailsSection";
import DayUseAvailabilitySection from "../SectionComponents/DayUseAvailabilitySection";
import Heading from "../../../../shared/Heading/Heading";
import Button from "../../../../shared/Button/Button";
import LoadingContainer from "../../../../shared/LoadingContainer/LoadingContainer";
import { DayUseContext } from "../../../../context/DayUseContext";

import styles from "./styles/ActivitiesSelectionsView.module.scss";
import { useHistory, useParams } from "react-router-dom";
import { ROUTES } from "../../../../constants/constants";

const ActivitiesSelectionsView = ({ isDesktop, scrollToTop }) => {
  const history = useHistory();
  const { hotelId } = useParams();
  const {
    dayUseHotelData,
    dayUseDetails,
    isLoadingDayUseActivities,
    dayUseSelectedHotelActivities,
    dayUseSelectedActivityDetails,
    isLoadingDayUseAvailability,
    dayUseAvailability,
    dayUseSelections,
    showMore,
    updateDetailsSelection,
    updateGuestCount,
    handleShowMoreLess,
    handleSelectionAction,
    getDayUseAvailability,
    setDayUseSelectedHotel,
    dayUseActivitiesData,
    setDayUseDetails,
  } = useContext(DayUseContext);

  useEffect(() => {
    if (dayUseHotelData && dayUseActivitiesData) {
      const foundHotel =  dayUseHotelData.find(
        (row) => row.HotelId === hotelId && row.AllowDayUseAppearance
      );
      
      if (foundHotel) {
        setDayUseSelectedHotel(foundHotel);
        setDayUseDetails({
          count: 1,
          hotelId: foundHotel.id,
          hotelTitle: foundHotel.Title,
          maestroHotelId: foundHotel.HotelId,
        });
      } else {
        history.push(ROUTES.HOME);
      }
    }
  }, [dayUseHotelData, dayUseActivitiesData]);

  return (
    <>
      {dayUseHotelData ? (
        <>
          {isDesktop && (
            <Heading
              txt={"Choose your experience"}
              new_styles={{
                paddingBottom: "24px",
                textAlign: "center",
                fontFamily: "Georgia",
                fontSize: "26px",
                color: "var(--ion-color-primary)",
                textTransform: "uppercase",
              }}
            />
          )}

          {dayUseDetails?.hasOwnProperty("hotelId") && (
            <DayUseParamsSelSection
              dayUseDetails={dayUseDetails}
              isLoadingActivities={isLoadingDayUseActivities}
              selectedHotelActivities={dayUseSelectedHotelActivities}
              updateDetailsSelection={updateDetailsSelection}
              updateGuestCount={updateGuestCount}
            />
          )}

          {dayUseSelectedActivityDetails && (
            <DayUseActivityDetailsSection
              selectedActivityDetails={dayUseSelectedActivityDetails}
              handleShowMoreLess={handleShowMoreLess}
              fetchAvailability={getDayUseAvailability}
              fadeButton={
                dayUseDetails &&
                dayUseDetails.hotelId &&
                dayUseDetails.count &&
                dayUseDetails.activityId &&
                dayUseDetails.date
              }
              isLoadingDayUseAvailability={isLoadingDayUseAvailability}
              showMore={showMore}
              isDesktop={isDesktop}
            />
          )}

          {isLoadingDayUseAvailability && !dayUseAvailability ? (
            <LoadingContainer />
          ) : (
            <>
              {dayUseAvailability?.length > 0 ? (
                <>
                  <DayUseAvailabilitySection
                    availability={dayUseAvailability}
                    params={dayUseDetails}
                    selections={dayUseSelections}
                    handleSelectionAction={handleSelectionAction}
                  />

                  <div
                    className={`
                        ${styles.day_use_activities_selections_btn_wrapper} ${dayUseSelections.length === 0 ? "disableElement" : ""}`}
                  >
                    <Button
                      txt={"Go to reservation basket"}
                      onClick={() => history.push(ROUTES.BASKET)}
                      minHeight={50}
                      height={50}
                    />
                  </div>
                </>
              ) : dayUseAvailability ? (
                <div className={styles.day_use_no_avail_wrapper}>
                  <p
                    style={{
                      font: "normal normal 600 16px/22px Arial",
                      color: "color: #3C3C3C",
                    }}
                  >
                    We apologise there are no slots available for that
                    selected date
                  </p>

                  <div className={styles.day_use_check_another_hotel_wrapper}>
                    <Button
                      txt={"Check another hotel"}
                      onClick={() => scrollToTop()}
                      type="primary"
                      height={56}
                      minHeight={56}
                    />
                  </div>
                </div>
              ) : null}
            </>
          )}
        </>
      ) : (
        <LoadingContainer
          new_styles={{
            overflow: "auto",
            height: "calc(100vh - 140px)",
            overflowX: "hidden",
          }}
        />
      )}
    </>
  );
};

export default ActivitiesSelectionsView;
