import { useEffect, useState } from "react";

const WindowWidth = () => {
  let [width, setWidth] = useState(window.innerWidth);
  let [height, setHeight] = useState(window.outerHeight);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 2) {
        return;
      } else {
        setWidth(window.innerWidth);
        setHeight(window.outerHeight);
      }
    };

    window.addEventListener("resize", handleResize);

    // Cleanup function
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // No dependencies, runs only once after initial render

  return { width, height };
};

export default WindowWidth;
