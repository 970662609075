import React from "react";

import ImageCarouselLogic from "./ImageCarouselLogic";
import { leftArrow, rightArrow } from "../../assets/SVGIcons";
import IndicatorCircle from "../IndicatorNumber/IndicatorCircle";
import InfoBox from "../../components/InfoBox/InfoBox";

import styles from "./image-carousel.module.scss";
import BLIcon from "../Ionic/BLIcon";
import WindowWidth from "../../hooks/WindowWidth";
import { getImageFormatURL } from "../../helpingFunctions/imageFormat";

const ImageCarousel = ({
  onClick,
  sliderImages = [],
  autoSlide = false,
  view,
  containImg,
  customStyle,
  imageIcons,
  imageCustomStyle,
  fillImg,
  newImageStyles,
  new_styles,
  format = "small",
  removeBorderRadius,
  customBorderRadius
}) => {

  const { width } = WindowWidth();
  const isDesktop = width >= 1300;

  if (sliderImages && sliderImages.length > 0) {
    sliderImages = sliderImages.filter((sliderImage) => {
      if (sliderImage.image) {
        return sliderImage.image.formats?.small || sliderImage.image.url;
      }

      return sliderImage.formats?.small || sliderImage.url;
    });
  }

  const {
    handleSliderLeft,
    handleSliderRight,
    handleSliderSkip,
    loginInfoClickHandler,
    leftPosition,
    isLoginInfoOpened,
  } = ImageCarouselLogic(sliderImages.length - 1, autoSlide);

  const getSideArrowPosition = () => {
    if (view === "Entertainment" && customStyle) {
      const pic = sliderImages[Math.abs(leftPosition) / 100];
      if (pic?.formats?.small?.width > pic?.formats?.small?.height) {
        return `${Number(pic.formats.small.height) / 2}px`;
      }
    }
    return `calc(50% - 15px)`;
  };

  let ImageIcons = null;
  if (imageIcons) {
    ImageIcons = (
      <div className={styles.slider__image_icons}>
        {imageIcons.map(({ icon }) =>
          icon ? (
            <IndicatorCircle
              key={`${icon?.formats?.thumbnail?.url}`}
              txt={
                <img
                  width={"66%"}
                  height={"57%"}
                  src={icon?.formats?.thumbnail?.url}
                  alt={""}
                />
              }
              backgroundColor={"transparent"}
              color={"#5f5f5f"}
              border={"1px solid #5f5f5f"}
              width={"50px"}
              height={"50px"}
              fontSize={"1.2rem"}
            />
          ) : null
        )}
      </div>
    );
  }

  let CarouselNavigation = {};
  if (!autoSlide) {
    CarouselNavigation.leftArrow = (
      <div
        className={styles.leftArrow}
        onClick={handleSliderLeft}
        style={{
          opacity: leftPosition === 0 ? "0.4" : "1",
          top: getSideArrowPosition(),
        }}
      >
        <BLIcon customIconSource={leftArrow} />
      </div>
    );
    CarouselNavigation.rightArrow = (
      <div
        className={styles.rightArrow}
        style={{
          opacity:
            leftPosition === -100 * (sliderImages.length - 1) ? "0.4" : "1",

          top: getSideArrowPosition(),
        }}
        onClick={handleSliderRight}
      >
        <BLIcon customIconSource={rightArrow} />
      </div>
    );

    CarouselNavigation.dots = (
      <div className={styles.dotsWrapper_manual}>
        {sliderImages.map((image, index) => (
          <span
            key={image.hash}
            className={styles.dotsWrapper_manual_nav}
            style={{
              backgroundColor: leftPosition === -100 * index ? "white" : null,
            }}
            onClick={() => handleSliderSkip(-100 * index)}
          />
        ))}
      </div>
    );
  }

  if (autoSlide && view === "Login") {
    CarouselNavigation.dots = (
      <div className={styles.dotsWrapper_auto}>
        {sliderImages.map((image, index) => (
          <div
            key={`${image.hash}_Login`}
            className={styles.dotsWrapper_auto_nav}
            onClick={() => handleSliderSkip(-100 * index)}
            style={{
              opacity: leftPosition === -100 * index ? "1" : "0.6",
            }}
          >
            <div
              style={{
                backgroundColor:
                  leftPosition === -100 * index ? "#646464" : "#6E6E6E",
                height: "7px",
                width: "100%",
                borderRadius: "1px",
              }}
            />
            <p
              className={styles.dotsWrapper_auto_nav_index}
              style={{
                color: leftPosition === -100 * index ? "#646464" : "#6E6E6E",
                borderRadius: "1px",
              }}
            >
              0{index + 1}
            </p>
          </div>
        ))}
      </div>
    );
  }

  let Images = [];

  if (view === "Login") {
    Images = sliderImages.map(({ image, title, dsc }) => (
      <div
        key={`${image.hash}${title}`}
        className={styles.slideImage}
        style={
          image?.formats?.small?.width > image?.formats?.small?.height
            ? customStyle
            : null
        }
      >
        {ImageIcons}
        {image ? (
          <img
            src={getImageFormatURL(image, format)}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              borderRadius: !!customBorderRadius ? customBorderRadius : removeBorderRadius ? "2px" : view === "WhatsOnGuide_Card" ? "0" : "10px",
              objectFit: containImg ? "contain" : "cover",
              position: "absolute",
            }}
          />
        ) : null}
        {isDesktop ? (
          <InfoBox
            loginInfoClickHandler={loginInfoClickHandler}
            isLoginInfoOpened={isLoginInfoOpened}
            title={title}
            dsc={dsc}
            navigator={CarouselNavigation.dots}
          />
        ) : null}
      </div>
    ));
  } else {
    Images = sliderImages.map(({ id, formats, url }) => (
      <div
        key={`${id}${formats}`}
        className={styles.slideImage}
        style={
          formats?.small?.width > formats?.small?.height
            ? customStyle
            : imageCustomStyle
        }
      >
        {ImageIcons}
        <img
          src={`${formats?.small?.url || url}`}
          alt=""
          style={{
            width: "100%",
            height: "100%",
            objectFit: fillImg ? "fill" : containImg ? "contain" : "cover",
            ...newImageStyles,
          }}
        />
      </div>
    ));
  }

  return (
    <div
      className={styles.slider}
      style={{
        ...new_styles,
      }}
    >
      {CarouselNavigation.leftArrow}
      <div
        className={styles.imageWrapper}
        style={{ left: `${leftPosition}%` }}
        onClick={onClick}
      >
        {Images}
      </div>
      {CarouselNavigation.rightArrow}
      {view !== "Login" && CarouselNavigation.dots}
    </div>
  );
};

export default ImageCarousel;
