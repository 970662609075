import React from "react";
import styles from "./InformationalBox.module.scss";

const InformationalBox = ({
  content,
  jsxContent,
  boxClassName,
  contentClassName,
  boxNewStyles,
  showIcon,
}) => {
  return (
    <>
      <div
        className={`${styles.InformationalBox} ${boxClassName}`}
        style={{ ...boxNewStyles }}
      >
        {showIcon ? (
          <img
            style={{
              width: "25px",
              height: "2.5rem",
              marginLeft: "15px",
            }}
            src={`/img/info_icons/info-information.svg`}
            alt=""
          />
        ) : null}
        {jsxContent ? (
          jsxContent
        ) : (
          <p
            className={`${styles.InformationalBox__content} ${contentClassName}`}
          >
            {content}
          </p>
        )}
      </div>
    </>
  );
};

export default InformationalBox;
