import React, { useContext, useEffect, useState } from "react";
import Heading from "../../../../shared/Heading/Heading";
import Button from "../../../../shared/Button/Button";
import RegistrationScreenNav from "../SectionComponents/Navigation/RegistrationScreenNav";
import Markdown from "markdown-to-jsx";
import RegisteredCard from "../../SelfServices/components/RegisteredCard/RegisteredCard";

import styles from "./styles/RegistrationView.module.scss";
import { DayUseContext } from "../../../../context/DayUseContext";
import PersonalDetailsDisclaimer from "../../../../components/PersonalDetailsDisclaimer/PersonalDetailsDisclaimer";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../constants/constants";

const RegistrationViewAddCardView = () => {
  const {
    dayUseCCDetails, 
    showCardDialog, 
    setShowCardDialog, 
    dayUseSelections, 
    registrationFormData,
  } = useContext(DayUseContext);
  const [regCardModuleView, setRegCardModuleView] = useState({ modalPage: "" });

  const history = useHistory();

  useEffect(() => {
    if (!dayUseSelections?.length || 
      !registrationFormData?.email || 
      !registrationFormData?.firstName ||
      !registrationFormData?.lastName ||
      !registrationFormData?.phone) {
      history.push(ROUTES.BASKET);
    }
  }, []);

  return (
    <>
      <RegistrationScreenNav />

      <Heading
        txt={"Add your credit card details"}
        new_styles={{
          paddingBottom: "24px",
          textAlign: "center",
          fontFamily: "Georgia",
          fontSize: "26px",
          textTransform: "uppercase",
          color: "var(--ion-color-primary)",
        }}
      />

      <div
        style={{
          width: "100%",
          gap: "20px",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Markdown
          style={{
            font: "normal normal normal 16px/22px Arial",
            color: "var(--ion-color-primary)",
          }}
        >
          {
            "In order to complete the reservation booking we need to collect your credit card details via a secure third party provider. Please note that your card will not be charged at this time. You will be asked to pay once you arrive to your selected experience, this card would only be charged in case of late a cancellation or a no-show."
          }
        </Markdown>
        <PersonalDetailsDisclaimer/>
      </div>


      <div
        className={styles.day_use_reg_view_btns_wrapper}
        style={{ marginTop: "40px", marginBottom: "20px" }}
      >
        <Button
          txt="Back"
          type="primary-inverse"
          onClick={() => history.push(ROUTES.RESERVATION_REGISTER)}
        />

        <Button
          txt={"Add your card"}
          onClick={() => setShowCardDialog(true)}
        />
      </div>

      {showCardDialog && (
        <RegisteredCard
          removeModal={() => {
            setShowCardDialog(false);
            setRegCardModuleView((prevState) => {
              return { ...prevState, modalPage: "" };
            });

            if (dayUseCCDetails) {
              history.push(ROUTES.RESERVATION_CONFIRM);
            }
          }}
          showCardDialog={showCardDialog}
          regCardModuleView={regCardModuleView}
        />
      )}
    </>
  );
};

export default RegistrationViewAddCardView;
