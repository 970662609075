import React from "react";
import DayUseAvailabilitySectionLogic from "./DayUseAvailabilitySectionLogic";
import styles from "./DayUseAvailabilitySection.module.scss";

const DayUseAvailabilitySection = ({
  params,
  availability,
  handleSelectionAction,
  selections,
}) => {
  const { getRowSummary } = DayUseAvailabilitySectionLogic(
    selections,
    params,
    handleSelectionAction
  );

  return (
    <>
      <div className={styles.day_use_availability_section_wrapper}>
        {availability?.map((availObj, index) => (
          <div key={availObj.FacilityDate} className={styles["row_width"]}>{getRowSummary(availObj, index)}</div>
        ))}
      </div>
    </>
  );
};

export default DayUseAvailabilitySection;
