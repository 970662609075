import React, { useState, useContext, useEffect } from "react";
import DayUseDesktopNav from "./SectionComponents/Navigation/DayUseDesktopNav";
import DayUseMobileNav from "./SectionComponents/Navigation/DayUseMobileNav";
import DayUseHeaderSection from "./SectionComponents/DayUseHeaderSection";
import { DayUseContext } from "../../../context/DayUseContext";
import { WarnerLogoHorizontalLight } from "../../../assets/SVGIcons";

import styles from "./day_use.module.scss";
import BLParagraph from "../../../shared/Ionic/Paragraph/BLParagraph";
import WindowWidth from "../../../hooks/WindowWidth";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";

const DayUseWrapper = ({
  scrollRef,
  manualDropdownOpen,
  setManualDropdownOpen,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const location = useLocation();
  const { width } = WindowWidth();

  useEffect(() => {
    if (width >= 1024) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [width]);

  const {
    history,
    dayUseSelections,
    dayUseDetails,
    dayUseHotelData,
    updateHotelSelection,
    dayUseSelectedHotel,
  } = useContext(DayUseContext);

  const doc2 = document.getElementById("launcher");
  if (doc2) {
    doc2.style.display = "none";
  }

  const CUSTOM_STYLE = {
    DESKTOP: {
      width: isCollapsed ? "85%" : "65%",
      border: "1px solid #EDE0D3",
      overflow: "auto",
      height: "calc(100vh - 200px)",
      overflowX: "hidden",
      padding: "16px",
      maxWidth: "4000px",
      transition: "all 0.5s ease 0s",
    },
    MOBILE: {
      padding: location.pathname.includes(`${ROUTES.HOTEL_LOCATIONS}/`) ? "0" : "16px",
      marginTop: "16px",
      overflow: "auto",
      overflowX: "hidden",
    },
    SIDER: {
      width: isCollapsed ? "15%" : "35%",
      overflow: "auto",
      height: "calc(100vh - 200px)",
      overflowX: "hidden",
      background: "var(--ion-color-secondary)",
      transition: "all 0.5s ease 0s",
    },
  };

  return (
    <div className={styles.day_use_wrapper}>
      <div
        style={{
          height: isDesktop ? null : "calc(100% - 75px)",
          overflowY: "auto",
        }}
      >
        {isDesktop && (
          <div className={styles.header}>
            <div
              style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              onClick={() => history.push(ROUTES.HOME)}
            >
              {WarnerLogoHorizontalLight}
            </div>
            <div style={{ margin: "0 auto", transform: "translateX(-97px)" }}>
              <BLParagraph
                txt="Day Experiences"
                color="secondary"
                newClasses="x-large georgia"
              />
            </div>
          </div>
        )}
        <div
          ref={scrollRef}
          className={isDesktop ? styles.content : ""}
          style={isDesktop ? {} : { height: "100%", overflow: "auto" }}
        >
          <div style={isDesktop ? CUSTOM_STYLE.SIDER : {}}>
            <DayUseHeaderSection
              dayUseDetails={dayUseDetails}
              hotelData={dayUseHotelData}
              updateHotelSelection={updateHotelSelection}
              selectedHotel={dayUseSelectedHotel}
              manualDropdownOpen={manualDropdownOpen}
              setManualDropdownOpen={setManualDropdownOpen}
              hideLogo
              hideSelection={isCollapsed}
              isDesktop={isDesktop}
            />
            {isDesktop && (
              <DayUseDesktopNav
                selections={dayUseSelections}
                isCollapsed={isCollapsed}
                setIsCollapsed={setIsCollapsed}
                dayUseSelectedHotel={dayUseSelectedHotel}
              />
            )}
          </div>

          <div style={isDesktop ? CUSTOM_STYLE.DESKTOP : CUSTOM_STYLE.MOBILE}>
            {children}
          </div>
        </div>
      </div>
      {!isDesktop && (
        <DayUseMobileNav
          history={history}
          selections={dayUseSelections}
          dayUseSelectedHotel={dayUseSelectedHotel}
        />
      )}
    </div>
  );
};

export default DayUseWrapper;
