import React from "react";
import { useLocation } from "react-router-dom";

import RegisterCardHeader from "../../components/RegisterCardHeader/RegisterCardHeader";
import CardRegistrationLogic from "./CardRegistrationLogic";
import LoadingContainer from "../../../../../shared/LoadingContainer/LoadingContainer";
import Heading from "../../../../../shared/Heading/Heading";
import { xSign } from "../../../../../assets/SVGIcons";

import styles from "./CardRegistration.module.scss";

const CardRegistration = ({ changeModalPage, removeModal, details }) => {
  const { loaders } = CardRegistrationLogic(changeModalPage);
  const { location } = useLocation();

  if (location?.pathname("/pxp/error")) {
    return null;
  }

  let LoaderDisplay = null;
  if (loaders.length > 0) {
    LoaderDisplay = (
      <div className={styles.CardRegistration__loaders}>
        <Heading
          txt={"Registering Credit Card"}
          new_styles={{ fontSize: "22px" }}
        />
        {loaders.map(({ headline, desc, status }) => (
          <div className={styles.CardRegistration__step} key={headline}>
            <Heading
              txt={headline}
              new_styles={{
                fontSize: "18px",
                textAlign: "left",
                fontFamily: "Helvetica Neue",
                fontWeight: "bolder",
              }}
            />
            <div className={styles.CardRegistration__loader_and_desc}>
              {status === "success" ? (
                <img
                  className={styles.CardRegistration__success}
                  src={`/img/info_icons/done_.svg`}
                  alt="done"
                />
              ) : status === "failure" ? (
                xSign()
              ) : (
                <LoadingContainer
                  loaderWrapperStyle={{
                    width: "fit-content",
                    height: "fit-content",
                  }}
                  new_styles={{
                    marginLeft: "0px",
                  }}
                />
              )}
              <p className={styles.CardRegistration__desc}>{desc}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.CardRegistration}>
      <RegisterCardHeader removeModal={removeModal} />
      {LoaderDisplay}
      <object
        className={styles.CardRegistration__pxp}
        data={details.redirectUrl}
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default CardRegistration;
