import React, { useLayoutEffect } from "react";
import AppendHead from "react-append-head";

import styles from '../../../css/policies.module.scss';

const PrivacyAndPolicies = () => {
  useLayoutEffect(() => {
    setTimeout(() => {
      window.OneTrust.NoticeApi.Initialized.then(function () {
        window.OneTrust.NoticeApi.LoadNotices(
          [
            "https://privacyportal-eu-cdn.onetrust.com/75f6ad4c-3666-4142-a5d5-a8a3cdabae93/privacy-notices/draft/c13cf6e9-d917-4dc9-ab7f-340d1ffda550.json",
          ],
          false
        );
      });
    }, 200);
  }, []);

  return (
    <div className={styles.policies}>
      <AppendHead>
        <script
          src="https://privacyportal-eu-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
          type="text/javascript"
          id="otprivacy-notice-script"
        />
      </AppendHead>
      <div
        id="otnotice-c13cf6e9-d917-4dc9-ab7f-340d1ffda550"
        className="otnotice"
      />
    </div>
  );
};

export default PrivacyAndPolicies;
