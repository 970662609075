import { makeStyles } from "@mui/styles";

const keywords = {
  l: "Left",
  r: "Right",
  t: "Top",
  b: "Bottom",
};

const useStyles = makeStyles(
  {
    dimensions: (props) => ({
      ...props,
    }),
  },
  { name: "bl" }
);

export default function CreateDimensions(settings = {}) {
  let classes = [];
  const stylings = {};
  Object.entries(settings).forEach(([key, value]) => {
    value.forEach((row) => {
      const props = row.split("-");
      if (props.length === 1) {
        if (!isNaN(props[0])) {
          stylings[key] = `${props[0]}px`;
        } else {
          classes.push(`bl-${key}-${row}`);
        }
      } else {
        if (!isNaN(props[1])) {
          stylings[`${key}${keywords[props[0]]}`] = `${props[1]}px`;
        } else {
          classes.push(`bl-${key}-${row}`);
        }
      }
    });
  });

  const dynamicClasses = useStyles(stylings);
  let constructedClasses = classes.join(" ");

  constructedClasses += " " + dynamicClasses.dimensions;
  return { dimensions: constructedClasses };
}
