import React,{ useContext } from "react";

import Button from "../../../../../shared/Button/Button";
import InformationText from "../../../../../shared/InformationText/InformationText";
import LogoAndTitle from "../../components/LogoAndTitle/LogoAndTitle";

import { DayUseContext } from "../../../../../context/DayUseContext";

const AddCard = ({ handleClick, removeModal }) => {
  const { dayUseTexts } = useContext(DayUseContext);

  return (
    <>
      <LogoAndTitle
        removeModal={removeModal}
        title={"Register your payment card"}
      />
      <InformationText
        content={dayUseTexts?.CreditCard?.Paragraph_IntroductionText}
        new_styles={{
          marginTop: "80px",
          padding: "16px",
        }}
        markdown
      />
      <Button
        txt={`Add card now`}
        onClick={handleClick}
        new_styles={{
          backgroundColor: "var(--ion-color-primary)",
          marginTop: "25px",
        }}
      />
    </>
  );
};

export default AddCard;
