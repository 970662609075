import React from "react";
import Markdown from "markdown-to-jsx";
import { Dialog, DialogContent } from "@mui/material";
import Heading from "../../../../../shared/Heading/Heading";
import ImageCarousel from "../../../../../shared/ImageCarousel/ImageCarousel";

import style from "./details-dialog.module.scss";

const DetailsDialog = ({ hotelDetails, handleReadMore, isDesktop, showHotelDetails }) => {

  const handleDialogClose = () => {
    handleReadMore(null);
  };

  return (
    <Dialog
      open={showHotelDetails}
      onClose={handleDialogClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: "97%",
            '@media(min-width: 900px)': {
              minWidth: '90%'
            },
            '@media(min-width: 1150px)': {
              minWidth: '80%'
            },
            border: "none",
          },
        },
      }}
    >
      <DialogContent
        sx={{
          padding: 0,
        }}
      >
        {isDesktop ? (
          <div className={style.infoDetailsBox}>
            <img
              className={style.closeButtons}
              src={`/img/info_icons/error_.svg`}
              onClick={handleDialogClose}
              alt="Close icon"
            />
            <div className={style.title}>
              <Heading txt={hotelDetails.Title} fontSize="24px" />
              {hotelDetails.Address ? (
                <Heading txt={hotelDetails.Address} fontSize="18px" />
              ) : null}
            </div>
            <div className={style.contentWrapper}>
              <div className={style.imgSliderWrapper}>
                <ImageCarousel
                  sliderImages={hotelDetails.Images}
                  view="Entertainment"
                  containImg
                  customStyle={{ height: "fit-content" }}
                />
              </div>

              <div className={style.activity_details_wrapper}>
                {hotelDetails.LongDescription ? (
                  <p className={style.ActDescription}>
                    <Markdown>{hotelDetails.LongDescription}</Markdown>
                  </p>
                ) : hotelDetails.ShortDescription ? (
                  <p className={style.ActDescription}>
                    <Markdown>{hotelDetails.ShortDescription}</Markdown>
                  </p>
                ) : hotelDetails.Description ? (
                  <p className={style.ActDescription}>
                    <Markdown>{hotelDetails.Description}</Markdown>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className={style.infoDetailsBox}>
            <img
              className={style.closeButtons}
              src={`/img/info_icons/error_.svg`}
              onClick={() => handleReadMore(null)}
              alt=""
            />
            <div className={style.title}>
              <Heading txt={hotelDetails.Title} fontSize={"24px"} />
              {hotelDetails.Address ? (
                <>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={`/img/day_use_icons/location-pin-blue.svg`}
                    alt=""
                  />
                  <span style={{ fontSize: "16px", paddingLeft: "5px" }}>
                    {hotelDetails.Address}
                  </span>
                </>
              ) : null}
            </div>
            <div style={{ justifyContent: 'center', overflow: 'auto' }} className={style.contentWrapper}>
              <div className={style.imgSliderWrapper}>
                <ImageCarousel
                  containImg
                  sliderImages={hotelDetails.Images}
                  customStyle={{ height: "fit-content" }} />
              </div>
              <div className={style.activity_details_wrapper}>
                {hotelDetails.LongDescription ? (
                  <p className={style.ActDescription}>
                    <Markdown>{hotelDetails.LongDescription}</Markdown>
                  </p>
                ) : hotelDetails.ShortDescription ? (
                  <p className={style.ActDescription}>
                    <Markdown>{hotelDetails.ShortDescription}</Markdown>
                  </p>
                ) : hotelDetails.Description ? (
                  <p className={style.ActDescription}>
                    <Markdown>{hotelDetails.Description}</Markdown>
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DetailsDialog;
