import { useContext, useEffect, useState } from "react";
import { DayUseContext } from "../../../../../context/DayUseContext";
import axiosInstance from "../../../../../url/createAxios";
import {
  CARD_ADD_DAY_USE,
} from "./CardRegistrationConstants";


const CardRegistrationLogic = (changeModalPage) => {
  const instance = axiosInstance();
  const [loaders, setLoaders] = useState([]);
  const { setDayUseCCDetails } = useContext(DayUseContext);

  useEffect(() => {
    let requestNumber = 0;
    const interval = setInterval(async () => {
      requestNumber++;
      if (requestNumber >= 105) {
        clearInterval(interval);
        changeModalPage({ page: "CardRetry"});
        return;
      }
      const { data } = await instance.get(`/pxp/getLastTransactionStatus?isDayUse=true`, {
        withCredentials: true,
      });

      setLoaders([CARD_ADD_DAY_USE]);

      if (data.hasRegisteredCard) {
        try {
          const { data: status } = await instance.post(`/pxp/callback?isDayUse=true`, {
            withCredentials: true,
            callbackStatus: "success",
          });

          setLoaders([]);

          if (status.status === "success" && data.state === "Authorised") {
            setDayUseCCDetails(data);
            changeModalPage({ page: "CardConfirmation", details: data });
          } else {
            changeModalPage({ page: "CardRetry", details: data });
          }
        } catch (e) {
          changeModalPage({ page: "CardRetry", details: data });
          clearInterval(interval);
        }
      }
    }, 4000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return { loaders };
};

export default CardRegistrationLogic;
