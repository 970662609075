import React from "react";

import TextLogo from "../../../../../components/TextLogo/TextLogo";

import styles from "./RegisterCardHeader.module.scss";

const RegisterCardHeader = ({ removeModal }) => {
  return (
    <header className={styles.RegisterCardHeader}>
      <TextLogo />
      <span className={styles.RegisterCardHeader__exit} onClick={removeModal}>
        Exit
      </span>
    </header>
  );
};

export default RegisterCardHeader;
