import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import styles from "./SelectSearch.module.scss";
import { InputAdornment } from "@mui/material";

const SelectSearch = ({
  data,
  placeholder,
  manageFormData,
  isRedBorder,
  selectedValueId,
  newAutoCompleteWrapperStyles,
  newAutoCompleteStyles,
  textFieldVariantNew,
  textFieldNewStyles,
  disableClearable,
  textFieldWrapperHeight,
  inputFieldStyles,
  manualDropdownOpen,
  setManualDropdownOpen,
  disabled
}) => {
  const [open, setOpen] = useState(false);
  const formatedData = data?.map((item) => {
    return {
      label: item.Title,
      ...item,
    };
  });

  const getSelLabel = () => {
    const selected = formatedData?.find((row) => row.id === selectedValueId);

    return selected ? selected.label : "";
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (manualDropdownOpen) {
      setOpen(manualDropdownOpen);
      setManualDropdownOpen(false);
    }
  }, [manualDropdownOpen]);

  return (
    <span
      className={styles.SelectSearch}
      style={{ ...newAutoCompleteWrapperStyles }}
    >
      <Autocomplete
        freeSolo
        disablePortal
        options={formatedData}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        style={{ paddingRight: 0 }}
        sx={{
          width: "100%",
          backgroundColor: "#fff",
          border: "none",
          "& .MuiOutlinedInput-root": {
            padding: "0px 20px 0 10px !important",
          },
          ...newAutoCompleteStyles,
        }}
        disabled={disabled}
        disableClearable={disableClearable}
        fullWidth
        onKeyPress={(e) => handleKeyPress(e)}
        renderInput={(params) => (
          <TextField
            className={styles.SelectSearch_input}
            {...params}
            placeholder={placeholder}
            sx={{
              input: {
                color: 'var(--ion-color-primary)',
                "&::placeholder": {
                  opacity: 1,
                },
              },
              height: textFieldWrapperHeight ? textFieldWrapperHeight : 64,
              border: isRedBorder ? "1px solid var(--ion-color-danger)" : "none",
              ...inputFieldStyles,
            }}
            variant={textFieldVariantNew ? textFieldVariantNew : "outlined"}
            InputProps={{
              ...params.InputProps,
              style: { ...textFieldNewStyles },
              endAdornment: (
                <InputAdornment 
                  position="end" 
                  onClick={() => setOpen(!open)}
                  sx={{
                    cursor: "pointer"
                  }}
                >
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={"/img/day_use_icons/downArrow.svg"}
                    alt="no img"
                  />
                </InputAdornment>
              )
            }}
          />
        )}
        value={getSelLabel()}
        onChange={(e, val) => manageFormData(val?.id)}
      />
    </span>
  );
};

export default SelectSearch;
