import React from 'react';

import Heading from '../../shared/Heading/Heading';

const TextLogo = () => {
  return (
    <Heading
      txt={"Day Experiences"}
      new_styles={{
        fontSize: "1.3rem",
        color: "var(--ion-color-primary)"
      }}
    />
  );
};

export default TextLogo;