import React from "react";
import styles from "./InformationalBoxHeavy.module.scss";

const InformationalBoxHeavy = ({
  title,
  content,
  boxClassName,
  titleClassName,
}) => {
  return (
    <>
      <div className={`${styles.InformationalBoxHeavy} ${boxClassName}`}>
        {title ? (
          <p
            className={`${styles.InformationalBoxHeavy_title} ${titleClassName}`}
          >
            {title}
          </p>
        ) : null}
        {content ? <p>{content}</p> : null}
      </div>
    </>
  );
};

export default InformationalBoxHeavy;
