const ROUTES = Object.freeze({
  HOME: "/",
  MY_BOOKING: "/my-booking",
  MY_BOOKING_SEARCH: "/my-booking/:hotelId/:bookingId",
  HOTEL_LOCATIONS: "/hotel-locations", 
  HOTEL_LOCATION: "/hotel-locations/:id", 
  BASKET: "/basket",
  SEARCH_EXPERIENCE: "/search-experience",
  SEARCH_EXPERIENCE_HOTEL: "/search-experience/:hotelId",
  RESERVATION: "/reservation",
  RESERVATION_REGISTER: "/reservation/register",
  RESERVATION_SECURE: "/reservation/secure",
  RESERVATION_CONFIRM: "/reservation/confirm",
  RESERVATION_SUMMARY: "/reservation/summary",
  MAINTENANCE: "/maintenance",
  PRIVACY: "/privacy",
  COOKIE: "/cookie",
});

const CLASS_CONSTANTS = Object.freeze({
  CUSTOM_MADE_CLASS_PREFIX: "bl-",
  TEXT_CSS_CLASS_IDENTIFIER: "bl-text-",
  IONIC_CSS_CLASS_IDENTIFIER: "ion-",
  CLASS_NOT_FOUND_TEXT: "undefined",
});

const IMAGE_FORMATS = Object.freeze({
  XXLARGE: "xxlarge", //2000px
  XLARGE: "xlarge", //1600px
  LARGE: "large", //1000px
  MEDIUM: "medium", //750px
  SMALL: "small", //500px
});

const MOBILE_PLATFORM = "mobile";
const DESKTOP_PLATFORM = "desktop";
const POUND_SYMBOL = "£";

export {
  ROUTES,
  MOBILE_PLATFORM,
  DESKTOP_PLATFORM,
  IMAGE_FORMATS,
  POUND_SYMBOL,
  CLASS_CONSTANTS
};
