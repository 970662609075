import React, { useEffect } from "react";
import { IonApp, setupIonicReact } from "@ionic/react";
import { BrowserRouter as Router } from "react-router-dom";

import Index from "./routes";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

// custom theme css
import "./theme/variables.scss";
import "./theme/theme.scss";
import "./theme/fonts.scss";
import "./theme/bl-dimensions.scss";
import "./theme/bl-custom.scss";
import "./theme/ion-overrides.scss";



// import useThemeDetector from "./hooks/useThemeDetector";

setupIonicReact();

// const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
// const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

const App = () => {
  // const isDarkTheme = useThemeDetector();

  // useEffect(() => {
  //   if (isDarkTheme) {
  //     lightSchemeIcon?.remove();
  //     document.head.append(darkSchemeIcon);
  //   } else {
  //     document.head.append(lightSchemeIcon);
  //     darkSchemeIcon?.remove();
  //   }
  // }, [isDarkTheme]);

  useEffect(() => {
    setTimeout(() => {
      const doc = document.querySelector(".wcv-panel");
      if (doc) {
        doc.style.display = "none";
      }
    }, 100);
  }, []);

  return (
    <IonApp>
      <Router>
        <Index />
      </Router>
    </IonApp>
  );
};

export default App;
