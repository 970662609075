import { useEffect, useRef, useState } from "react";

import useZendesk from "../hooks/useZendesk";
import WindowWidth from "../hooks/WindowWidth";
import { useLocation } from "react-router-dom";

const RouteLogic = () => {
  const location = useLocation();
  const { width } = WindowWidth();
  const isDesktop = width >= 1024;
  const scrollRef = useRef(null);

  const [manualDropdownOpen, setManualDropdownOpen] = useState(false);
  const [isScrollingStarted, setIsScrollingStarted] = useState(false);
  useZendesk(location.pathname);

  const checkIfScrolledToTop = () => {
    if (scrollRef?.current?.scrollTop === 0) {
      setIsScrollingStarted(false);
      setManualDropdownOpen(true);
    }
  };

  useEffect(() => {
    if (isScrollingStarted) {
      scrollRef?.current?.addEventListener("scroll", checkIfScrolledToTop);
    }

    return () => {
      scrollRef?.current?.removeEventListener("scroll", checkIfScrolledToTop);
    };
  }, [isScrollingStarted]);

  const scrollToTop = () => {
    if (!isDesktop) {
      setIsScrollingStarted(true);
      scrollRef?.current?.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setManualDropdownOpen(true);
    }
  };

  useEffect(() => {
    scrollRef?.current?.scrollTo({ top: 0 });
  }, [location.pathname]);

  return {
    manualDropdownOpen,
    setManualDropdownOpen,
    scrollRef,
    isDesktop,
    scrollToTop,
  };
};

export default RouteLogic;
