import React from "react";
import { IonText } from "@ionic/react";

import { classFormatter } from "../BLIonicHelpers";

import CreateDimensions from "../../../helpingFunctions/DimensionsFactory";
import BLMarkdown from "../Markdown/BLMarkdown";
import styles from "./BLParagraph.module.scss";
import { CLASS_CONSTANTS } from "../../../constants/constants";

const BLParagraph = ({
  txt = null,
  newClasses = "",
  onClick,
  dimensions = {},
  newStyles = {},
  color,
  markDown,
  center,
  ellipsisOn3rdRow,
  ionTextDirectClasses,
  children
}) => {
  const dimensionStyles = CreateDimensions(dimensions);

  const paragraphSpecificClasses = classFormatter(
    newClasses,
    CLASS_CONSTANTS.TEXT_CSS_CLASS_IDENTIFIER
  );

  return (
    <IonText
      className={`
        ${paragraphSpecificClasses} ${dimensionStyles.dimensions} 
        ${center ? "ion-text-center" : ""} ${ellipsisOn3rdRow ? "ellipsis-3rd-row" : ""} 
        ${newClasses.includes("bl_Paragraph_itinerary_text_ellipsis") ? styles.bl_Paragraph_itinerary_text_ellipsis : ""}
        ${newClasses.includes("bl_Paragraph_itinerary_notbooked_text_ellipsis") ? styles.bl_Paragraph_itinerary_notbooked_text_ellipsis : ""}
        ${newClasses.includes("bl_Paragraph_itinerary_text_checkin") ? styles.bl_Paragraph_itinerary_text_checkin : ""}
        ${newClasses.includes("bl_Paragraph_itinerary_text_noconflict") ? styles.bl_Paragraph_itinerary_text_noconflict : ""}
        ${ionTextDirectClasses}
      `}
      color={color}
      style={{ ...newStyles, pointerEvents: onClick || markDown ? "auto" : "none", cursor: onClick ? "pointer" : "default" }}
      onClick={onClick ? onClick : null}
    >
      {markDown && txt ? <BLMarkdown>{txt}</BLMarkdown> : txt}
      {children}
    </IonText>
  );
};

export default BLParagraph;
