import React, { useContext } from "react";
import ImageCarousel from "../../../../shared/ImageCarousel/ImageCarousel";
import Heading from "../../../../shared/Heading/Heading";
import Markdown from "markdown-to-jsx";
import { DayUseContext } from "../../../../context/DayUseContext";

import locationStyles from "./styles/LocationsDetailsView.module.scss";
import styles from "../SectionComponents/DayUseActivityDetailsSection.module.scss";

const LocationsDetailsView = () => {
  const { dayUseHotelData, showHotelDetails } = useContext(DayUseContext);

  return (
    <>
      <div className={locationStyles.day_use_locations_header_wrapper}>
        <Heading
          txt={"Locations"}
          new_styles={{
            paddingBottom: "24px",
            textAlign: "center",
            fontFamily: "Georgia",
            fontSize: "26px",
            textTransform: "uppercase",
            color: "var(--ion-color-primary)",
          }}
        />
        <span>Browse all Warner Hotels below</span>
      </div>

      {dayUseHotelData?.map(
        ({ Title, Address, Description, Images, MapUrl, AllowDayUseAppearance, id, HotelId }, index) => (
          <div
            key={index}
            className={styles.day_use_activity_details_section_wrapper}
            style={{ height: "203px" }}
          >
            <div style={{ height: "100%", width: "40%" }}>
              <ImageCarousel
                sliderImages={Images ? Images : []}
                autoSlide
              />
            </div>
            <div style={{ padding: "10px", width: "60%" }}>
              <div style={{ display: "flex" }}>
                <h1
                  className={styles.day_use_activity_details_title}
                  style={{ paddingRight: "5px" }}
                >
                  {Title}
                </h1>

                <img
                  onClick={() =>
                    showHotelDetails({
                      Title,
                      Address,
                      LongDescription: Description,
                      Images,
                      id: id,
                      AllowDayUseAppearance,
                      HotelId,
                    })
                  }
                  style={{
                    width: "30px",
                    height: "30px",
                    marginLeft: "15px",
                    marginBottom: "5px",
                    cursor: "pointer",
                  }}
                  src={`/img/day_use_icons/info-information.svg`}
                  alt=""
                />
              </div>
              <h2
                className={styles.day_use_activity_details_price}
                style={{ height: "45px" }}
              >
                {Address}
              </h2>

              <p className={styles.day_use_activity_details_description}>
                <Markdown>{Description ? Description : ""}</Markdown>
              </p>
              {MapUrl &&
                <div
                  className={locationStyles.day_use_locations_card_link_wrapper}
                >
                  <div
                    className={
                      locationStyles.day_use_locations_link_map_wrapper
                    }
                  >
                    <a
                      href={MapUrl}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        style={{ width: "20px", height: "20px" }}
                        src={`/img/day_use_icons/map.svg`}
                        alt=""
                      />
                      <span
                        className={styles.day_use_locations_card_link}
                        style={{
                          paddingLeft: "10px",
                          color: "var(--ion-color-primary)",
                          font: "normal normal 600 18px/22px Arial",
                          cursor: "pointer"
                        }}
                      >
                        View on a map
                      </span>
                    </a>
                  </div>
                </div>
              }
            </div>
          </div>
        )
      )}
    </>
  );
};

export default LocationsDetailsView;
