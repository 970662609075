import React, { useContext } from "react";
import BLParagraph from "../../shared/Ionic/Paragraph/BLParagraph";
import { DayUseContext } from "../../context/DayUseContext";
import { replacePlaceholders } from "../../helpingFunctions/replacePlaceholders";
import { ROUTES } from "../../constants/constants";

const PersonalDetailsDisclaimer = () => {
  const { dayUseTexts } = useContext(DayUseContext);

  return (
    <>
      {dayUseTexts?.Paragraph_PersonalDetailsDisclaimer && (
        <BLParagraph
          newClasses="x-small"
          center
          color="primary"
          markDown
          txt={replacePlaceholders(
            dayUseTexts.Paragraph_PersonalDetailsDisclaimer,
            {
              PRIVACY_POLICY_URL: ROUTES.PRIVACY,
              TERMS_AND_CONDITIONS_URL:
                "https://www.warnerleisurehotels.co.uk/company/terms-and-conditions",
            }
          )}
        />
      )}
    </>
  );
};

export default PersonalDetailsDisclaimer;
