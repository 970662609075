import React from "react";

export const rightArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ fill: "var(--ion-color-white)" }}
  >
    <path
      id="arrow-right-circle-fill"
      className="cls-1"
      d="M8,0A8,8,0,1,1,0,8,8,8,0,0,1,8,0ZM4.5,7.5a.5.5,0,0,0,0,1h5.793L8.146,10.646a.5.5,0,1,0,.708.708l3-3a.5.5,0,0,0,0-.708l-3-3a.5.5,0,1,0-.708.708L10.293,7.5Z"
    />
  </svg>
);

export const leftArrow = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    style={{ fill: "var(--ion-color-white)" }}
  >
    <path
      id="arrow-left-circle-fill"
      className="cls-1"
      d="M8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0Zm3.5,7.5a.5.5,0,0,1,0,1H5.707l2.147,2.146a.5.5,0,1,1-.708.708l-3-3a.5.5,0,0,1,0-.708l3-3a.5.5,0,0,1,.708.708L5.707,7.5Z"
    />
  </svg>
);

export const xSign = (
  size = 24,
  backgroundFill = "#ac1b1b",
  symbolFill = "var(--ion-color-white)"
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width={size}
    height={size}
  >
    <path
      d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
      fill={backgroundFill}
      style={{ width: size, height: size }}
    />
    <path
      fill={symbolFill}
      strokeWidth={size}
      stroke={symbolFill}
      d="M320 320L192 192M192 320l128-128"
    />
  </svg>
);

export const alertCircle = (size = 24, fill = "var(--ion-color-primary)") => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
  >
    <path
      style={{ fill: fill }}
      d="M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm0 319.91a20 20 0 1120-20 20 20 0 01-20 20zm21.72-201.15l-5.74 122a16 16 0 01-32 0l-5.74-121.94v-.05a21.74 21.74 0 1143.44 0z"
    />
  </svg>
);

export const chevronDownOutlined = (size, fill) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={size}
      height={size}
    >
      <path
        fill="none"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="48"
        d="M112 184l144 144 144-144"
      />
    </svg>
  );
};

export const CheckCircle = (size, scale) => {
  return (
    <svg
      width={size}
      height={size}
      style={{ fill: "var(--ion-color-success)" }}
      id="d9090658-f907-4d85-8bc1-743b70378e93"
      data-name="Livello 1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>prime</title>
      <path
        transform={`scale(${scale})`}
        id="70fa6808-131f-4233-9c3a-fc089fd0c1c4"
        data-name="done circle"
        d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11.52,17L6,12.79l1.83-2.37L11.14,13l4.51-5.08,2.24,2Z"
      />
    </svg>
  );
};

export const DayUseServiceIcons = {
  Home: (size, fill) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="ionicon"
      viewBox="0 0 512 512"
      width={size}
      height={size}
      style={{ fill: fill }}
    >
      <title>Home</title>
      <path d="M261.56 101.28a8 8 0 00-11.06 0L66.4 277.15a8 8 0 00-2.47 5.79L63.9 448a32 32 0 0032 32H192a16 16 0 0016-16V328a8 8 0 018-8h80a8 8 0 018 8v136a16 16 0 0016 16h96.06a32 32 0 0032-32V282.94a8 8 0 00-2.47-5.79z" />
      <path d="M490.91 244.15l-74.8-71.56V64a16 16 0 00-16-16h-48a16 16 0 00-16 16v32l-57.92-55.38C272.77 35.14 264.71 32 256 32c-8.68 0-16.72 3.14-22.14 8.63l-212.7 203.5c-6.22 6-7 15.87-1.34 22.37A16 16 0 0043 267.56L250.5 69.28a8 8 0 0111.06 0l207.52 198.28a16 16 0 0022.59-.44c6.14-6.36 5.63-16.86-.76-22.97z" />
    </svg>
  ),
  HomeMobile: (size, fill) => (
    <svg 
      version="1.1" 
      id="Layer_1" 
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 518.8 511.1"
      style={{
        enableBackground: "new 0 0 518.8 511.1",
        width: size.width,
        height: size.height,
      }}
    >
      <g>
        <path style={{ fill }} d="M395.7,370.4c1.6,5.3,0.4,10.1-3.5,13.8c-2.8,2.6-6.5,3.7-10.8,3.5c-20.3-1.2-56.4-30.4-67.2-42.4
          c12.6,27.4,68.8,148.5,42,161.9c-2,1-4.1,1.4-6.3,1.4c-2.2,0-4.1-0.4-6.5-1.4c-14.8-5.5-34.1-30-56.6-71
          c-15.6-28.6-31-69.8-35.3-79.8c0,0.6-0.2,1.4-0.2,2.2c-6.3,62.7-12.6,109.2-18.9,114.1c-0.8,0.6-1.6,0.8-2.4,0.8
          c-1.6-0.2-2.8-1.6-3.7-4.3c-3.5-9.9-3.5-38.6,0.2-70c4.1-34.5,11-58,18.5-66.2c2.4-2.6,4.9-3.7,7.3-3
          c11.2,2.8,68.2,104.5,87.3,142.5C323.4,429,274.3,332,290.6,316c3-2.8,10.1-3.9,16.8-3c13,1.8,31,10.3,49.9,21.5
          C378.6,347.2,392.6,360.4,395.7,370.4"/>
        <path style={{ fill }} d="M3.8,163.6c0.8-2.2,3.2-3.5,6.5-4.5c27.2-6.9,134.8,28.2,160.5,42.8c-22.7-30-55.4-70.8-52.2-82.2
          c0.4-1.6,1.4-2.8,2.8-3.4c10.1-4.1,30,17.9,38.2,27.8c11.6,14,23.5,31.1,31.3,45.9c6.3,11.6,11.6,25.8,7.7,31.9
          c-1.4,2.2-4.5,9.7-88.9-17.7c-22.5-7.3-54.6-19.5-70.4-25.2c15.4,6.9,47.1,21.5,68.4,31.7c80.6,38.8,76.7,44.8,76.3,47.3
          c-0.6,4.3-8.3,10.1-21.1,16c-12.8,5.9-29,11.8-43.8,16.8C89,300.8,48.9,315,24.9,315c-1,0-2,0-3,0c-10.8-0.4-17-3.4-17.7-8.7
          c-0.6-3.4,0.4-6.7,2.8-10.1c8.1-11,32.9-24.8,74.1-34.3c24.8-5.7,46.5-9.3,54.6-10.4C114.8,242.7-6.5,187.9,3.8,163.6"/>
        <path style={{ fill }} d="M344.5,284.7c21.7,7.9,127.8,45.9,136.6,68c1,2.4,1,4.5,0,6.5c-1.8,3.4-5.9,4.7-11.2,4.7
          c-14,0-37.9-9.5-53-16.2c-43.6-19.1-104.1-56.6-102.1-68.8c1.4-8.3,23.5-10.8,50.1-16.2c26.6-5.3,73.7-8.3,90.9-10.8
          c-32.5,0.2-133.3-2.2-143.7-12.8c-0.8-0.8-1.2-1.8-1.2-2.8c-2-16.2,71.4-69.4,97-80.2c5.7-2.6,9.5-2.6,11.2-0.6
          c1.2,1.4,1.8,3,1.6,4.9c-1.4,10.8-18.7,30.6-68,61.7c-0.4,0.2-0.8,0.6-1.2,0.8c26-1.4,135-2.2,159.3,20.3c3,2.8,4.3,5.7,4.3,9.1
          C514.6,278.4,371.5,283.5,344.5,284.7"/>
        <path style={{ fill }} d="M218.1,335.1c-1,27.4-10.1,83.8-22.9,122.4c-9.5,28.4-18.7,42-28,42c-0.6,0-1.2,0-1.8-0.2
          c-3.9-0.8-7.1-3.2-8.9-7.1c-6.3-13-0.6-42,17-87.7c9.5-24.8,16.8-42.8,21.9-53.4c-25.6,30.6-65.7,59.3-85.6,58.2
          c-5.5-0.4-10.1-2.8-12.8-7.7c-9.9-17.2,53.8-77.9,70.8-94c-0.2,0.2-0.6,0.2-0.8,0.4C100.6,335.7,16,369,4.6,363.7
          c-0.8-0.4-1.4-1-1.6-1.8c-0.4-1.4,0.4-3,2.2-4.7c19.5-18.9,162.6-72.2,184.3-64.5c0.8,0.4,1.6,1,1.8,1.8
          c2.8,8.9-34.9,53.4-49.5,71.2c17.9-14.6,64.7-47.5,72.9-45.7C218.9,320.9,218.5,326.7,218.1,335.1"/>
        <path style={{ fill }} d="M419.2,89.5c-25.4,40.2-103.9,122.2-125.8,122.2c-1,0-1.8-0.2-2.4-0.4c-16.6-7.1-2.2-133.1,2.2-171.5
          c-9.9,38.6-38.2,159.3-56.6,159.3c-10.8,0.2-30-15.6-51.3-47.1c-20.1-29.8-37.1-63.7-43-86.4c-4.3-16.6-3-26.6,4.1-29.6
          c4.3-1.8,9.1-1.6,14.2,0.6c31.1,14,64.5,102.7,70.8,119.7c5.3-21.7,38.2-153.6,68-153.6c0.2,0,0.6,0,0.8,0c2.4,0.2,4.3,1.4,6.1,3.5
          c20.5,24.4,6.5,106.3,1,153.6c-1,9.3-2,17.2-2.4,22.1c1-1.4,2.8-3.2,4.3-5.3C371.5,94.8,415,56,425.7,64.4
          C429.4,67.8,427.7,76.3,419.2,89.5"/>
      </g>
    </svg>
  ),
  LocationsMobile: (size, fill) => (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="115 20 60 90"
      width={size.width}
      height={size.height}
      style={{ fill: fill }}
    >
      <path
        id="trail-sign"
        d="m194.46,75.31l-11.41-11.41c-1.15-1.16-2.72-1.81-4.35-1.8h-26.43v-6.16h27.71c3.4,0,6.16-2.76,6.16-6.16v-18.47c0-3.4-2.76-6.16-6.16-6.16h-27.71v-3.08c0-1.7-1.38-3.08-3.08-3.08s-3.08,1.38-3.08,3.08v3.08h-26.43c-1.63,0-3.2.64-4.35,1.8l-11.41,11.41c-1.2,1.2-1.2,3.15,0,4.35l11.41,11.41c1.15,1.16,2.72,1.81,4.35,1.8h26.43v6.16h-27.71c-3.4,0-6.16,2.76-6.16,6.16v18.47c0,3.4,2.76,6.16,6.16,6.16h27.71v9.24c0,1.7,1.38,3.08,3.08,3.08s3.08-1.38,3.08-3.08v-9.24h26.43c1.63,0,3.2-.64,4.35-1.8l11.41-11.41c1.2-1.2,1.2-3.15,0-4.35Z"
      />
    </svg>
  ),
  ExperiencesMobile: (size, fill) => (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="115 20 60 90"
      width={size.width}
      height={size.height}
      style={{ fill: fill }}
    >
      <path
        id="bookmark"
        d="m173.72,110.2c-.75,0-1.48-.27-2.05-.77l-25.66-22.82-25.66,22.82c-1.27,1.13-3.22,1.01-4.35-.26-.5-.56-.78-1.29-.78-2.05V36.31c0-6.8,5.52-12.31,12.31-12.31h36.95c6.8,0,12.31,5.52,12.31,12.31v70.81c0,1.7-1.38,3.08-3.08,3.08Z"
      />
    </svg>
  ),
  BasketMobile: (size, fill) => (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="120 20 60 90"
      width={size.width}
      height={size.height}
      style={{ fill: fill }}
    >
      <path
        id="basket"
        d="m187.96,48.62h-14.85l-21.1-28.15c-1.24-1.63-3.56-1.96-5.2-.73-.27.21-.52.45-.73.73l-21.1,28.15h-14.85c-4.16-.01-7.53,3.35-7.54,7.51-.01.71.09,1.41.3,2.09l10.71,37.76c1.44,5.06,6.05,8.55,11.31,8.55h48.29c5.25-.02,9.86-3.49,11.33-8.54l10.73-37.8c.05-.19.08-.37.1-.56l.13-1.14c.01-.12.01-.24.01-.36-.01-4.16-3.4-7.52-7.54-7.51Zm-39.08,36.4c-4.82.01-8.73-3.89-8.73-8.71h0c0-4.82,3.91-8.73,8.71-8.73s8.73,3.91,8.73,8.71-3.89,8.73-8.71,8.73Zm-14.65-36.4l14.81-19.75,14.81,19.75h-29.62Z"
      />
    </svg>
  ),
  TrailSign: (size, fill) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className="ionicon"
      viewBox="0 0 512 512"
      style={{ fill: fill }}
    >
      <title>Trail Sign</title>
      <path
        stroke={fill}
        d="M491.31 324.69L432 265.37a31.8 31.8 0 00-22.63-9.37H272v-32h144a32 32 0 0032-32V96a32 32 0 00-32-32H272V48a16 16 0 00-32 0v16H102.63A31.8 31.8 0 0080 73.37l-59.31 59.32a16 16 0 000 22.62L80 214.63a31.8 31.8 0 0022.63 9.37H240v32H96a32 32 0 00-32 32v96a32 32 0 0032 32h144v48a16 16 0 0032 0v-48h137.37a31.8 31.8 0 0022.63-9.37l59.31-59.32a16 16 0 000-22.62z"
      />
    </svg>
  ),
  Location: (fill) => (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="413.099px"
      height="413.099px"
      viewBox="0 0 413.099 413.099"
      style={{ fill: fill }}
    >
      <g>
        <g>
          <path
            d="M206.549,0L206.549,0c-82.6,0-149.3,66.7-149.3,149.3c0,28.8,9.2,56.3,22,78.899l97.3,168.399c6.1,11,18.4,16.5,30,16.5
     c11.601,0,23.3-5.5,30-16.5l97.3-168.299c12.9-22.601,22-49.601,22-78.901C355.849,66.8,289.149,0,206.549,0z M206.549,193.4
     c-30,0-54.5-24.5-54.5-54.5s24.5-54.5,54.5-54.5s54.5,24.5,54.5,54.5C261.049,169,236.549,193.4,206.549,193.4z"
          />
        </g>
      </g>
    </svg>
  ),
  Basket: (size, fill) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="ionicon"
      viewBox="0 0 512 512"
      width={size}
      height={size}
      style={{ fill: fill }}
    >
      <title>Basket</title>
      <path d="M424.11 192H360L268.8 70.4a16 16 0 00-25.6 0L152 192H87.89a32.57 32.57 0 00-32.62 32.44 30.3 30.3 0 001.31 9l46.27 163.14a50.72 50.72 0 0048.84 36.91h208.62a51.21 51.21 0 0049-36.86l46.33-163.36a15.62 15.62 0 00.46-2.36l.53-4.93a13.3 13.3 0 00.09-1.55A32.57 32.57 0 00424.11 192zM256 106.67L320 192H192zm0 245a37.7 37.7 0 1137.88-37.7A37.87 37.87 0 01256 351.63z" />
    </svg>
  ),
  CreditCard: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.745"
      height="23.912"
      viewBox="0 0 30.745 23.912"
      style={{ fill: "var(--ion-color-secondary)" }}
    >
      <path
        id="Icon_awesome-credit-card"
        data-name="Icon awesome-credit-card"
        className="cls-1"
        d="M0,23.6a2.563,2.563,0,0,0,2.562,2.562h25.62A2.563,2.563,0,0,0,30.745,23.6V14.206H0Zm10.248-3.63a.642.642,0,0,1,.641-.641h7.259a.642.642,0,0,1,.641.641v2.135a.642.642,0,0,1-.641.641H10.889a.642.642,0,0,1-.641-.641Zm-6.832,0a.642.642,0,0,1,.641-.641H7.9a.642.642,0,0,1,.641.641v2.135a.642.642,0,0,1-.641.641H4.057a.642.642,0,0,1-.641-.641ZM30.745,4.812V7.374H0V4.812A2.563,2.563,0,0,1,2.562,2.25h25.62A2.563,2.563,0,0,1,30.745,4.812Z"
        transform="translate(0 -2.25)"
      />
    </svg>
  ),
  List: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.912"
      height="23.912"
      viewBox="0 0 23.912 23.912"
      style={{ fill: "var(--ion-color-secondary)" }}
    >
      <path
        id="Icon_material-format-align-justify"
        data-name="Icon material-format-align-justify"
        className="cls-1"
        d="M4.5,28.412H28.412V25.755H4.5Zm0-5.314H28.412V20.442H4.5Zm0-5.314H28.412V15.128H4.5Zm0-5.314H28.412V9.814H4.5ZM4.5,4.5V7.157H28.412V4.5Z"
        transform="translate(-4.5 -4.5)"
      />
    </svg>
  ),
  Recipe: (size, fill) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="ionicon"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      style={{ fill: fill }}
    >
      <path d="M400 480a16 16 0 01-10.63-4L256 357.41 122.63 476A16 16 0 0196 464V96a64.07 64.07 0 0164-64h192a64.07 64.07 0 0164 64v368a16 16 0 01-16 16z" />
    </svg>
  ),
};

export const Logos = {
  DayUseLoginPageLogoShort: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      style={{ width: "325px", height: "150px" }}
      viewBox="216.6 322.9 1316.6 333.2"
    >
      <g>
        <g>
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M737.1,468.7c-8.5,0-17.9-.6-25.6-.6-11.4,0-17.3.4-17.3.4l1.8-3.5c4.1-7.5,5.1-9.2,5.1-70.2s-.8-60-5.1-68.2l-1.8-3.5s5.9.4,17.3.4,19.5-.6,28.3-.6c23.2,0,77.9,1.6,77.9,69.8s-53.5,75.9-80.6,75.9h0ZM738.1,337.3c-7.7,0-13.6.4-13.6.4v116.4s6.3.4,13,.4c27.5,0,55.1-7.7,55.1-59.2s-25.8-58-54.5-58h0Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M933.9,468.1c-12,0-20.8.4-20.8.4,2.6-1.8,6.5-5.3,6.5-12.8s-3.5-18.7-7.5-32.8l-3.7-12h-44.4l-3.5,12c-3.9,14-7.7,25.8-7.7,32.8s3.9,11,6.5,12.8c0,0-7.1-.4-18.7-.4s-18.3.4-18.3.4c7.5-7.3,11.4-20.1,20.1-48.2l26-84.9c.6-2.4,1.4-4.9,1.4-7.5s-1-4.1-1.4-4.3h42.5c0,.2-1.2,1.4-1.2,4.3s.6,5.1,1.4,7.5l24.2,84.9c7.9,28.1,11.6,40.9,19.1,48.2,0,0-7.9-.4-20.8-.4h.3ZM890.8,346.4c-2.4-8.3-3.9-13.6-4.1-15.3-.4,1.4-1.6,6.3-4.3,15.3l-14.2,49.7h36.4l-13.8-49.7h0Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M1038.7,364.6l-22.2,40.7c0,47.8.8,51.9,5.1,59.8l1.8,3.5s-5.7-.4-18.5-.4-18.7.4-18.7.4l1.8-3.5c3.9-7.1,5.1-12.2,5.1-55.1l-24.6-45.8c-18.1-33.8-19.3-35.4-26.5-40.9,0,0,6.1.4,22.4.4s22-.4,22-.4c-3.3,2.8-4.7,6.3-4.7,9.6,0,5.7,1,8.1,10.6,27.5l14.9,29.7,14.9-29.9c9.6-19.3,9.8-21.6,9.8-27.3s-1.4-6.7-4.7-9.6c0,0,7.5.4,19.1.4s19.7-.4,19.7-.4c-6.7,4.7-9,7.9-27.3,41.3h0Z"
          />
        </g>
        <g>
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M216.6,506.6h91v28.3h-58.6v29.7h50.5v27.3h-50.5v33.8h60.8v28.3h-93.2v-147.3h0Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M368,577.6l-48-71h38.1l15.1,22.8c5.5,8.3,11,17.1,16.1,25h.2c4.9-7.9,10.8-17.1,15.9-24.8l15.1-23h36.4l-48,71,52.1,76.3h-38.3l-19.1-28.9c-5.5-8.1-10.8-16.5-15.7-24.4h-.4c-4.9,7.9-10,16.1-15.5,24.2l-19.1,29.1h-36.6l51.9-76.3h-.2Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M472.4,506.6h52.5c16.9,0,29.9,2.6,40.7,10.6s18.1,20.8,18.1,37.4-7.3,29.5-18.1,37.4-23.8,10.8-40.7,10.8h-20.1v51.1h-32.4v-147.3h0ZM523.7,575.6c9.2,0,15.3-1,19.9-4.3s7.7-9.4,7.7-16.3-3.1-13.2-7.7-16.5-10.8-4.3-19.9-4.3h-18.9v41.3h18.9Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M602.4,506.6h91v28.3h-58.6v29.7h50.5v27.3h-50.5v33.8h60.8v28.3h-93.2v-147.3h0Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M716.6,506.6h54.1c15.9,0,28.3,2.6,38.5,10,10.2,7.3,17.1,19.3,17.1,35.4s-10.6,34.4-28.5,41.3l40.1,60.6h-37l-36.2-56h-15.7v56h-32.4v-147.3h0ZM768.9,572.3c8.1,0,13.6-1,18.1-4.1s6.9-8.8,6.9-14.9-2.6-12-6.9-15.1-10-4.1-18.1-4.1h-19.9v38.1h19.9Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M849.4,506.6h32.4v147.3h-32.4v-147.3h0Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M908.7,506.6h91v28.3h-58.6v29.7h50.5v27.3h-50.5v33.8h60.8v28.3h-93.2v-147.3h0Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M1022.8,506.6h30.5l44.2,59.4c9.6,12.8,18.5,25.2,27.7,38.1h.4c-.2-14.2-.4-28.5-.4-42.7v-54.7h31.1v147.3h-30.5l-44.2-59.4c-9.6-12.8-18.5-25.2-27.7-38.1h-.4c.2,14.2.4,28.5.4,42.7v54.7h-31.1v-147.3h0Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M1320.7,506.6h91v28.3h-58.6v29.7h50.5v27.3h-50.5v33.8h60.8v28.3h-93.2v-147.3h0Z"
          />
          <path
            style={{ strokeWidth: "0px", fill: "#0f3549" }}
            d="M1479.2,628.1c13.2,0,21.4-5.1,21.4-15.3s-3.9-11-13-14.7c-9.2-3.7-27.5-7.3-39.7-14.4-12.2-7.1-18.7-17.7-18.7-34.2,0-28.3,20.6-45.2,51.5-45.2s31.9,3.7,44.8,10.4v31.3c-15.5-9.6-32.8-13.6-44-13.6s-19.7,4.7-19.7,14,4.1,10.8,13.4,14.4c9.4,3.7,27.7,7.1,39.9,14.4,12.2,7.3,18.1,19.1,18.1,34.6,0,29.1-21.4,46.2-53.3,46.2s-34.4-4.1-48.4-11.6v-32.6c16.5,11.4,36.4,16.1,47.8,16.1v.2h-.1Z"
          />
        </g>
        <path
          style={{ strokeWidth: "0px", fill: "#0f3549" }}
          d="M1256.1,656.1c-47.2,0-80-32.6-80-75.5s33-77.7,80.2-76.3,42.1,10.2,42.1,10.2v31.9c-11.2-7.9-26-13.2-40.9-13.2-28.9,0-48.8,20.1-48.8,46.8s19.5,47.2,50.1,47.2,40.9-13.4,40.9-13.4v31.7c-12.2,7.1-28.5,10.6-43.5,10.6h0Z"
        />
      </g>
    </svg>
  ),
  DayUseLoginPageLogoLong: (
    <svg
      style={{ width: "100%", height: "100%" }}
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="216.6 264.3 1316.6 455.5"
    >
      {/* <rect  style={{fill: "#faecde", strokeWidth: "0px"}} x=".8" y="2.3" width="1740" height="979" /> */}
      <g>
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M502.2,719.2l.5-11.9c-2.5,10.9-10.7,12.3-16.5,12.3-14.3,0-17.7-9.7-17.7-16.7,0-17.1,14.6-18.1,23.7-18.1s10,.6,10,.6v-4.4c0-3.8-.1-7.6-2-10.1-1.6-2.1-4.7-3.6-10.3-3.6s-11,.2-15.5,7.2c0,0,.2-2.3.2-5.9s-.2-5.6-.2-5.6c0,0,5.2-2.8,17.7-2.8s15.6,2.9,17.8,5.8c3.2,4,3.2,9.3,3.2,15.7s0,8.7,0,19,.6,11,2.4,14.3l.9,1.7-14.1,2.5h0ZM502.1,691.8s-2.2-.4-7.9-.4-14.2.3-14.2,10.6,4.5,10.3,10.4,10.3c9.5,0,11.7-5.6,11.7-5.6v-14.8h0Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M539.5,719.4c-6.4,0-9.9-2.3-11.8-5.3-1.7-2.6-2.3-6.1-2.3-13.5,0-24.1.3-32.3.3-32.3h-6.5c.2,0,3.6-3,7.2-8.8,3.8-5.7,5.8-10.5,5.8-10.5h4.5v12.6h6.4c8.3,0,9.9-1.1,9.9-1.1,0,0,0,1.4,0,4.4s0,4.3,0,4.3c0,0-1.5-1-9.9-1h-6.4v32.7c0,4.5.3,6.2,1.2,8.1,1.1,2.2,3.2,3.6,7,3.6,5.6,0,8.4-3,8.4-3,0,1.7-1.2,9.8-13.9,9.8h0Z"
        />
      </g>
      <g>
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M677.2,670.5l-12.5,48s-3.5-.2-11.5-.2-11.6.2-11.6.2l-10.8-48.1c-.8-3.4-1.1-6.3-1.1-7,0,.5-.4,3.1-1.2,7.1l-10.1,48s-3.9-.2-11.7-.2-12,.2-12,.2l-12.1-48c-3.6-14.2-5.6-20.2-9.5-23.9,0,0,4.2.2,13.2.2s14.3-.2,14.3-.2c-1.3.9-3.2,2.6-3.2,6.3s1.3,9.3,2.8,16.8l7.2,34.2c.5,2.5.9,5.2,1,5.9,0-.4.4-3.1,1.1-5.8l12-57.5s3.5.2,9.8.2,10-.2,10-.2l12.5,56.6c.6,2.7,1.3,6.8,1.3,6.8,0,0,.6-3.9,1.2-6.8l7.1-33.4c1.6-7.6,2.8-13.3,2.8-16.8s-1.9-5.4-3.2-6.3c0,0,4.1.2,12,.2s11.4-.2,11.4-.2c-3.7,3.6-5.7,9.7-9.4,23.9h.2Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M717.7,719.2l.5-12.1c-2.7,11-10.5,12.4-16.7,12.4-14.1,0-17.7-9.8-17.7-17.6,0-17.7,15.2-18.5,24-18.5s9.7.6,9.7.6v-2.7c0-3.2,0-6.4-2-8.7-1.6-1.8-4.5-3-10.3-3s-11.5,1.2-15.7,6.7c0,0,.3-2.7.3-7s-.3-6.8-.3-6.8c0,0,6.2-3,20.6-3s18,3.1,20.5,6.3c3.6,4.3,3.7,10.2,3.7,16.8s0,8.9,0,18.1.6,11,2.4,14.3l.9,1.7-19.8,2.5h0ZM717.4,691.9s-1.2-.3-5.1-.3-11.2.2-11.2,9.2,3.7,9.1,8.3,9.1,8.1-3.5,8.1-3.5v-14.4h0Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M774.3,673.3c-7.3,0-9.1,4.1-9.1,4.1v13.7c0,22,1.1,22.8,2.7,25.6l1.1,1.8s-3.9-.2-12.5-.2-12.1.2-12.1.2l.9-1.7c1.6-2.9,2.5-3.7,2.5-25.7v-4c0-17.4-.9-20.2-2.5-23.2l-.9-1.7,20.3-2.5-.5,17c2.2-15.4,9.9-17.4,14.4-17.4s6.9,1.8,6.9,1.8c0,0-.2,2.5-.2,8.6s.2,9.4.2,9.4c-3.1-4.5-7.6-5.7-11.4-5.7h.2Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M837.7,718.3c-8.7,0-12.2.2-12.2.2l.9-1.7c1.6-2.9,2.5-3.6,2.5-25.6s-.3-15.3-1.6-17.5c-1-1.6-2.7-3.5-7.3-3.5-7.5,0-8.8,4.5-8.8,4.5v16.4c0,21.8.9,22.8,2.5,25.7l.9,1.7s-3.5-.2-12.1-.2-12.1.2-12.1.2l.9-1.7c1.6-2.9,2.5-3.7,2.5-25.7v-4c0-17.4-.9-20.2-2.5-23.2l-.9-1.7,20.5-2.5-.5,14.1c2.3-10.8,8.8-14.5,17.7-14.5s12.7,2.6,15.3,7.3c2.4,4.2,2.8,9.1,2.8,20.8v3.6c0,21.9.9,22.8,2.5,25.7l.9,1.7s-3.5-.2-12.1-.2h.2Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M875.2,692.4c0,9.7,4.9,16.6,15.8,16.6s16.8-5.5,17.1-6c0,7.6-5.5,16.7-23.1,16.7s-27.5-4.5-27.5-29.7,9.9-30.5,27-30.5c24.9,0,25.6,20.3,25.6,31.9v1h-34.9,0ZM884.6,668c-6.5,0-9.3,4.3-9.4,17.1l18-1.9c0-8.8-.5-15.2-8.7-15.2h.1Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M948.2,673.3c-7.3,0-9.1,4.1-9.1,4.1v13.7c0,22,1.1,22.8,2.7,25.6l1.1,1.8s-3.9-.2-12.5-.2-12.1.2-12.1.2l.9-1.7c1.6-2.9,2.5-3.7,2.5-25.7v-4c0-17.4-.9-20.2-2.5-23.2l-.9-1.7,20.3-2.5-.5,17c2.2-15.4,9.9-17.4,14.4-17.4s6.9,1.8,6.9,1.8c0,0-.2,2.5-.2,8.6s.2,9.4.2,9.4c-3.1-4.5-7.6-5.7-11.4-5.7h.2Z"
        />
      </g>
      <g>
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M1042.4,718.3c-6.4,0-9.3.2-9.3.2l.9-1.7c1.9-3.7,2.5-4.6,2.5-31.2h-31.6c0,26.7.4,27.3,2.5,31.2l.9,1.7s-2.7-.2-9.1-.2-9.3.2-9.3.2l.9-1.7c2-3.8,2.5-4.5,2.5-34.7s-.4-29.7-2.5-33.7l-.9-1.7s2.6.2,9.2.2,9.2-.2,9.2-.2l-.9,1.7c-1.9,3.6-2.5,4.5-2.5,29h31.6c0-24.3-.5-25.2-2.5-29l-.9-1.7s2.6.2,9.2.2,9.2-.2,9.2-.2l-.9,1.7c-2,3.8-2.5,4.5-2.5,34.7s.4,29.7,2.5,33.7l.9,1.7s-2.6-.2-9.1-.2Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M1084.7,719.8c-17.5,0-26.3-12.2-26.3-29.1s5.2-30.5,27.4-30.5,26.3,12.1,26.3,29-5.2,30.6-27.4,30.6ZM1085.1,667.3c-10.3,0-14.5,7.9-14.5,22.8s3.7,22.6,14.8,22.6,14.5-7.9,14.5-22.9-3.8-22.5-14.8-22.5h0Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M1135.5,719.4c-6.4,0-9.9-2.3-11.8-5.3-1.7-2.6-2.3-6.1-2.3-13.5,0-24.1.3-32.3.3-32.3h-6.5c.2,0,3.6-3,7.2-8.8,3.8-5.7,5.8-10.5,5.8-10.5h4.5v12.6h6.4c8.3,0,9.9-1.1,9.9-1.1,0,0,0,1.4,0,4.4s0,4.3,0,4.3c0,0-1.5-1-9.9-1h-6.4v32.7c0,4.5.3,6.2,1.2,8.1,1.1,2.2,3.2,3.6,7,3.6,5.6,0,8.4-3,8.4-3,0,1.7-1.2,9.8-13.9,9.8h.1Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M1167.2,690.4c0,10.6,3.7,21.4,17.3,21.4s17.5-6.9,17.5-6.9c0,7.3-5.7,14.7-20.6,14.7s-26-9.5-26-29.3,11.5-30.1,25.4-30.1c21.5,0,23.5,17.9,23.5,29.3v.9h-37.1ZM1180.5,666.6c-6,0-13,2.5-13.3,18.4l25.3-1.9c0-6.1-.8-16.5-12-16.5Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M1218.8,718.3c-6.4,0-9,.2-9,.2l.9-1.7c1.9-3.5,2.5-5.5,2.5-25.7v-22.6c0-18.3-.6-21.3-2.5-25.1l-.9-1.7,14.8-2.6s-.3,10.9-.3,20.8v31.1c0,19.4.5,21.9,2.5,25.7l.9,1.7s-2.5-.2-9-.2h0Z"
        />
        <path
          style={{ fill: "#3592ba", strokeWidth: "0px" }}
          d="M1252.5,719c-10,0-15.6-3.1-15.6-3.1,0,0,.2-1.9.2-6.2s-.2-7.1-.2-7.1c3.9,6,7.8,9.7,15.1,9.7s10.2-4,10.2-7.9-2.1-6.1-5.7-8.3l-8.8-5c-4.4-2.4-10.5-5.8-10.5-15.3s7.6-15.5,19-15.5,14.8,3,14.8,3c0,0-.2,2-.2,6.3s.2,6.9.2,6.9c-3.7-5.6-7.5-9.7-14.3-9.7s-9.3.5-9.3,6.9,2.6,6.1,7.7,9l6.8,3.8c6.5,3.5,10.9,7.8,10.9,15.7s-4.5,16.7-20.3,16.7h0Z"
        />
      </g>
      <g>
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M737.1,410.1c-8.5,0-17.9-.6-25.6-.6-11.4,0-17.3.4-17.3.4l1.8-3.5c4.1-7.5,5.1-9.2,5.1-70.2s-.8-60-5.1-68.2l-1.8-3.5s5.9.4,17.3.4,19.5-.6,28.3-.6c23.2,0,77.9,1.6,77.9,69.8s-53.5,75.9-80.6,75.9h0ZM738.1,278.7c-7.7,0-13.6.4-13.6.4v116.4s6.3.4,13,.4c27.5,0,55.1-7.7,55.1-59.2s-25.8-58-54.5-58h0Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M933.9,409.5c-12,0-20.8.4-20.8.4,2.6-1.8,6.5-5.3,6.5-12.8s-3.5-18.7-7.5-32.8l-3.7-12h-44.4l-3.5,12c-3.9,14-7.7,25.8-7.7,32.8s3.9,11,6.5,12.8c0,0-7.1-.4-18.7-.4s-18.3.4-18.3.4c7.5-7.3,11.4-20.1,20.1-48.2l26-84.9c.6-2.4,1.4-4.9,1.4-7.5s-1-4.1-1.4-4.3h42.5c0,.2-1.2,1.4-1.2,4.3s.6,5.1,1.4,7.5l24.2,84.9c7.9,28.1,11.6,40.9,19.1,48.2,0,0-7.9-.4-20.8-.4h.3ZM890.8,287.8c-2.4-8.3-3.9-13.6-4.1-15.3-.4,1.4-1.6,6.3-4.3,15.3l-14.2,49.7h36.4l-13.8-49.7h0Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M1038.7,306l-22.2,40.7c0,47.8.8,51.9,5.1,59.8l1.8,3.5s-5.7-.4-18.5-.4-18.7.4-18.7.4l1.8-3.5c3.9-7.1,5.1-12.2,5.1-55.1l-24.6-45.8c-18.1-33.8-19.3-35.4-26.5-40.9,0,0,6.1.4,22.4.4s22-.4,22-.4c-3.3,2.8-4.7,6.3-4.7,9.6,0,5.7,1,8.1,10.6,27.5l14.9,29.7,14.9-29.9c9.6-19.3,9.8-21.6,9.8-27.3s-1.4-6.7-4.7-9.6c0,0,7.5.4,19.1.4s19.7-.4,19.7-.4c-6.7,4.7-9,7.9-27.3,41.3h0Z"
        />
      </g>
      <g>
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M216.6,448h91v28.3h-58.6v29.7h50.5v27.3h-50.5v33.8h60.8v28.3h-93.2v-147.3h0Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M368,519l-48-71h38.1l15.1,22.8c5.5,8.3,11,17.1,16.1,25h.2c4.9-7.9,10.8-17.1,15.9-24.8l15.1-23h36.4l-48,71,52.1,76.3h-38.3l-19.1-28.9c-5.5-8.1-10.8-16.5-15.7-24.4h-.4c-4.9,7.9-10,16.1-15.5,24.2l-19.1,29.1h-36.6l51.9-76.3h-.2Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M472.4,448h52.5c16.9,0,29.9,2.6,40.7,10.6s18.1,20.8,18.1,37.4-7.3,29.5-18.1,37.4-23.8,10.8-40.7,10.8h-20.1v51.1h-32.4v-147.3h0ZM523.7,517c9.2,0,15.3-1,19.9-4.3s7.7-9.4,7.7-16.3-3.1-13.2-7.7-16.5-10.8-4.3-19.9-4.3h-18.9v41.3h18.9Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M602.4,448h91v28.3h-58.6v29.7h50.5v27.3h-50.5v33.8h60.8v28.3h-93.2v-147.3h0Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M716.6,448h54.1c15.9,0,28.3,2.6,38.5,10,10.2,7.3,17.1,19.3,17.1,35.4s-10.6,34.4-28.5,41.3l40.1,60.6h-37l-36.2-56h-15.7v56h-32.4v-147.3h0ZM768.9,513.7c8.1,0,13.6-1,18.1-4.1s6.9-8.8,6.9-14.9-2.6-12-6.9-15.1-10-4.1-18.1-4.1h-19.9v38.1h19.9Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M849.4,448h32.4v147.3h-32.4v-147.3Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M908.7,448h91v28.3h-58.6v29.7h50.5v27.3h-50.5v33.8h60.8v28.3h-93.2v-147.3h0Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M1022.8,448h30.5l44.2,59.4c9.6,12.8,18.5,25.2,27.7,38.1h.4c-.2-14.2-.4-28.5-.4-42.7v-54.7h31.1v147.3h-30.5l-44.2-59.4c-9.6-12.8-18.5-25.2-27.7-38.1h-.4c.2,14.2.4,28.5.4,42.7v54.7h-31.1v-147.3h0Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M1320.7,448h91v28.3h-58.6v29.7h50.5v27.3h-50.5v33.8h60.8v28.3h-93.2v-147.3h0Z"
        />
        <path
          style={{ fill: "#0f3549", strokeWidth: "0px" }}
          d="M1479.2,569.5c13.2,0,21.4-5.1,21.4-15.3s-3.9-11-13-14.7c-9.2-3.7-27.5-7.3-39.7-14.4-12.2-7.1-18.7-17.7-18.7-34.2,0-28.3,20.6-45.2,51.5-45.2s31.9,3.7,44.8,10.4v31.3c-15.5-9.6-32.8-13.6-44-13.6s-19.7,4.7-19.7,14,4.1,10.8,13.4,14.4c9.4,3.7,27.7,7.1,39.9,14.4,12.2,7.3,18.1,19.1,18.1,34.6,0,29.1-21.4,46.2-53.3,46.2s-34.4-4.1-48.4-11.6v-32.6c16.5,11.4,36.4,16.1,47.8,16.1v.2Z"
        />
      </g>
      <path
        style={{ fill: "#0f3549", strokeWidth: "0px" }}
        d="M1256.1,597.6c-47.2,0-80-32.6-80-75.5s33-76.3,80.2-76.3,30.5,3.7,42.1,10.2v31.9c-11.2-7.9-26-13.2-40.9-13.2-28.9,0-48.8,20.1-48.8,46.8s19.5,47.2,50.1,47.2,29.5-5.3,40.9-13.4v31.7c-12.2,7.1-28.5,10.6-43.5,10.6Z"
      />
    </svg>
  ),
  DayUseLoginPageLogoWarnerHotels: (width) => (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="202.5 1.8 391.3 280"
      style={{
        enableBackground: "new 0 0 793.7 283.5",
        width,
      }}
    >
      <g id="Layer_3"></g>
      <g id="Layer_1">
        <g>
          <g>
            <path
              style={{ fill: "#0F3549" }}
              d="M308,89h6.5l3.6,12.1c0.7,2.4,1.4,4.9,2,7.3h0.1c0.7-2.4,1.4-5,2.1-7.3l3.7-12.1h4.5l3.7,12.1
              c0.7,2.4,1.5,4.9,2.1,7.3h0.1c0.6-2.4,1.4-5,2.1-7.4l3.6-12h6.2l-8.7,28.3h-6.6l-3-10c-0.7-2.4-1.4-4.5-2-6.8h-0.1
              c-0.6,2.3-1.3,4.5-2,6.9l-3,9.8h-6.5L308,89z"
            />
            <path
              style={{ fill: "#0F3549" }}
              d="M357.7,89h6.3l11.3,28.3h-6.6l-2.3-5.9h-11.4l-2.3,5.9h-6.4L357.7,89z M364.6,106.5l-1.1-3
              c-0.9-2.3-1.9-5.2-2.6-7.3h-0.1c-0.8,2.2-1.8,4.9-2.7,7.3l-1.2,3H364.6z"
            />
            <path
              style={{ fill: "#0F3549" }}
              d="M379.6,89H390c3,0,5.4,0.5,7.4,1.9c2,1.4,3.3,3.7,3.3,6.8c0,3.7-2,6.6-5.5,7.9l7.7,11.6h-7.1l-6.9-10.7h-3
              v10.7h-6.2V89z M389.7,101.6c1.6,0,2.6-0.2,3.5-0.8c0.9-0.6,1.3-1.7,1.3-2.9c0-1.2-0.5-2.3-1.3-2.9c-0.8-0.6-1.9-0.8-3.5-0.8
              h-3.8v7.3H389.7z"
            />
            <path
              style={{ fill: "#0F3549" }}
              d="M407.6,89h5.9l8.5,11.4c1.8,2.5,3.6,4.8,5.3,7.3h0.1c0-2.7-0.1-5.5-0.1-8.2V89h6v28.3h-5.9l-8.5-11.4
              c-1.8-2.5-3.6-4.8-5.3-7.3h-0.1c0,2.7,0.1,5.5,0.1,8.2v10.5h-6V89z"
            />
            <path
              style={{ fill: "#0F3549" }}
              d="M439.3,89h17.5v5.4h-11.2v5.7h9.7v5.2h-9.7v6.5h11.7v5.4h-17.9V89z"
            />
            <path
              style={{ fill: "#0F3549" }}
              d="M462.7,89h10.4c3,0,5.4,0.5,7.4,1.9c2,1.4,3.3,3.7,3.3,6.8c0,3.7-2,6.6-5.5,7.9l7.7,11.6h-7.1l-6.9-10.7h-3
              v10.7h-6.2V89z M472.8,101.6c1.6,0,2.6-0.2,3.5-0.8c0.9-0.6,1.3-1.7,1.3-2.9c0-1.2-0.5-2.3-1.3-2.9c-0.8-0.6-1.9-0.8-3.5-0.8
              h-3.8v7.3H472.8z"
            />
          </g>
          <g>
            <g>
              <path
                style={{ fill: "#3592BA" }}
                d="M417.4,57.6c0.3,0.8,0.1,1.5-0.5,2.1c-0.4,0.4-1,0.6-1.6,0.5c-3.1-0.2-8.5-4.6-10.2-6.4
                c1.9,4.2,10.4,22.5,6.4,24.5c-0.3,0.1-0.6,0.2-1,0.2c-0.3,0-0.6-0.1-1-0.2c-2.2-0.8-5.2-4.5-8.6-10.7
                c-2.4-4.3-4.7-10.6-5.4-12.1c0,0.1,0,0.2,0,0.3c-1,9.5-1.9,16.5-2.9,17.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.2,0-0.4-0.2-0.6-0.7
                c-0.5-1.5-0.5-5.8,0-10.6c0.6-5.2,1.7-8.8,2.8-10c0.4-0.4,0.7-0.6,1.1-0.5c1.7,0.4,10.3,15.8,13.2,21.6
                c-2.5-6.6-9.9-21.3-7.4-23.7c0.4-0.4,1.5-0.6,2.5-0.5c2,0.3,4.7,1.6,7.5,3.3C414.8,54.1,417,56.1,417.4,57.6"
              />
              <path
                style={{ fill: "#3592BA" }}
                d="M358.1,26.3c0.1-0.3,0.5-0.5,1-0.7c4.1-1,20.4,4.3,24.3,6.5c-3.4-4.5-8.4-10.7-7.9-12.5
                c0.1-0.2,0.2-0.4,0.4-0.5c1.5-0.6,4.5,2.7,5.8,4.2c1.8,2.1,3.5,4.7,4.7,6.9c0.9,1.8,1.8,3.9,1.2,4.8c-0.2,0.3-0.7,1.5-13.5-2.7
                c-3.4-1.1-8.3-3-10.7-3.8c2.3,1.1,7.1,3.2,10.4,4.8c12.2,5.9,11.6,6.8,11.6,7.2c-0.1,0.7-1.3,1.5-3.2,2.4
                c-1.9,0.9-4.4,1.8-6.6,2.5c-4.6,1.5-10.6,3.7-14.3,3.7c-0.1,0-0.3,0-0.4,0c-1.7-0.1-2.6-0.5-2.7-1.3c-0.1-0.5,0.1-1,0.4-1.5
                c1.2-1.7,5-3.8,11.2-5.2c3.7-0.9,7-1.4,8.3-1.6C374.9,38.3,356.5,30,358.1,26.3"
              />
              <path
                style={{ fill: "#3592BA" }}
                d="M409.7,44.6c3.3,1.2,19.3,7,20.7,10.3c0.1,0.4,0.1,0.7,0,1c-0.3,0.5-0.9,0.7-1.7,0.7c-2.1,0-5.7-1.4-8-2.4
                c-6.6-2.9-15.8-8.6-15.5-10.4c0.2-1.2,3.6-1.6,7.6-2.5c4-0.8,11.2-1.3,13.8-1.7c-4.9,0-20.2-0.3-21.8-1.9
                c-0.1-0.1-0.2-0.3-0.2-0.4c-0.3-2.4,10.8-10.5,14.7-12.1c0.9-0.4,1.4-0.4,1.7-0.1c0.2,0.2,0.3,0.5,0.2,0.8
                c-0.2,1.7-2.8,4.6-10.3,9.3c-0.1,0-0.1,0.1-0.2,0.1c4-0.2,20.5-0.3,24.1,3.1c0.4,0.4,0.7,0.9,0.7,1.4
                C435.4,43.7,413.8,44.5,409.7,44.6"
              />
              <path
                style={{ fill: "#3592BA" }}
                d="M390.5,52.3c-0.1,4.1-1.5,12.7-3.5,18.5c-1.4,4.3-2.8,6.3-4.2,6.3c-0.1,0-0.2,0-0.3,0
                c-0.6-0.1-1.1-0.5-1.4-1.1c-1-2-0.1-6.4,2.6-13.3c1.4-3.8,2.5-6.5,3.3-8.1c-3.9,4.6-9.9,9-12.9,8.8c-0.8-0.1-1.5-0.4-1.9-1.2
                c-1.5-2.6,8.1-11.8,10.7-14.2c0,0-0.1,0-0.1,0c-10.1,4.2-22.9,9.3-24.6,8.5c-0.1-0.1-0.2-0.1-0.2-0.3c-0.1-0.2,0-0.4,0.3-0.7
                c3-2.9,24.6-10.9,27.9-9.8c0.1,0,0.2,0.1,0.3,0.3c0.4,1.4-5.3,8.1-7.5,10.8c2.7-2.2,9.8-7.2,11-6.9
                C390.7,50.1,390.6,51,390.5,52.3"
              />
              <path
                style={{ fill: "#3592BA" }}
                d="M421,15.1c-3.8,6.1-15.7,18.5-19.1,18.5c-0.1,0-0.3,0-0.4-0.1c-2.5-1.1-0.3-20.1,0.3-26
                c-1.5,5.8-5.8,24.1-8.6,24.1c-1.6,0-4.5-2.3-7.8-7.1c-3-4.5-5.6-9.6-6.5-13.1c-0.7-2.5-0.5-4,0.6-4.5c0.7-0.3,1.4-0.2,2.1,0.1
                c4.7,2.1,9.8,15.6,10.7,18.1c0.8-3.3,5.8-23.3,10.3-23.3c0,0,0.1,0,0.1,0c0.4,0,0.7,0.2,0.9,0.5c3.1,3.7,1,16.1,0.1,23.3
                c-0.2,1.4-0.3,2.6-0.4,3.3c0.2-0.2,0.4-0.5,0.7-0.8c9.4-12.4,16-18.3,17.6-17C422.5,11.8,422.3,13.1,421,15.1"
              />
            </g>
          </g>
          <g>
            <polygon
              style={{ fill: "#3592BA" }}
              points="361.3,128.1 361.3,134 353.6,134 353.6,128.1 351.8,128.1 351.8,142.2 353.6,142.2 353.6,135.7 
              361.3,135.7 361.3,142.2 363.1,142.2 363.1,128.1 			"
            />
            <g>
              <path
                style={{ fill: "#3592BA" }}
                d="M381.4,135.1c0-3.1-2.2-5.6-5.3-5.6s-5.3,2.5-5.3,5.6c0,3.1,2.2,5.6,5.3,5.6S381.4,138.2,381.4,135.1
                M368.9,135.1c0-4.1,3.1-7.3,7.2-7.3s7.2,3.2,7.2,7.3s-3.1,7.3-7.2,7.3S368.9,139.2,368.9,135.1"
              />
            </g>
            <polygon
              style={{ fill: "#3592BA" }}
              points="387.5,128.1 387.5,129.7 392.2,129.7 392.2,142.2 394.1,142.2 394.1,129.7 398.8,129.7 398.8,128.1 
                    "
            />
            <polygon
              style={{ fill: "#3592BA" }}
              points="405,128.1 405,142.2 413.3,142.2 413.3,140.5 406.8,140.5 406.8,135.7 412.3,135.7 412.3,134 
              406.8,134 406.8,129.7 413.1,129.7 413.1,128.1 			"
            />
            <polygon
              style={{ fill: "#3592BA" }}
              points="419.6,128.1 419.6,142.2 428.2,142.2 428.2,140.5 421.5,140.5 421.5,128.1 			"
            />
            <g>
              <path
                style={{ fill: "#3592BA" }}
                d="M437.7,140.7c1.8,0,2.9-0.8,2.9-2.3c0-0.9-0.4-1.5-1.4-2c-1-0.5-2.8-0.8-4.1-1.4c-1.2-0.6-2-1.6-2-3.3
                c0-2.5,1.9-4,4.6-4c1.3,0,2.9,0.4,4,1v1.9c-1.3-0.9-2.8-1.2-4-1.2c-1.7,0-2.8,0.7-2.8,2.1c0,1,0.5,1.5,1.5,2
                c1,0.4,2.9,0.7,4.1,1.4c1.2,0.7,1.8,1.8,1.8,3.2c0,2.6-1.9,4.1-4.7,4.1c-1.4,0-3.2-0.4-4.4-1.2v-2
                C434.7,140.2,436.4,140.7,437.7,140.7"
              />
            </g>
          </g>
          <g>
            <path
              style={{ fill: "#103549" }}
              d="M356,226.9c-2.5,0-5.3-0.2-7.6-0.2c-3.4,0-5.1,0.1-5.1,0.1l0.5-1c1.2-2.2,1.5-2.7,1.5-20.9
              c0-17.6-0.2-17.8-1.5-20.3l-0.5-1c0,0,1.8,0.1,5.1,0.1c2.4,0,5.8-0.2,8.4-0.2c6.9,0,23.2,0.5,23.2,20.7
              C380,226.5,364.1,226.9,356,226.9z M356.3,187.9c-2.3,0-4.1,0.1-4.1,0.1v34.6c0,0,1.9,0.1,3.9,0.1c8.2,0,16.4-2.3,16.4-17.6
              C372.5,190.5,364.8,187.9,356.3,187.9z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M415.7,226.8c-3.6,0-6.2,0.1-6.2,0.1c0.8-0.5,1.9-1.6,1.9-3.8c0-2.1-1-5.6-2.2-9.7l-1.1-3.6H395l-1,3.6
              c-1.1,4.2-2.3,7.7-2.3,9.7c0,2.2,1.1,3.3,1.9,3.8c0,0-2.1-0.1-5.6-0.1c-3.6,0-5.4,0.1-5.4,0.1c2.2-2.2,3.4-6,6-14.3l7.7-25.2
              c0.2-0.7,0.4-1.5,0.4-2.2c0-0.8-0.3-1.2-0.4-1.3h12.6c0,0.1-0.4,0.4-0.4,1.3c0,0.8,0.2,1.5,0.4,2.2l7.2,25.2
              c2.4,8.3,3.4,12.2,5.7,14.3C421.9,226.9,419.5,226.8,415.7,226.8z M402.9,190.6c-0.7-2.5-1.1-4.1-1.2-4.5
              c-0.1,0.4-0.5,1.9-1.3,4.5l-4.2,14.8H407L402.9,190.6z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M448,196l-6.6,12.1c0,14.2,0.2,15.4,1.5,17.8l0.5,1c0,0-1.7-0.1-5.5-0.1s-5.6,0.1-5.6,0.1l0.5-1
              c1.1-2.1,1.5-3.6,1.5-16.4l-7.3-13.6c-5.4-10-5.7-10.5-7.9-12.2c0,0,1.8,0.1,6.7,0.1c5,0,6.5-0.1,6.5-0.1c-1,0.8-1.4,1.9-1.4,2.8
              c0,1.7,0.3,2.4,3.1,8.2l4.4,8.8l4.4-8.9c2.8-5.7,2.9-6.4,2.9-8.1c0-1-0.4-2-1.4-2.8c0,0,2.2,0.1,5.7,0.1c4.2,0,5.9-0.1,5.9-0.1
              C454.1,185.1,453.5,186,448,196z"
            />
          </g>
          <g>
            <path
              style={{ fill: "#103549" }}
              d="M202.5,237.4h27v8.4h-17.4v8.8h15v8.1h-15v10h18.1v8.4h-27.7V237.4z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M247.5,258.5l-14.3-21.1h11.3l4.5,6.8c1.6,2.5,3.3,5.1,4.8,7.4h0.1c1.5-2.4,3.2-5.1,4.7-7.4l4.5-6.8h10.8
              l-14.3,21.1l15.5,22.7h-11.4l-5.7-8.6c-1.6-2.4-3.2-4.9-4.7-7.3h-0.1c-1.5,2.4-3,4.8-4.6,7.2l-5.7,8.6H232L247.5,258.5z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M278.5,237.4h15.6c5,0,8.9,0.8,12.1,3.1c3.2,2.4,5.4,6.2,5.4,11.1c0,5-2.2,8.8-5.4,11.1
              c-3.2,2.4-7.1,3.2-12.1,3.2h-6v15.2h-9.6V237.4z M293.7,257.9c2.7,0,4.5-0.3,5.9-1.3s2.3-2.8,2.3-4.8c0-2.1-0.9-3.9-2.3-4.9
              s-3.2-1.3-5.9-1.3h-5.6v12.3H293.7z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M317.1,237.4h27v8.4h-17.4v8.8h15v8.1h-15v10h18.1v8.4h-27.7V237.4z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M351.1,237.4h16.1c4.7,0,8.4,0.8,11.4,3s5.1,5.7,5.1,10.5c0,5.8-3.1,10.2-8.5,12.3l11.9,18h-11l-10.8-16.6
              h-4.7v16.6h-9.6V237.4z M366.6,256.9c2.4,0,4.1-0.3,5.4-1.2c1.3-0.9,2.1-2.6,2.1-4.4c0-1.9-0.8-3.6-2.1-4.5
              c-1.3-0.9-3-1.2-5.4-1.2h-5.9v11.3H366.6z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M390.6,237.4h9.6v43.8h-9.6V237.4z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M408.2,237.4h27v8.4h-17.4v8.8h15v8.1h-15v10h18.1v8.4h-27.7V237.4z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M442.1,237.4h9.1l13.1,17.7c2.8,3.8,5.5,7.5,8.2,11.3h0.1c-0.1-4.2-0.1-8.5-0.1-12.7v-16.3h9.3v43.8h-9.1
              l-13.1-17.7c-2.8-3.8-5.5-7.5-8.2-11.3h-0.1c0.1,4.2,0.1,8.5,0.1,12.7v16.3h-9.3V237.4z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M511.6,281.8c-14,0-23.8-9.7-23.8-22.4c0-12.8,9.8-22.7,23.8-22.7c4.7,0,9.1,1.1,12.5,3v9.5
              c-3.3-2.4-7.7-3.9-12.2-3.9c-8.6,0-14.5,6-14.5,13.9s5.8,14,14.9,14c4.5,0,8.8-1.6,12.2-4v9.4
              C520.9,280.8,516.1,281.8,511.6,281.8z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M530.7,237.4h27v8.4h-17.4v8.8h15v8.1h-15v10h18.1v8.4h-27.7V237.4z"
            />
            <path
              style={{ fill: "#103549" }}
              d="M577.8,273.5c3.9,0,6.4-1.5,6.4-4.5c0-2.1-1.1-3.3-3.9-4.4s-8.2-2.2-11.8-4.3c-3.6-2.1-5.6-5.3-5.6-10.2
              c0-8.4,6.1-13.4,15.3-13.4c4.3,0,9.5,1.1,13.3,3.1v9.3c-4.6-2.8-9.7-4.1-13.1-4.1c-3.6,0-5.9,1.4-5.9,4.2c0,2.1,1.2,3.2,4,4.3
              c2.8,1.1,8.2,2.1,11.9,4.3s5.4,5.7,5.4,10.3c0,8.7-6.4,13.7-15.8,13.7c-4.5,0-10.2-1.2-14.4-3.4v-9.7
              C568.5,272.1,574.4,273.5,577.8,273.5z"
            />
          </g>
        </g>
      </g>
    </svg>
  ),
};

export const WarnerLogoHorizontalLight = (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="94.39 94.6 886.71 255.8"
    style={{ enableBackground: "new 0 0 1075.5 444.8", height: "51px" }}
  >
    <g>
      <g>
        <path
          style={{ fill: "#FAECDE" }}
          d="M386.9,134.4h21.8l12,40.5c2.3,8,4.7,16.4,6.8,24.4h0.3c2.2-8.1,4.7-16.6,7.2-24.5l12.5-40.4h15.1l12.4,40.4
        c2.5,8.1,5,16.4,7.2,24.5h0.3c2.1-7.9,4.6-16.7,7-24.8l12.1-40.1h20.9l-29.1,94.4h-21.9l-10.2-33.2c-2.5-8-4.6-15-6.6-22.6h-0.3
        c-2.1,7.6-4.3,15-6.8,22.9l-10.2,32.8h-21.6L386.9,134.4z"
        />
        <path
          style={{ fill: "#FAECDE" }}
          d="M575.9,192.7l-3.8-10.2c-2.9-7.8-6.3-17.2-8.6-24.2h-0.3c-2.6,7.4-5.9,16.4-9,24.4l-3.9,10H575.9z
         M552.9,134.4H574l37.8,94.4h-22.2L582,209H544l-7.7,19.8h-21.2L552.9,134.4z"
        />
        <path
          style={{ fill: "#FAECDE" }}
          d="M659.5,176.5c5.2,0,8.7-0.7,11.6-2.6c2.9-2,4.4-5.6,4.4-9.5c0-4.2-1.7-7.7-4.4-9.6c-2.7-2-6.4-2.6-11.6-2.6
        h-12.8v24.4H659.5z M626,134.4h34.7c10.2,0,18.1,1.7,24.6,6.4c6.5,4.7,10.9,12.4,10.9,22.7c0,12.5-6.8,22-18.2,26.5l25.7,38.8H680
        l-23.2-35.8h-10v35.8H626V134.4z"
        />
        <path
          style={{ fill: "#FAECDE" }}
          d="M719.2,134.4h19.6l28.3,38.1c6.1,8.2,11.9,16.2,17.7,24.4h0.3c-0.1-9.1-0.3-18.2-0.3-27.4v-35.1h19.9v94.4
        h-19.6l-28.3-38.1c-6.1-8.2-11.9-16.2-17.7-24.4h-0.3c0.1,9.1,0.3,18.2,0.3,27.4v35.1h-19.9V134.4z"
        />
        <polygon
          style={{ fill: "#FAECDE" }}
          points="825.3,134.4 883.6,134.4 883.6,152.5 846.1,152.5 846.1,171.5 878.4,171.5 878.4,189 846.1,189 
        846.1,210.7 885,210.7 885,228.8 825.3,228.8 		"
        />
        <g>
          <path
            style={{ fill: "#FAECDE" }}
            d="M936.9,176.5c5.2,0,8.7-0.7,11.6-2.6s4.4-5.6,4.4-9.5c0-4.2-1.7-7.7-4.4-9.6c-2.7-2-6.4-2.6-11.6-2.6h-12.8
          v24.4H936.9z M903.4,134.4h34.7c10.2,0,18.1,1.7,24.6,6.4c6.5,4.7,10.9,12.4,10.9,22.7c0,12.5-6.8,22-18.2,26.5l25.7,38.8h-23.7
          l-23.2-35.8h-10v35.8h-20.7V134.4z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            style={{ fill: "#3592BA" }}
            d="M292.8,280.5c0.8,2.7,0.2,5.1-1.8,6.9c-1.4,1.3-3.3,1.9-5.4,1.8c-10.3-0.6-28.5-15.4-34-21.4
          c6.4,13.9,34.8,75,21.2,81.9c-1,0.5-2.1,0.7-3.2,0.7c-1,0-2.1-0.2-3.3-0.6c-7.4-2.8-17.2-15.1-28.6-35.9
          c-7.9-14.4-15.7-35.2-17.9-40.4c0,0.3-0.1,0.7-0.1,1.1c-3.2,31.7-6.4,55.2-9.6,57.7c-0.4,0.3-0.8,0.4-1.2,0.4
          c-0.8-0.1-1.4-0.8-1.9-2.2c-1.8-5-1.8-19.5,0.1-35.3c2.1-17.4,5.6-29.3,9.4-33.5c1.2-1.3,2.5-1.8,3.7-1.5
          c5.7,1.4,34.5,52.8,44.2,72c-8.2-22.1-33-71.1-24.7-79.1c1.5-1.4,5.1-2,8.5-1.5c6.6,0.9,15.6,5.2,25.2,10.9
          C284.2,268.7,291.2,275.3,292.8,280.5"
          />
          <path
            style={{ fill: "#3592BA" }}
            d="M94.7,175.9c0.4-1.1,1.5-1.8,3.3-2.3c13.7-3.5,68.2,14.3,81.1,21.6c-11.5-15.2-28-35.8-26.4-41.6
          c0.2-0.8,0.7-1.4,1.4-1.7c5.1-2.1,15.2,9.1,19.3,14c5.9,7.1,11.8,15.7,15.8,23.2c3.2,5.9,5.9,13.1,3.9,16.2
          c-0.7,1.1-2.3,4.9-45-8.9c-11.3-3.7-27.6-9.9-35.6-12.8c7.8,3.5,23.8,10.8,34.6,16c40.7,19.6,38.8,22.7,38.6,23.9
          c-0.3,2.2-4.2,5.1-10.7,8.1c-6.5,3-14.7,6-22.1,8.5c-15.3,5.1-35.4,12.3-47.7,12.3c-0.5,0-1,0-1.5,0c-5.5-0.2-8.5-1.7-9-4.4
          c-0.3-1.7,0.2-3.4,1.4-5.1c4.1-5.6,16.7-12.6,37.4-17.3c12.5-2.9,23.5-4.7,27.6-5.3C150.8,215.9,89.5,188.2,94.7,175.9"
          />
          <path
            style={{ fill: "#3592BA" }}
            d="M267,237c11,3.9,64.6,23.2,69,34.4c0.5,1.2,0.5,2.3,0,3.2c-0.9,1.7-2.9,2.4-5.7,2.4c-7,0-19.2-4.8-26.8-8.1
          c-22-9.6-52.6-28.6-51.6-34.8c0.7-4.2,11.9-5.5,25.3-8.2c13.4-2.7,37.3-4.2,46-5.5c-16.4,0.1-67.4-1.1-72.7-6.5
          c-0.4-0.4-0.6-0.9-0.6-1.4c-1-8.2,36.1-35,49-40.5c2.9-1.2,4.8-1.3,5.7-0.3c0.6,0.7,0.9,1.5,0.8,2.5c-0.7,5.5-9.4,15.4-34.4,31.2
          c-0.2,0.1-0.4,0.3-0.6,0.4c13.2-0.7,68.3-1.1,80.5,10.2c1.5,1.4,2.2,2.9,2.2,4.6C352.9,233.9,280.5,236.5,267,237"
          />
          <path
            style={{ fill: "#3592BA" }}
            d="M203.1,262.6c-0.5,13.8-5.1,42.4-11.6,61.9c-4.8,14.4-9.5,21.2-14.1,21.2c-0.3,0-0.6,0-0.9-0.1
          c-2-0.4-3.6-1.6-4.5-3.6c-3.2-6.6-0.3-21.2,8.6-44.4c4.8-12.5,8.5-21.6,11.1-27c-13,15.5-33.2,30-43.2,29.4
          c-2.8-0.2-5-1.4-6.5-3.9c-4.9-8.6,27.2-39.4,35.8-47.5c-0.1,0.1-0.3,0.1-0.4,0.2c-33.6,14-76.4,30.9-82.1,28.2
          c-0.4-0.2-0.7-0.5-0.8-0.9c-0.2-0.7,0.2-1.5,1.1-2.4c9.9-9.6,82.2-36.5,93.2-32.6c0.4,0.2,0.8,0.5,0.9,0.9
          c1.4,4.5-17.6,27-25,35.9c9.1-7.4,32.7-24,36.9-23.1C203.5,255.4,203.2,258.3,203.1,262.6"
          />
          <path
            style={{ fill: "#3592BA" }}
            d="M304.7,138.4c-12.8,20.3-52.6,61.8-63.6,61.8c-0.5,0-0.9-0.1-1.2-0.2c-8.4-3.6-1.1-67.2,1.1-86.7
          c-5,19.5-19.3,80.6-28.6,80.5c-5.4,0.1-15.1-7.8-26-23.8c-10.2-15-18.7-32.2-21.7-43.7c-2.2-8.4-1.5-13.4,2.1-14.9
          c2.2-0.9,4.6-0.8,7.2,0.3c15.8,7.1,32.6,51.9,35.7,60.5c2.6-10.9,19.4-77.6,34.4-77.6c0.1,0,0.3,0,0.4,0c1.2,0.1,2.2,0.7,3.1,1.8
          c10.3,12.4,3.3,53.7,0.5,77.7c-0.5,4.7-1,8.6-1.2,11.2c0.5-0.6,1.3-1.6,2.2-2.7c31.4-41.4,53.5-61,58.8-56.7
          C309.9,127.4,309,131.7,304.7,138.4"
          />
        </g>
      </g>
    </g>
    <g>
      <polygon
        style={{ fill: "#3592BA" }}
        points="447.4,265.6 447.4,285.5 421.7,285.5 421.7,265.6 415.6,265.6 415.6,312.7 421.7,312.7 421.7,291.1 
      447.4,291.1 447.4,312.7 453.6,312.7 453.6,265.6 	"
      />
      <g>
        <path
          style={{ fill: "#3592BA" }}
          d="M514.4,289.2c0-10.3-7.5-18.5-17.8-18.5c-10.3,0-17.8,8.2-17.8,18.5s7.5,18.5,17.8,18.5
        C506.9,307.7,514.4,299.5,514.4,289.2 M472.7,289.2c0-13.7,10.3-24.3,23.9-24.3c13.7,0,23.9,10.6,23.9,24.3s-10.3,24.3-23.9,24.3
        C483,313.5,472.7,302.9,472.7,289.2"
        />
      </g>
      <polygon
        style={{ fill: "#3592BA" }}
        points="534.7,265.6 534.7,271.2 550.5,271.2 550.5,312.7 556.6,312.7 556.6,271.2 572.5,271.2 572.5,265.6 	
      "
      />
      <polygon
        style={{ fill: "#3592BA" }}
        points="593,265.6 593,312.7 620.8,312.7 620.8,307.1 599.2,307.1 599.2,291 617.6,291 617.6,285.5 
      599.2,285.5 599.2,271.2 620.2,271.2 620.2,265.6 	"
      />
      <polygon
        style={{ fill: "#3592BA" }}
        points="642,265.6 642,312.7 670.4,312.7 670.4,307.1 648.2,307.1 648.2,265.6 	"
      />
      <g>
        <path
          style={{ fill: "#3592BA" }}
          d="M702.3,307.8c5.9,0,9.7-2.7,9.7-7.7c0-2.9-1.2-4.9-4.6-6.5c-3.3-1.6-9.4-2.6-13.5-4.6
        c-4.1-2-6.7-5.4-6.7-10.9c0-8.3,6.2-13.2,15.4-13.2c4.4,0,9.7,1.2,13.4,3.4v6.4c-4.2-2.9-9.5-4.2-13.4-4.2c-5.5,0-9.2,2.5-9.2,7.1
        c0,3.3,1.6,5.1,4.9,6.5c3.3,1.4,9.8,2.5,13.8,4.7c4,2.3,6.1,5.9,6.1,10.7c0,8.7-6.3,13.8-15.7,13.8c-4.8,0-10.6-1.4-14.8-4v-6.7
        C692.1,306.3,698,307.8,702.3,307.8"
        />
      </g>
    </g>
  </svg>
);
