import React, { useContext } from "react";

import { formatTime, formatDate, getCurrentDate } from "../../../../helpingFunctions/dateTimeUtilities";
import { formatPrice } from "../../../../helpingFunctions/formatPrice";

import styles from "../Views/styles/ConfirmationScreenView.module.scss";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import { DayUseContext } from "../../../../context/DayUseContext";


const DayUseConfirmationScreenCard = ({
  index,
  row,
  source,
  dayUseCCDetails,
  dayUseOccasions,
}) => {
  const {
    dayUseTexts
  } = useContext(DayUseContext);

  return (
    <>
      <div
        key={index}
        className={styles.day_use_confirmation_screen_card_wrapper}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex" }}>
            <p>{`Reservation ID:`}</p>
            {row.bookingId ? (
              <span
                style={{
                  font: "normal normal bold 16px/22px Arial",
                  paddingLeft: "10px",
                }}
              >
                {row.bookingId}
              </span>
            ) : null}
          </div>
          {source === "confScreen" ? (
            <span
              style={{
                color: row.status === "Booked" ? "#7B9A61" : "var(--ion-color-danger)",
                font: "normal normal bold 16px/22px Arial",
                display: "flex",
              }}
            >
              <p
                style={{
                  paddingRight: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* {dayUseCheck(16, 16)} */}
              </p>
              {row.status === "Booked" ? "Success" : "Failed"}
            </span>
          ) : null}
        </div>
        <p
          style={{
            paddingTop: "15px",
            paddingBottom: "15px",
            font: "normal normal bold 18px/24px Arial",
            color: "var(--ion-color-primary)",
          }}
        >
          Reservation Summary
        </p>
        <div style={{ paddingLeft: "10px" }}>
          <div className={styles.day_use_confirmation_screen_row}>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ fontWeight: "bold" }}
            >
              Name:
            </p>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ paddingLeft: "5px", width: "100%" }}
            >
              {row.name}
            </p>
          </div>
          <div className={styles.day_use_confirmation_screen_row}>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ fontWeight: "bold" }}
            >
              Experience:
            </p>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ paddingLeft: "5px", width: "100%" }}
            >
              {row.experience}
            </p>
          </div>
          <div className={styles.day_use_confirmation_screen_row}>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ fontWeight: "bold" }}
            >
              Hotel:
            </p>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ paddingLeft: "5px", width: "100%" }}
            >
              {row.hotel}
            </p>
          </div>
          <div className={styles.day_use_confirmation_screen_row}>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ fontWeight: "bold" }}
            >
              Date:
            </p>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ paddingLeft: "5px", width: "100%" }}
            >
              {formatDate(row.date)}
            </p>
          </div>
          <div className={styles.day_use_confirmation_screen_row}>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ fontWeight: "bold" }}
            >
              Time slot:
            </p>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ paddingLeft: "5px", width: "100%" }}
            >
              {formatTime(`${getCurrentDate()}T${row.time.substring(0, 5)}`, true)}
            </p>
          </div>
          <div className={styles.day_use_confirmation_screen_row}>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ fontWeight: "bold" }}
            >
              Guests:
            </p>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ paddingLeft: "5px", width: "100%" }}
            >
              {`${row.guests} ${row.guests === 1 ? "adult" : "adults"}`}
            </p>
          </div>
          {dayUseOccasions?.length > 0 &&
            <div className={styles.day_use_confirmation_screen_row}>
              <p
                className={styles.day_use_confirmation_screen_cell}
                style={{ fontWeight: "bold" }}
              >
                Occasion:
              </p>
              <p
                className={styles.day_use_confirmation_screen_cell}
                style={{ paddingLeft: "5px", width: "100%" }}
              >
                {row.occasion}
              </p>
            </div>
          }
          {source !== "bookedDayUseView" && dayUseCCDetails && (
            <div className={styles.day_use_confirmation_screen_row}>
              <p
                className={styles.day_use_confirmation_screen_cell}
                style={{ fontWeight: "bold" }}
              >
                Credit card:
              </p>
              <p
                className={styles.day_use_confirmation_screen_cell}
                style={{ paddingLeft: "5px", width: "100%" }}
              >
                {`${dayUseCCDetails?.cardType.charAt(0).toUpperCase() +
                  dayUseCCDetails?.cardType.slice(1).toLowerCase()} / **** **** **** ${dayUseCCDetails.cardNums}`}
              </p>
            </div>
          )}
          <div className={styles.day_use_confirmation_screen_row}>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{ fontWeight: "bold" }}
            >
              Total Price:
            </p>
            <p
              className={styles.day_use_confirmation_screen_cell}
              style={{
                paddingLeft: "5px",
                width: "100%",
              }}
            >
              {formatPrice(Number(row.price) * row.guests) || 0}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              paddingTop: "16px",
              paddingBottom: "16px",
            }}
          >
            {row.status === "Booked" && source === "confScreen" ? (
              <BLParagraph
                txt={dayUseTexts?.Paragraph_ChargeInformationText_ConfirmationScreen}
                markDown
                color="primary"
              />
            ) : (
              <BLParagraph
                txt={dayUseTexts?.Paragraph_ChargeInformationText_BookingScreen}
                markDown
                color="primary"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DayUseConfirmationScreenCard;
