import React from "react";

import WindowWidth from "../../hooks/WindowWidth";
import Tooltip from "../Tooltip/Tooltip";

import styles from "./Input.module.scss";

const InputLabel = ({
  txt,
  tooltipContent,
  icon,
  labelNewStyles,
  tooltipNewStyles,
}) => {
  const { width } = WindowWidth();

  let ToolTipIcon = null;
  if (tooltipContent) {
    ToolTipIcon = (
      <Tooltip
        info={tooltipContent}
        infoIcon={"/img/day_use_icons/info-information.svg"}
        iconSize={"25px"}
        new_styles={{
          marginLeft: "10px",
          marginTop: width > 399 ? "17px" : "20px",
          width: "auto",
        }}
        toolTipBoxStyles={tooltipNewStyles}
        directionClass={width > 610 || width <= 400 ? "" : "left"}
      />
    );
  }

  return (
    <label className={styles.LoginInputLabel} style={{ ...labelNewStyles }}>
      {icon ? (
        <img
          className={styles.LoginInput__icon}
          src={`/img/icons/login/${icon}`}
          alt="no img"
        />
      ) : null}
      {txt} {ToolTipIcon}
    </label>
  );
};

export default InputLabel;
