import { useState } from "react";

import axiosInstance from "../../../../../url/createAxios";

const CardRetryLogic = (changeModalPage) => {
  const [pageMessage, setPageMessage] = useState(
    "There was a problem with card registration!"
  );

  const instance = axiosInstance();
  const pxpCallback = async () => {
    setTimeout(async () => {
      const { data } = await instance.post(`/pxp/callback?isDayUse=true`, {
        withCredentials: true,
        callbackStatus: "success",
      });
      if (data?.status === "success" && data.state === "Authorised") {
        changeModalPage((prevState) => {
          return { ...prevState, page: "CardConfirmation" };
        });
      } else {
        setPageMessage(
          "We could not add the credit card to your reservation. Please try again later!"
        );
      }
    }, 3100);
  };

  return { pxpCallback, pageMessage };
};

export default CardRetryLogic;
