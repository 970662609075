import React, { useContext, useEffect } from "react";
import Heading from "../../../../shared/Heading/Heading";
import Button from "../../../../shared/Button/Button";
import RegistrationScreenNav from "../SectionComponents/Navigation/RegistrationScreenNav";
import RegistrationViewLogic from "./Logic/RegistrationViewLogic";
import DayUseBasketCard from "../SectionComponents/DayUseBasketCard";
import LoadingContainer from "../../../../shared/LoadingContainer/LoadingContainer";
import styles from "./styles/RegistrationView.module.scss";
import { DayUseContext } from "../../../../context/DayUseContext";
import PersonalDetailsDisclaimer from "../../../../components/PersonalDetailsDisclaimer/PersonalDetailsDisclaimer";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../constants/constants";

const RegistrationViewPreConfirmView = ({ isDesktop }) => {
  const {
    registrationFormData,
    setRegistrationFormData,
    revertDayUseForm,
    initiateBookingProcedure,
    setDayUseSelections,
    handleEditAction,
    processedRecordsResults,
    dayUseSelections,
    isProcessingDayUseBookings,
    dayUseCCDetails,
    dayUseOccasions,
  } = useContext(DayUseContext);

  const history = useHistory();

  useEffect(() => {
    if (!dayUseSelections?.length || 
      !registrationFormData?.email || 
      !registrationFormData?.firstName ||
      !registrationFormData?.lastName ||
      !registrationFormData?.phone ||
      !dayUseCCDetails
    ) {
      history.push(ROUTES.BASKET);
    }
  }, []);

  const {
    hasFormErrors,
  } = RegistrationViewLogic(
    registrationFormData,
    setRegistrationFormData,
  );


  const errBox = () => {
    return (
      <>
        {hasFormErrors && (
          <div
            className={`${styles.day_use_register_view_wrapper} ${styles.day_use_reg_view_error_wrapper}`}
          >
            <img
              className={styles.day_use_params_sel_section_icon}
              src={`/img/info_icons/exclamation.svg`}
              alt="no img"
            />
            <p style={{ color: "white", paddingLeft: "15px" }}>
              Please make sure to fill all required fields marked with *.
            </p>
          </div>
        )}

        {isProcessingDayUseBookings ? (
          <LoadingContainer />
        ) : processedRecordsResults.hasOwnProperty("error") ? (
          <div
            className={`${styles.day_use_register_view_wrapper} ${styles.day_use_reg_view_error_wrapper}`}
          >
            <img
              className={styles.day_use_params_sel_section_icon}
              src={`/img/info_icons/exclamation.svg`}
              alt="no img"
            />
            <p style={{ color: "white", paddingLeft: "15px" }}>
              {processedRecordsResults.error}
            </p>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      <RegistrationScreenNav />

      <Heading
        txt={"Reserve"}
        new_styles={{
          paddingBottom: "24px",
          textAlign: "center",
          fontFamily: "Georgia",
          fontSize: "26px",
          textTransform: "uppercase",
          color: "var(--ion-color-primary)",
        }}
      />

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          font: "normal normal normal 16px/22px Arial",
          color: "var(--ion-color-primary)",
        }}
      >
        <p>
          {
            "Thank you for providing your personal and credit card details. Here is the summary of your reservation, please review and book."
          }
        </p>
        <p
          style={{
            marginTop: "15px",
            marginBottom: "10px",
            font: "normal normal 600 16px/22px Arial",
            color: "var(--ion-color-primary)",
          }}
        >
          {`Your experience${dayUseSelections.length > 1 ? "s" : ""} details`}
        </p>

        {dayUseSelections?.map((sel, index) => (
          <DayUseBasketCard
            key={index}
            selection={sel}
            index={index}
            setSelections={setDayUseSelections}
            handleEditAction={handleEditAction}
            isDesktop={isDesktop}
            hideActionIcons
          />
        ))}

        <p
          style={{
            marginTop: "15px",
            marginBottom: "10px",
            font: "normal normal 600 16px/22px Arial",
            color: "var(--ion-color-primary)",
          }}
        >
          {`Your personal details`}
        </p>

        <div
          style={{
            backgroundColor: "var(--ion-color-secondary)",
            padding: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            <p style={{ fontWeight: "bold" }}>Name:</p>
            <p>{`${registrationFormData.firstName} ${registrationFormData.lastName}`}</p>
          </div>
          <div style={{ display: "flex", gap: "15px", flexWrap: "wrap" }}>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width: "48%",
                flexWrap: "wrap",
              }}
            >
              <p style={{ fontWeight: "bold" }}>E-mail:</p>
              <p>{`${registrationFormData.email}`}</p>
            </div>

            <div
              style={{
                display: "flex",
                gap: "10px",
                width: "48%",
                flexWrap: "wrap",
              }}
            >
              <p style={{ fontWeight: "bold" }}>Telephone:</p>
              <p>{`${registrationFormData.phone}`}</p>
            </div>
          </div>

          {dayUseOccasions?.length > 0 &&
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              <p style={{ fontWeight: "bold" }}>Occasion:</p>
              <p>
                {registrationFormData.occasion &&
                  `${dayUseOccasions?.find(occasion => occasion.id === registrationFormData.occasion).Title}`
                }
              </p>
            </div>
          }

          <div
            style={{
              background: "white",
              gap: "20px",
              display: "flex",
              padding: "8px",
              flexWrap: "wrap",
            }}
          >
            <p style={{ fontWeight: "bold" }}>Credit card:</p>
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              <p style={{ fontWeight: "bold" }}>Type:</p>
              <p>{`${dayUseCCDetails?.cardType.charAt(0).toUpperCase() +
                dayUseCCDetails?.cardType.slice(1).toLowerCase()}`}</p>
            </div>
            <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
              <p style={{ fontWeight: "bold" }}>Number:</p>
              <p>{"**** **** **** " + dayUseCCDetails?.cardNums}</p>
            </div>
          </div>
        </div>
      </div>


      <div style={{ marginTop: "16px" }}><PersonalDetailsDisclaimer /></div>

      {errBox()}

      <div
        className={styles.day_use_reg_view_btns_wrapper}
        style={{ marginTop: "20px", marginBottom: "30px" }}
      >
        <Button
          txt="Cancel"
          type="primary-inverse"
          onClick={() => {
            revertDayUseForm();
            history.push(ROUTES.BASKET);
          }}
        />

        <Button
          txt={"Reserve"}
          onClick={initiateBookingProcedure}
          opacity={isProcessingDayUseBookings ? "0.4" : 1}
          pointerEvents={isProcessingDayUseBookings && "none"}
        />
      </div>
    </>
  );
};

export default RegistrationViewPreConfirmView;
