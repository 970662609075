import React from "react";
import Markdown from "markdown-to-jsx";

import styles from "./information-text.module.scss";

const InformationText = ({ lineHeight, content, className, markdown, new_styles }) => {
  return (
    <div
      className={`${styles.infoWrapper} ${className}`}
      style={{
        lineHeight,
        ...new_styles
      }}
    >
      {markdown && content ? <Markdown>{content}</Markdown> : content}
    </div>
  );
};

export default InformationText;
