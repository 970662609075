import { useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";

import { goToTheTop } from "../helpingFunctions/linkChange";

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    goToTheTop();
  }, [location.pathname]);

  return null;
};

export default withRouter(ScrollToTop);
