import React from "react";

import Button from "../../../../../shared/Button/Button";
import LogoAndTitle from "../../components/LogoAndTitle/LogoAndTitle";
import CardRetryLogic from "./CardRetryLogic";

import styles from "./CardRetry.module.scss";

const CardRetry = ({ changeModalPage, removeModal }) => {
  const { pxpCallback, pageMessage } = CardRetryLogic(changeModalPage);
  return (
    <>
      <LogoAndTitle removeModal={removeModal} title={"Register Credit Card"} />
      <div className={styles.CardRetry}>
        <p>{pageMessage}</p>
        <Button
          txt={"Retry"}
          new_styles={{
            color: "var(--ion-color-secondary)",
            backgroundColor: "var(--ion-color-primary)",
            marginTop: "25px"
          }}
          onClick={pxpCallback}
        />
      </div>
    </>
  );
};

export default CardRetry;
