import React from "react";
import { IonIcon } from "@ionic/react";

const BLIcon = ({
  customIconSource,
  iconSource,
  onClick,
  color,
  disable,
  iconSize = "sm",
  newStyles,
  newClasses,
}) => {
  return customIconSource ? (
    <div
      className={`${newClasses} bl-flex ion-align-items-center ${
        (onClick || color === "secondary") && "bl-text-pointer"
      }`} style={{ ...newStyles }}
      onClick={onClick ? onClick : null}
    >
      {customIconSource}
    </div>
  ) : (
    <IonIcon
      className={`${`bl-icon-${iconSize}`} ${
        disable ? "bl-disable-fade" : ""
      } ${
        (onClick || color === "secondary") && "bl-text-pointer"
      } ${newClasses}`}
      icon={iconSource}
      color={color}
      onClick={onClick ? onClick : null}
      style={{ ...newStyles }}
    />
  );
};

export default BLIcon;
