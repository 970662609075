import React from "react";
import Button from "../../../../shared/Button/Button";
import styles from "./DayUseAvailabilitySection.module.scss";
import { formatTime } from "../../../../helpingFunctions/dateTimeUtilities";

const DayUseAvailabilitySectionLogic = (
  selections,
  params,
  handleSelectionAction
) => {
  const isSelected = (availObj) => {
    return selections.find(
      (row) =>
        row.activityId === params.activityId &&
        row.FacilityDate === availObj.FacilityDate
    );
  };

  const getRowSummary = (availObj, index) => {
    const availQuantity = Number(availObj.FacilityQuantity);
    const selectedQuantity = params.count;
    let msg;
    let icon;

    if (
      availQuantity >= 1 &&
      availQuantity <= 3 &&
      availQuantity >= selectedQuantity
    ) {
      msg = `Hurry, only ${availQuantity} slots left`;
      icon = "alarm-clock.svg";
    } else if (availQuantity >= selectedQuantity) {
      icon = "done_blue.svg";
      msg = `Available slots: ${availQuantity}`;
    } else {
      msg = `Unavailable (available slots: ${availQuantity})`;
      icon = "error_blue.svg";
    }

    return (
      <div
        className={`${styles.day_use_avail_row_wrapper} ${
          msg?.includes("Unavailable") && !isSelected(availObj)
            ? styles.fade
            : null
        }`}
      >
        <div
          style={{
            height: "auto",
            minHeight: "44px",
            background: "white",
            width: "100%",
            border: isSelected(availObj) ? "2px solid var(--ion-color-primary)" : "2px solid var(--ion-color-secondary)",
            display: "flex",
            alignItems: "center",
            padding: "2px 8px",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              className={styles.day_use_avail_section_icon}
              src={`/img/day_use_icons/${icon}`}
              alt=""
            />
            <div>
              <p
                style={{
                  padding: "0 10px",
                  width: "100%",
                  font: "normal normal bold 16px/22px Arial",
                }}
              >
                {formatTime(availObj.FacilityDate.slice(0, 19), true)}
              </p>
              {msg && (
                <p
                  style={{
                    padding: "0 10px",
                    width: "100%",
                    font: "normal normal normal 16px/22px Arial",
                  }}
                >
                  {msg}
                </p>
              )}
            </div>
          </div>
          {isSelected(availObj) && (
            <img
              style={{ margin: "5px 0" }}
              className={styles.day_use_avail_section_icon}
              src={`/img/day_use_icons/check_blue.svg`}
              alt=""
            />
          )}
        </div>

        <Button
          new_styles={{
            paddingLeft: "15px",
            paddingRight: "15px",
            opacity:
              msg === "Unavailable" && !isSelected(availObj) ? "0.4" : "1",
            pointerEvent:
              msg === "Unavailable" && !isSelected(availObj) ? "none" : "auto",
            minWidth: "130px",
          }}
          txt={isSelected(availObj) ? "Deselect" : "Select"}
          onClick={() => handleSelectionAction(availObj, index)}
          type={isSelected(availObj) ? "primary-inverse" : "primary"}
          height={48}
          minHeight={48}
          width={130}
        />
      </div>
    );
  };

  return {
    getRowSummary,
  };
};

export default DayUseAvailabilitySectionLogic;
