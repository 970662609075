import { useEffect, useState } from "react";

const ImageCarouselLogic = (imageLength, autoSlide) => {
  const [leftPosition, setLeftPosition] = useState(0);
  const [isLoginInfoOpened, setIsLoginInfoOpened] = useState(false);

  const handleSliderLeft = () => {
    if (leftPosition === 0) return;

    setLeftPosition((prevState) => prevState + 100);
  };

  const handleSliderRight = () => {
    if (leftPosition === -100 * imageLength) return;

    setLeftPosition((prevState) => prevState - 100);
  };

  const handleSliderSkip = (skip) => {
    setLeftPosition(skip);
  };

  const imageDisplayed = () => {
    let imageIndex = 1;

    if (leftPosition === 0) return imageIndex;

    let leftPositionClone = leftPosition;

    while (leftPositionClone !== 0) {
      leftPositionClone += 100;
      imageIndex++;
    }

    return imageIndex;
  };

  const loginInfoClickHandler = (x) => {
    setIsLoginInfoOpened(x);
  };

  useEffect(() => {
    loginInfoClickHandler(false);
  }, [leftPosition]);
  useEffect(() => {
    if (!autoSlide || isLoginInfoOpened) return;
    let arrived = false;
    const carouselInterval = setInterval(() => {
      setLeftPosition((prevState) => {
        if (prevState > -100 * imageLength && !arrived) {
          return prevState - 100;
        } else if (prevState < 0) {
          arrived = true;
          return prevState + 100;
        } else {
          arrived = false;
          return 0;
        }
      });
    }, 5000);

    return () => {
      clearInterval(carouselInterval);
    };
  }, [isLoginInfoOpened]);

  return {
    handleSliderLeft,
    handleSliderRight,
    handleSliderSkip,
    imageDisplayed,
    loginInfoClickHandler,
    isLoginInfoOpened,
    leftPosition,
  };
};

export default ImageCarouselLogic;
