import { CLASS_CONSTANTS } from "../../constants/constants";

const classFormatter = (
  classes,
  cssClassAddition = CLASS_CONSTANTS.CUSTOM_MADE_CLASS_PREFIX
) => {
  try {
    if (!classes) {
      return "";
    }
    let formattedNewClasses = "";
    classes.split(" ").map((newClass) => {
      const isIonicClass = newClass.includes(CLASS_CONSTANTS.IONIC_CSS_CLASS_IDENTIFIER);

      const foundClass = isIonicClass
        ? `${newClass} `
        : `${cssClassAddition}${newClass}`;

      if (!foundClass.includes(CLASS_CONSTANTS.CLASS_NOT_FOUND_TEXT)) {
        formattedNewClasses += `${foundClass} `;
      }
    });

    return formattedNewClasses;
  } catch (error) {
    console.error(
      `${new Date()} Error in classFormatter in BLIonicHelper.js ${error.message
      }`
    );
  }
};

const cssModuleClassConvert = (cssModuleVariable, classes) => {
  try {
    if (!classes) {
      return "";
    }
    let convertedClasses = "";

    const splittedBySpace = classes.split(" ").filter(i => i);

    for (let index = 0; index < splittedBySpace.length; index++) {
      const regularClass = splittedBySpace[index];

      if (cssModuleVariable[regularClass])
        convertedClasses += `${cssModuleVariable[regularClass]} `;
      else convertedClasses += `${regularClass} `;
    }

    return convertedClasses;
  } catch (error) {
    console.error(
      `${new Date()} Error in cssModuleClassConvert in BLIonicHelper.js ${error.message
      }`
    );
  }
};

export { classFormatter, cssModuleClassConvert };
