import React, { useEffect } from "react";
import LoadingContainer from "../../../../shared/LoadingContainer/LoadingContainer";
import WindowWidth from "../../../../hooks/WindowWidth";
import SelectSearch from "../../../../components/SelectSearch/SelectSearch";
import Input from "../../../../components/Input/Input";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { add, format, parseISO  } from "date-fns";

import styles from "./DayUseParamsSelSection.module.scss";
import {
  getCurrentDate,
  getDatesBetween,
} from "../../../../helpingFunctions/dateTimeUtilities";
import { ThemeProvider, createTheme } from "@mui/material";

const datePickerTheme = createTheme(
  {
    palette: {
      primary: { main: "#0F3448" }
    }
  }
);

const DayUseParamsSelSection = ({
  dayUseDetails,
  isLoadingActivities,
  selectedHotelActivities,
  updateDetailsSelection,
  updateGuestCount,
}) => {
  const { width } = WindowWidth();

  const getDatePlaceholder = () => {
    const today = new Date().toISOString().split("T")[0].split("-");
    return `${today[2]}.${today[1]}.${today[0]}`;
  };

  const currentDate = getCurrentDate();
  const endDate = add(new Date(), {
    years: 0,
    months: 0,
    weeks: 0,
    days: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  const datesToDisable = getDatesBetween(currentDate, endDate);

  const nextAvailableDate = add(new Date(datesToDisable[1]), {
    years: 0,
    months: 0,
    weeks: 0,
    days: 1,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (selectedHotelActivities) {
      if (!dayUseDetails?.date) {
        updateDetailsSelection("date", format(nextAvailableDate, "yyyy-MM-dd"));
      }

      if (!dayUseDetails?.activityId && selectedHotelActivities[0]) {
        updateDetailsSelection("activityId", selectedHotelActivities[0]?.id);
      }
    }
  }, [selectedHotelActivities]);
  
  return (
    <>
      <div
        className={styles.day_use_params_sel_section_wrapper}
        style={{ height: width > 420 ? "auto" : "400px" }}
      >
        {!isLoadingActivities ? (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              gap: "20px",
            }}
          >
            {selectedHotelActivities && (
              <>
                <Input
                  txt={"Select an experience"}
                  labelNewStyles={{
                    paddingLeft: "40px",
                  }}
                  loginInputNewStyles={{ flex: "1", minWidth: "255px" }}
                  newStylesIcon={{ marginBottom: "30px" }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      className={styles.day_use_params_sel_section_icon}
                      src={`/img/day_use_icons/local-activity.svg`}
                      alt="no img"
                    />
                    <SelectSearch
                      data={selectedHotelActivities}
                      placeholder={
                        selectedHotelActivities[0]
                          ? selectedHotelActivities[0].Title
                          : "No experiences available, please select a different location"
                      }
                      manageFormData={(value) =>
                        updateDetailsSelection("activityId", value)
                      }
                      selectedValueId={dayUseDetails?.activityId}
                      newAutoCompleteWrapperStyles={{
                        width: "100%",
                        paddingLeft: "10px",
                      }}
                      textFieldWrapperHeight={48}
                    />
                  </div>
                </Input>
                <Input
                  txt={"No. of Adults"}
                  newStylesWrapper={{
                    minWidth: "200px",
                  }}
                  loginInputNewStyles={{ minWidth: "200px" }}
                  labelNewStyles={{
                    justifyContent: "left",
                    paddingLeft: "40px",
                  }}
                  newStylesIcon={{ marginBottom: "30px" }}
                >
                  <div
                    className={styles.day_use_params_sel_section_field_wrapper}
                  >
                    <img
                      className={styles.day_use_params_sel_section_icon}
                      src={`/img/day_use_icons/open-people.svg`}
                      alt="no img"
                    />
                    <div
                      className={
                        styles.day_use_params_sel_section_guests_field_wrapper
                      }
                    >
                      <img
                        style={{ paddingLeft: "10px", cursor: "pointer" }}
                        src="/img/day_use_icons/minus.svg"
                        alt=""
                        onClick={() => updateGuestCount("minus")}
                      />
                      <input
                        className={
                          styles.day_use_params_sel_section_guests_input_field
                        }
                        type="number"
                        value={dayUseDetails.count}
                        disabled
                      />
                      <img
                        style={{ paddingRight: "10px", cursor: "pointer" }}
                        src="/img/day_use_icons/plus.svg"
                        alt=""
                        onClick={() => updateGuestCount("plus")}
                      />
                    </div>
                  </div>
                </Input>
                <Input
                  txt={"Date"}
                  newStylesWrapper={{
                    width: "180px",
                  }}
                  labelNewStyles={{
                    justifyContent: "left",
                    paddingLeft: "40px",
                  }}
                  newStylesIcon={{ marginBottom: "30px" }}
                >
                  <div
                    className={styles.day_use_params_sel_section_field_wrapper}
                  >
                    <img
                      className={styles.day_use_params_sel_section_icon}
                      src={`/img/day_use_icons/calendar.svg`}
                      alt="no img"
                    />
                    <ThemeProvider theme={datePickerTheme}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          disablePast
                          inputFormat="dd.MM.yyyy"
                          style={{
                            background: "white",
                            alignItems: "center",
                          }}
                          value={parseISO(dayUseDetails?.date
                            ? dayUseDetails.date
                            : nextAvailableDate)
                          
                          }
                          showToolbar={false}
                          onChange={(value) =>
                            updateDetailsSelection("date", format(value, "yyyy-MM-dd"))
                          }
                          shouldDisableDate={(date) =>
                            datesToDisable.includes(format(date, "yyyy-MM-dd"))
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{
                                height: "48px",
                                background: "white",
                                justifyContent: "center",
                                input: { cursor: 'pointer', color: "var(--ion-color-primary)" },
                              }}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: getDatePlaceholder(),
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </ThemeProvider>
                  </div>
                </Input>
              </>
            )}
          </div>
        ) : (
          <LoadingContainer />
        )}
      </div>
    </>
  );
};

export default DayUseParamsSelSection;
