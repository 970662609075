import React from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./main-footer.module.css";
import { format } from "date-fns";
import { ROUTES } from "../../constants/constants";

const MainFooter = () => {
  const location = useLocation();

  if (
    location.pathname.includes("kiosk") ||
    location.pathname.includes("pxp")
  ) {
    return null;
  }

  return (
    <div className={styles.mainFooterWrapper}>
      <div className={styles.policiyLinks}>
        <a
          className={styles.policiesLink}
          href="https://www.warnerleisurehotels.co.uk/company/terms-and-conditions"
          target={"_blank"}
          rel="noreferrer"
        >
          Terms and Conditions
        </a>
        <span style={{margin: "0 5px"}}>|</span>
        <Link
          className={styles.policiesLink}
          to={ROUTES.PRIVACY}
          style={{
            pointerEvents: location.pathname === ROUTES.PRIVACY ? "none" : "auto",
          }}
        >
          Privacy policy
        </Link>
        <span style={{margin: "0 5px"}}>|</span>
        <a className={`${styles.policiesLink} ot-sdk-show-settings`} href="#">
          Manage Cookies
        </a>
        <span style={{margin: "0 5px"}}>|</span>
        <Link
          className={styles.policiesLink}
          to={ROUTES.COOKIE}
          style={{
            pointerEvents: location.pathname === ROUTES.COOKIE ? "none" : "auto",
          }}
        >
          Cookie policy
        </Link>
      </div>
      <div className={styles.textInforamtion}>
        <p>
          Copyright {format(new Date(), "yyyy")} Warner Hotels. Bourne Holidays Limited (trading
          as Warner Hotels) is a company registered in England and Wales
          with company number 01854900 whose registered office is 1 Park Lane,
          Hemel Hempstead, Hertfordshire HP2 4YL.
        </p>
      </div>
    </div>
  );
};

export default MainFooter;
