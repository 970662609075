const goToPage = (url) => {
  const aTag = document.createElement("a");
  aTag.setAttribute("href", url);
  aTag.setAttribute("target", "blank");
  aTag.click();
  document.body.appendChild(aTag);
  document.body.removeChild(aTag);
};

const goToTheTop = () => {
  window.scrollTo(0, 0);
};

export {
  goToPage,
  goToTheTop
};