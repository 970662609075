import { POUND_SYMBOL } from "../constants/constants";

/**
	formatPrice
	
	@brief Adds the currency symbol and formats the price 
	@params
		* price - String number or number
	@return String containing the the currency symbol
**/
const formatPrice = (price) => {
  return `${POUND_SYMBOL}${Number.parseFloat(price).toFixed(2)}`;
};

export {
  formatPrice
};