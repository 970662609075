import React from "react";
import Thumbnail from "./components/Thumbnail";
import ThumbnailDescriptiveBox from "./components/ThumbnailDescriptiveBox";
import { DayUseServiceIcons } from "../../../../../assets/SVGIcons";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES } from "../../../../../constants/constants";

const DayUseDesktopNav = ({
  selections,
  isCollapsed,
  setIsCollapsed,
  dayUseSelectedHotel,
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "45px",
        marginTop: "60px",
        padding: "16px",
      }}
    >
      <div
        style={{
          display: "flex",
          position: "relative",
          justifyContent: isCollapsed ? "center" : "start",
        }}
        onClick={() => {
          history.push(ROUTES.HOTEL_LOCATIONS);
        }}
      >
        <Thumbnail
          size={{
            width: "104px",
            height: "104px",
          }}
          icon={DayUseServiceIcons.TrailSign(48, "var(--ion-color-secondary)")}
          active
          background="var(--ion-color-primary)"
          thumbnailNewStyles={{ zIndex: 2 }}
        />
        {!isCollapsed && (
          <ThumbnailDescriptiveBox
            title={"Locations"}
            desciption={"Learn about our locations"}
            isActive={location.pathname.includes(ROUTES.HOTEL_LOCATIONS)}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          position: "relative",
          justifyContent: isCollapsed ? "center" : "start",
        }}
        onClick={() => history.push(`${ROUTES.SEARCH_EXPERIENCE}/${dayUseSelectedHotel?.HotelId}/${dayUseSelectedHotel?.Title}`)}
      >
        <Thumbnail
          size={{
            width: "104px",
            height: "104px",
          }}
          icon={DayUseServiceIcons.Recipe(48, "var(--ion-color-secondary)")}
          active
          background="var(--ion-color-primary)"
          thumbnailNewStyles={{ zIndex: 2 }}
        />
        {!isCollapsed && (
          <ThumbnailDescriptiveBox
            title={"Experiences"}
            desciption={"View available experiences"}
            isActive={location.pathname.includes(ROUTES.SEARCH_EXPERIENCE)}
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          position: "relative",
          justifyContent: isCollapsed ? "center" : "start",
        }}
        onClick={() => history.push(ROUTES.BASKET)}
      >
        <Thumbnail
          size={{
            width: "104px",
            height: "104px",
            position: "relative",
          }}
          icon={DayUseServiceIcons.Basket(48, "var(--ion-color-secondary)")}
          active
          enableBlueprint={selections.length > 0}
          blueprintContent={selections.length}
          background="var(--ion-color-primary)"
          thumbnailNewStyles={{ zIndex: 2 }}
        />
        {!isCollapsed && (
          <ThumbnailDescriptiveBox
            title={"Basket"}
            desciption={"Confirm your reservations"}
            isActive={
              location.pathname.includes(ROUTES.BASKET) ||
              location.pathname.includes(ROUTES.RESERVATION)
            }
          />
        )}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          height: "85px",
        }}
      >
        <div
          style={{ display: "flex", alignItems: "center", gap: "5px", cursor: "pointer" }}
          onClick={() => setIsCollapsed(!isCollapsed)}
        >
          {!isCollapsed && (
            <img
              style={{ width: "25px", height: "25px" }}
              src={"/img/day_use_icons/leftArrow.svg"}
              alt="no img"
            />
          )}
          <p
            style={{
              fontWeigth: "bold",
              textDecoration: "underline",
              fontSize: "18px",
              color: "var(--ion-color-primary)",
            }}
          >
            {!isCollapsed ? "Collapse" : "Expand"}
          </p>
          {isCollapsed && (
            <img
              style={{ width: "25px", height: "25px" }}
              src={"/img/day_use_icons/rightArrow.svg"}
              alt="no img"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DayUseDesktopNav;

