import { useState } from "react";

const COUNTRIES = [
  { Title: "Bedfordshire", id: "Bedfordshire" },
  { Title: "Buckinghamshire", id: "Buckinghamshire" },
  { Title: "Cambridgeshire", id: "Cambridgeshire" },
  { Title: "Cheshire", id: "Cheshire" },
  { Title: "Cleveland", id: "Cleveland" },
  { Title: "Cornwall", id: "Cornwall" },
  { Title: "Cumbria", id: "Cumbria" },
  { Title: "Derbyshire", id: "Derbyshire" },
  { Title: "Devon", id: "Devon" },
  { Title: "Dorset", id: "Dorset" },
  { Title: "Durham", id: "Durham" },
  { Title: "East Sussex", id: "East Sussex" },
  { Title: "Essex", id: "Essex" },
  { Title: "Gloucestershire", id: "Gloucestershire" },
  { Title: "Greater London", id: "Greater London" },
  { Title: "Greater Manchester", id: "Greater Manchester" },
  { Title: "Hampshire", id: "Hampshire" },
  { Title: "Hertfordshire", id: "Hertfordshire" },
  { Title: "Kent", id: "Kent" },
  { Title: "Lancashire", id: "Lancashire" },
  { Title: "Leicestershire", id: "Leicestershire" },
  { Title: "Lincolnshire", id: "Lincolnshire" },
  { Title: "Merseyside", id: "Merseyside" },
  { Title: "Norfolk", id: "Norfolk" },
  { Title: "North Yorkshire", id: "North Yorkshire" },
  { Title: "Northamptonshire", id: "Northamptonshire" },
  { Title: "Northumberland", id: "Northumberland" },
  { Title: "Nottinghamshire", id: "Nottinghamshire" },
  { Title: "Oxfordshire", id: "Oxfordshire" },
  { Title: "Shropshire", id: "Shropshire" },
  { Title: "Somerset", id: "Somerset" },
  { Title: "South Yorkshire", id: "South Yorkshire" },
  { Title: "Staffordshire", id: "Staffordshire" },
  { Title: "Suffolk", id: "Suffolk" },
  { Title: "Surrey", id: "Surrey" },
  { Title: "Tyne and Wear", id: "Tyne and Wear" },
  { Title: "Warwickshire", id: "Warwickshire" },
  { Title: "West Berkshire", id: "West Berkshire" },
  { Title: "West Midlands", id: "West Midlands" },
  { Title: "West Sussex", id: "West Sussex" },
  { Title: "West Yorkshire", id: "West Yorkshire" },
  { Title: "Wiltshire", id: "Wiltshire" },
  { Title: "Worcestershire", id: "Worcestershire" },
  { Title: "Flintshire", id: "Flintshire" },
  { Title: "Glamorgan", id: "Glamorgan" },
  { Title: "Merionethshire", id: "Merionethshire" },
  { Title: "Monmouthshire", id: "Monmouthshire" },
  { Title: "Montgomeryshire", id: "Montgomeryshire" },
  { Title: "Pembrokeshire", id: "Pembrokeshire" },
  { Title: "Radnorshire", id: "Radnorshire" },
  { Title: "Anglesey", id: "Anglesey" },
  { Title: "Breconshire", id: "Breconshire" },
  { Title: "Caernarvonshire", id: "Caernarvonshire" },
  { Title: "Cardiganshire", id: "Cardiganshire" },
  { Title: "Carmarthenshire", id: "Carmarthenshire" },
  { Title: "Denbighshire", id: "Denbighshire" },
  { Title: "Aberdeen City", id: "Aberdeen City" },
  { Title: "Aberdeenshire", id: "Aberdeenshire" },
  { Title: "Angus", id: "Angus" },
  { Title: "Argyll and Bute", id: "Argyll and Bute" },
  { Title: "City of Edinburgh", id: "City of Edinburgh" },
  { Title: "Clackmannanshire", id: "Clackmannanshire" },
  { Title: "Dumfries and Galloway", id: "Dumfries and Galloway" },
  { Title: "Dundee City", id: "Dundee City" },
  { Title: "East Ayrshire", id: "East Ayrshire" },
  { Title: "East Dunbartonshire", id: "East Dunbartonshire" },
  { Title: "East Lothian", id: "East Lothian" },
  { Title: "East Renfrewshire", id: "East Renfrewshire" },
  { Title: "Eilean Siar", id: "Eilean Siar" },
  { Title: "Falkirk", id: "Falkirk" },
  { Title: "Fife", id: "Fife" },
  { Title: "Glasgow City", id: "Glasgow City" },
  { Title: "Highland", id: "Highland" },
  { Title: "Inverclyde", id: "Inverclyde" },
  { Title: "Midlothian", id: "Midlothian" },
  { Title: "Moray", id: "Moray" },
  { Title: "North Ayrshire", id: "North Ayrshire" },
  { Title: "North Lanarkshire", id: "North Lanarkshire" },
  { Title: "Orkney Islands", id: "Orkney Islands" },
  { Title: "Perth and Kinross", id: "Perth and Kinross" },
  { Title: "Renfrewshire", id: "Renfrewshire" },
  { Title: "Scottish Borders", id: "Scottish Borders" },
  { Title: "Shetland Islands", id: "Shetland Islands" },
  { Title: "South Ayrshire", id: "South Ayrshire" },
  { Title: "South Lanarkshire", id: "South Lanarkshire" },
  { Title: "Stirling", id: "Stirling" },
  { Title: "West Dunbartonshire", id: "West Dunbartonshire" },
  { Title: "West Lothian", id: "West Lothian" },
  { Title: "Antrim", id: "Antrim" },
  { Title: "Armagh", id: "Armagh" },
  { Title: "Down", id: "Down" },
  { Title: "Fermanagh", id: "Fermanagh" },
  { Title: "Derry and Londonderry", id: "Derry and Londonderry" },
  { Title: "Tyrone", id: "Tyrone" },
];

const RegistrationViewLogic = (
  registrationFormData,
  setRegistrationFormData,
) => {
  const [autoGenerateFormFields, setAutoGenerateFormFields] = useState([
    {
      label: "First Name *",
      key: "firstName",
      placeholder: "John",
      isMandatory: true,
      dataType: "text",
      hasError: false,
      desktopRowPos: 1,
      desktopWidth: "48%",
    },
    {
      label: "Last Name *",
      key: "lastName",
      placeholder: "Smith",
      isMandatory: true,
      dataType: "text",
      hasError: false,
      desktopRowPos: 1,
      desktopWidth: "48%",
    },
    {
      label: "E-mail *",
      key: "email",
      placeholder: "johnsmith@email.com",
      isMandatory: true,
      dataType: "email",
      hasError: false,
      desktopRowPos: 2,
      desktopWidth: "56%",
    },
    {
      label: "Phone Number *",
      key: "phone",
      placeholder: "04938724085",
      isMandatory: true,
      dataType: "number",
      hasError: false,
      desktopRowPos: 2,
      desktopWidth: "40%",
    },
  ]);
  
  const [errMsg, setErrMsg] = useState(null);

  const manageForm = (key, value) => {
    const updatedForm = JSON.parse(JSON.stringify(registrationFormData));
    updatedForm[key] = value;
    setRegistrationFormData(updatedForm);
  };

  const validateForm = () => {
    const autoGenerateFormFieldsCopy = JSON.parse(
      JSON.stringify(autoGenerateFormFields)
    );

    let totalIssues = 0;
    autoGenerateFormFieldsCopy.forEach((row, index) => {
      if (row.isMandatory) {
        if (registrationFormData[row.key].trim() === "") {
          autoGenerateFormFieldsCopy[index].hasError = true;
          setErrMsg(
            "Please make sure all required fields marked with * are filled."
          );
          totalIssues++;
        } else {
          autoGenerateFormFieldsCopy[index].hasError = false;
        }
      }
    });

    const PHONE_INDEX = autoGenerateFormFieldsCopy?.findIndex((val) => val.key === "phone");

    const isEmailInValid =
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        registrationFormData.email
      );

    if (registrationFormData.phone.length !== 11) {
      autoGenerateFormFieldsCopy[PHONE_INDEX].hasError = true;
      if (
        registrationFormData.phone !== "" &&
        !isEmailInValid &&
        totalIssues === 0
      ) {
        totalIssues++;
        setErrMsg(
          "The phone number provided is not valid. Please provide an 11-digit valid phone number."
        );
      }
    }

    const EMAIL_INDEX = autoGenerateFormFieldsCopy?.findIndex((val) => val.key === "email");

    if (isEmailInValid) {
      autoGenerateFormFieldsCopy[EMAIL_INDEX].hasError = true;
      if (registrationFormData.email !== "" && totalIssues === 0) {
        totalIssues++;
        setErrMsg(
          "The email address provided is not valid. Please provide a valid email address."
        );
      }
    }

    if (totalIssues === 0) {
      setErrMsg(null);
    }

    setAutoGenerateFormFields(autoGenerateFormFieldsCopy);

    return totalIssues;
  };

  return {
    COUNTRIES,
    errMsg,
    autoGenerateFormFields,
    manageForm,
    validateForm,
    setErrMsg,
  };
};

export default RegistrationViewLogic;
