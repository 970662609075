import React from "react";

import styles from "../BLMarkdown.module.scss";

const MarkdownCode = ({ children }) => {

  return (
    <code
      className={styles.MarkdownCode}
    >
      {children}
    </code>
  );

};

export default MarkdownCode;