import React from "react";
import GrayOutBackground from "../GrayOutBackground/GrayOutBackground";

import styles from "../../css/info_box.module.scss";

const AlertBox = ({ txt, button, boxClassName, boxNewStyles, customContent }) => {
  return (
    <GrayOutBackground>
      <div className={`${styles.alertBox} ${boxClassName}`} style={{ ...boxNewStyles }}>
        {customContent ? customContent : <p>{txt}</p>}

        <div className={styles.alertBoxButtons}>{button}</div>
      </div>
    </GrayOutBackground>
  );
};

export default AlertBox;
