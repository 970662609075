import React, { useContext, useEffect, useMemo, useState } from "react";
import Input from "../../../../components/Input/Input";
import Heading from "../../../../shared/Heading/Heading";
import DayUseConfirmationScreenCard from "../SectionComponents/DayUseConfirmationScreenCard";
import Button from "../../../../shared/Button/Button";
import LoadingContainer from "../../../../shared/LoadingContainer/LoadingContainer";
import InformationalBox from "../../../../shared/Containers/InformationalBox";
import AlertBox from "../../../../components/AlertBox/AlertBox";
import BookedDayUseViewLogic from "./Logic/BookedDayUseViewLogic";
import SingleSelect from "../../../../components/SingleSelect/SingleSelect";
import styles from "./styles/BookedDayUseView.module.scss";
import { DayUseContext } from "../../../../context/DayUseContext";
import { useParams, useHistory } from "react-router-dom";
import { ROUTES } from "../../../../constants/constants";

const BookedDayUseView = () => {
  const [isDeepLink, setIsDeepLink] = useState(false);
  const [bookedDayUseForm, setBookedDayUseForm] = useState({
    lastName: { data: "", hasError: false },
    bookingId: { data: "", hasError: false },
    hotelId: { data: "", hasError: false },
  });
  const {
    dayUseHotelData,
    initiatedCancellation,
    isCancelled,
    setIsCancelled,
    dayUseOccasions,
  } = useContext(DayUseContext);

  const history = useHistory();
  const { hotelId, bookingId } = useParams();

  const {
    isLoading,
    bookedDayUse,
    showDialog,
    isValidForm,
    manageForm,
    handleForm,
    setShowDialog,
    validateForm,
  } = BookedDayUseViewLogic(
    isCancelled,
    setIsCancelled,
    bookedDayUseForm,
    setBookedDayUseForm
  );

  const filteredAndSortedHotels = useMemo(() => {
    return dayUseHotelData
      ?.filter((hotel) => hotel.AllowDayUseAppearance)
      .sort((a, b) => a.Title.localeCompare(b.Title));
  }, [dayUseHotelData]);

  useEffect(() => {
    if (dayUseHotelData && hotelId && bookingId) {
      const hotelInfo = dayUseHotelData.find(hotel => hotel.HotelId === hotelId);

      if (hotelInfo) {
        setBookedDayUseForm({
          lastName: { data: "", hasError: false },
          bookingId: { data: bookingId, hasError: false },
          hotelId: { data: hotelInfo.id, hasError: false },
        });
        setIsDeepLink(true);
      } else {
        history.push(ROUTES.MY_BOOKING);
      }
    }
  }, [dayUseHotelData]);

  useEffect(() => {
    if (isDeepLink) {
      handleForm();
      setIsDeepLink(false);
    }
  }, [isDeepLink]);

  return (
    <>
      <Heading
        txt={"My Booking"}
        new_styles={{
          paddingBottom: "24px",
          textAlign: "center",
          fontFamily: "Georgia",
          fontSize: "26px",
          textTransform: "uppercase",
          color: "var(--ion-color-primary)"
        }}
      />

      <>
        {dayUseHotelData ? (
          <>
            <div className={styles.day_use_booking_view_wrapper}>
              <Input
                txt={"Hotel"}
                isRedBorder={bookedDayUseForm.hotelId.hasError}
              >
                <SingleSelect
                  data={filteredAndSortedHotels}
                  placeholder={"Select your location"}
                  onChange={(event) => manageForm("hotelId", event.target.value)}
                  value={bookedDayUseForm.hotelId.data}
                  customHeight={64}
                  error={bookedDayUseForm.hotelId.hasError}
                />
              </Input>

              <Input
                txt={"Booking ID"}
                newStylesWrapper={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
                isRedBorder={bookedDayUseForm.bookingId.hasError}
              >
                <input
                  type={"text"}
                  placeholder={"351478"}
                  style={{
                    border: bookedDayUseForm.bookingId.hasError
                      ? "1px solid var(--ion-color-danger)"
                      : "",
                  }}
                  value={bookedDayUseForm.bookingId.data}
                  onChange={(e) => manageForm("bookingId", e.target.value)}
                />
              </Input>
              <Input
                txt={"Last Name"}
                newStylesWrapper={{
                  paddingTop: "15px",
                  paddingBottom: "15px",
                }}
                isRedBorder={bookedDayUseForm.lastName.hasError}
              >
                <input
                  type={"text"}
                  placeholder={"Smith"}
                  style={{
                    border: bookedDayUseForm.lastName.hasError
                      ? "1px solid var(--ion-color-danger)"
                      : "",
                  }}
                  value={bookedDayUseForm.lastName.data}
                  onChange={(e) => manageForm("lastName", e.target.value)}
                />
              </Input>
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px"
              }}
            >
              <Button
                txt={"Search"}
                onClick={() => {
                  const isValid = validateForm();
                  if (isValid) {
                    const hotelInfo = dayUseHotelData.find(hotel => hotel.id === bookedDayUseForm.hotelId.data);
                    history.push(`${ROUTES.MY_BOOKING}/${hotelInfo.HotelId}/${bookedDayUseForm.bookingId.data}`);
                    setIsDeepLink(true);
                  }
                }}
              />
            </div>

            {isLoading ? (
              <LoadingContainer />
            ) : bookedDayUse && Object.keys(bookedDayUse).length > 0 ? (
              <>
                <div className={styles.day_use_booking_view_wrapper}>
                  <DayUseConfirmationScreenCard
                    row={bookedDayUse}
                    index={1}
                    source={"bookedDayUseView"}
                    setShowDialog={setShowDialog}
                    setIsCancelled={setIsCancelled}
                    dayUseOccasions={dayUseOccasions}
                  />
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    paddingBottom: "20px",
                    marginTop: "20px"
                  }}
                >
                  <Button
                    txt={"Cancel My Booking"}
                    onClick={() => {
                      setShowDialog(true);
                      setIsCancelled(null);
                    }}
                  />
                </div>
              </>
            ) : !bookedDayUse ? (
              <InformationalBox
                content={`We could not match a booking for the provided details. Please try again.`}
                boxNewStyles={{
                  width: "95%",
                  justifyContent: "center",
                  left: "0",
                  right: "0",
                  margin: "auto auto 20px auto",
                }}
                contentNewStyles={{ textAlign: "center" }}
                showIcon
              />
            ) : null}
          </>
        ) : (
          <LoadingContainer />
        )}

        {!isCancelled ? null : isCancelled === "Started" ? (
          <LoadingContainer />
        ) : isCancelled === "success" ? (
          <InformationalBox
            content={`Your booking with booking number ${bookedDayUseForm.bookingId.data} has been cancelled successfully.`}
            boxNewStyles={{
              width: "95%",
              justifyContent: "center",
              left: "0",
              right: "0",
              margin: "auto auto 20px auto",
            }}
            contentNewStyles={{ textAlign: "center" }}
            showIcon
          />
        ) : (
          <div
            className={styles.day_use_booking_view_wrapper}
            style={{
              background: "var(--ion-color-danger)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              className={styles.day_use_icon}
              src={`/img/info_icons/exclamation.svg`}
              alt="no img"
            />
            <p style={{ color: "white", paddingLeft: "15px" }}>
              Failed to cancel the selected booking. Please try again or contact
              the hotel directly.
            </p>
          </div>
        )}

        {showDialog && !isCancelled ? (
          <AlertBox
            txt={"Are you sure you want to cancel your booking?"}
            button={
              <>
                <Button
                  txt={"No"}
                  onClick={() => setShowDialog(false)}
                  type="primary-inverse"
                />
                <Button
                  txt={"Yes"}
                  onClick={() => initiatedCancellation(bookedDayUse, setShowDialog)}
                />
              </>
            }
          />
        ) : null}

        {!isValidForm ? (
          <div
            className={styles.day_use_booking_view_wrapper}
            style={{
              background: "var(--ion-color-danger)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px"
            }}
          >
            <img
              className={styles.day_use_icon}
              src={`/img/info_icons/exclamation.svg`}
              alt="no img"
            />
            <p style={{ color: "white", paddingLeft: "15px" }}>
              Please make sure to fill all fields.
            </p>
          </div>
        ) : null}
      </>
    </>
  );
};

export default BookedDayUseView;
