import React from "react";
import { Box } from "@mui/material";

import styles from "./DayUseMobileNav.module.scss";
import { DayUseServiceIcons } from "../../../../../assets/SVGIcons";
import { ROUTES } from "../../../../../constants/constants";


const DayUseMobileNav = ({ history, selections, dayUseSelectedHotel }) => {
  return (
    <div className={styles.day_use_footer_wrapper}>
      <div
        className={styles.day_use_avail_section_icon}
        onClick={() => history.push(ROUTES.HOME)}
      >
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          {DayUseServiceIcons.HomeMobile({
            width: 22,
            height: 22
          }, "var(--ion-color-secondary)")}
          <Box marginTop='4px' fontSize='15px' color='var(--ion-color-secondary)'>Home</Box>
        </Box>
      </div>
      <div
        className={styles.day_use_avail_section_icon}
        onClick={() => {
          history.push(ROUTES.HOTEL_LOCATIONS);
        }}
      >
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          {DayUseServiceIcons.LocationsMobile({
            width: 24,
            height: 22
          }, "var(--ion-color-secondary)")}
          <Box marginTop='4px' fontSize='15px' color='var(--ion-color-secondary)'>Locations</Box>
        </Box>
      </div>
      <div
        className={styles.day_use_avail_section_icon}
        onClick={() => history.push(`${ROUTES.SEARCH_EXPERIENCE}/${dayUseSelectedHotel?.HotelId}/${dayUseSelectedHotel?.Title}`)}
      >
        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          {DayUseServiceIcons.ExperiencesMobile({
            width: 22,
            height: 22
          }, "var(--ion-color-secondary)")}
          <Box marginTop='4px' fontSize='15px' color='var(--ion-color-secondary)'>Experiences</Box>
        </Box>
      </div>
      <div style={{ display: "flex", position: "relative" }}>
        <div
          className={styles.day_use_avail_section_icon}
          onClick={() => history.push(ROUTES.BASKET)}
        >
          <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
            {DayUseServiceIcons.BasketMobile({
              width: 28,
              height: 22
            }, "var(--ion-color-secondary)")}
            <Box marginTop='4px' fontSize='15px' color='var(--ion-color-secondary)'>Basket</Box>
          </Box>
        </div>
        {selections.length > 0 && (
          <span
            style={{
              color: "var(--ion-color-secondary)",
              fontWeight: "bold",
              marginLeft: "5px",
              position: "absolute",
              top: "5px",
              right: "0",
            }}
          >
            {selections.length}
          </span>
        )}
      </div>
    </div>
  );
};

export default DayUseMobileNav;

