import React, { createContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axiosInstance from "../url/createAxios";
import format from "date-fns/format";
import { ROUTES } from "../constants/constants";

export const DayUseContext = createContext({});

const DayUseProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const instance = axiosInstance();

  const [loginPageData, setLoginPageData] = useState({});
  const [dayUseDetails, setDayUseDetails] = useState(null);
  const [dayUseHotelData, setDayUseHotelData] = useState(null);
  const [dayUseActivitiesData, setDayUseActivitiesData] = useState(null);
  const [dayUseHotelDetailsLocation, setDayUseHotelDetailsLocation] =
    useState(null);
  const [dayUseSelectedHotel, setDayUseSelectedHotel] = useState(null);
  const [dayUseSelectedHotelActivities, setDayUseSelectedHotelActivities] =
    useState(null);
  const [dayUseSelectedActivityDetails, setDayUseSelectedActivityDetails] =
    useState(null);
  const [isLoadingDayUseActivities, setIsLoadingDayUseActivities] =
    useState(false);
  const [isLoadingDayUseAvailability, setIsLoadingDayUseAvailability] =
    useState(false);
  const [isLoadingLoginPage, setIsLoadingLoginPage] = useState(false);
  const [isProcessingDayUseBookings, setIsProcessingDayUseBookings] =
    useState(false);
  const [dayUseCCDetails, setDayUseCCDetails] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const [dayUseAvailability, setDayUseAvailability] = useState(null);
  const [processedRecordsResults, setProcessedRecordsResults] = useState([]);
  const [isCancelled, setIsCancelled] = useState(null);
  const [registrationFormData, setRegistrationFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    occasion: "",
  });

  const [dayUseOccasions, setDayUseOccasions] = useState([]);
  const [dayUseSelections, setDayUseSelections] = useState([]);
  const [showCardDialog, setShowCardDialog] = useState(false);
  const [dayUseTexts, setDayUseTexts] = useState(null);

  const updateHotelSelection = (value) => {
    setDayUseAvailability(null);
    const selHotel = dayUseHotelData.find((row) => row.id === Number(value));
    const prepDayUseDetailsObj = {
      hotelId: value,
      count: 1,
      hotelTitle: selHotel.Title,
      maestroHotelId: selHotel.HotelId,
    };
    setDayUseSelectedHotel(selHotel);

    if (dayUseActivitiesData) {
      const selectedHotelActivitiesDetails = dayUseActivitiesData.filter(
        (row) => row.hotelId === value
      );

      setDayUseSelectedHotelActivities(selectedHotelActivitiesDetails);
      setDayUseSelectedActivityDetails(selectedHotelActivitiesDetails[0]);
      prepDayUseDetailsObj.activityId = selectedHotelActivitiesDetails[0]?.id;
    } else {
      setDayUseSelectedActivityDetails(null);
    }
    setDayUseDetails(prepDayUseDetailsObj);
    if (location.pathname.includes(ROUTES.SEARCH_EXPERIENCE)) {
      history.push(`${ROUTES.SEARCH_EXPERIENCE}/${selHotel.HotelId}/${selHotel.Title}`);
    }
  };

  const updateDetailsSelection = (key, value) => {
    setDayUseAvailability(null);
    if (key === "activityId") {
      setDayUseSelectedActivityDetails(
        dayUseSelectedHotelActivities.find((row) => row.id === value)
      );
    }

    setDayUseDetails((prevState) => {
      if (value) {
        return { ...prevState, [key]: value };
      } else {
        const updatedObj = prevState;
        delete updatedObj[key];
        return { ...updatedObj };
      }
    });
  };

  const updateGuestCount = (type) => {
    let count = dayUseDetails.count;
    if (type === "minus") {
      count = count - 1;
    } else {
      count = count + 1;
    }
    if (count < 1 || count > 10) return;

    if (dayUseAvailability) {
      const currentSelectedDate =
        typeof dayUseDetails?.date === "string"
          ? dayUseDetails.date
          : format(dayUseDetails.date, "yyyy-MM-dd");

      const newSelectionArray = dayUseSelections.filter(
        (sel) => sel.date !== currentSelectedDate
      );

      dayUseSelections.forEach((selection) => {
        if (selection.date === currentSelectedDate) {
          const selectedTimeAvailObj = dayUseAvailability.find(
            (row) => row.FacilityDate === selection.FacilityDate
          );
          if (count <= Number(selectedTimeAvailObj.FacilityQuantity)) {
            selection.quantity = count;
            newSelectionArray.push(selection);
          }
        }
      });

      setDayUseSelections(newSelectionArray);
    }

    setDayUseDetails((prevState) => {
      prevState.count = count;
      return { ...prevState };
    });
  };

  const handleShowMoreLess = () => {
    setShowMore(!showMore);
  };

  const handleSelectionAction = (availObj) => {
    const dateTime = availObj.FacilityDate.split("T");

    const isSelectedIndex = dayUseSelections.findIndex(
      (row) =>
        row.activityId === dayUseDetails.activityId &&
        row.FacilityDate === availObj.FacilityDate
    );

    if (isSelectedIndex !== -1) {
      const updatedSelections = dayUseSelections.filter(
        (row, selIndex) => selIndex !== isSelectedIndex
      );
      setDayUseSelections(updatedSelections);
      sessionStorage.setItem("basket", JSON.stringify(updatedSelections));
    } else {
      const newSelection = {
        date: dateTime[0],
        time: dateTime[1],
        FacilityDate: availObj.FacilityDate,
        hotelId: dayUseDetails.hotelId,
        activityId: dayUseDetails.activityId,
        FacilityCode: availObj.FacilityCode,
        FacilityTypeCode: dayUseSelectedActivityDetails.FacilityTypeCode,
        quantity: dayUseDetails.count,
        activityTitle: dayUseSelectedActivityDetails.Title,
        maestroHotelId: dayUseSelectedActivityDetails.maestroHotelId,
        hotelTitle: dayUseSelectedActivityDetails.hotelTitle,
        activityPricePP: dayUseSelectedActivityDetails.Price,
        activityImages: dayUseSelectedActivityDetails.Images,
        BuildingCode: dayUseSelectedActivityDetails.BuildingCode,
        FacilityTypeDescription:
          dayUseSelectedActivityDetails.FacilityTypeDescription,
      };

      setDayUseSelections((prevState) => {
        return [...prevState, newSelection];
      });
      sessionStorage.setItem(
        "basket",
        JSON.stringify([...dayUseSelections, newSelection])
      );
    }
  };

  const refreshSearchParams = (isFromConfScreen) => {
    setDayUseDetails((prevState) => {
      return {
        hotelId: prevState?.hotelId,
        hotelTitle: prevState?.hotelTitle,
        count: 1,
      };
    });
    if (isFromConfScreen) {
      setProcessedRecordsResults([]);
      setDayUseSelections([]);
    }
    setDayUseSelectedActivityDetails(null);
    setDayUseAvailability(null);
    history.push(`${ROUTES.SEARCH_EXPERIENCE}/${dayUseSelectedHotel?.HotelId}/${dayUseSelectedHotel?.Title}`);
  };

  const handleEditAction = async (index) => {
    const sel = dayUseSelections[index];
    const newSelectedActivityDetails = dayUseActivitiesData.find(
      (row) => row.id === sel.activityId
    );
    setDayUseSelectedHotel(
      dayUseHotelData.find((row) => row.id === sel.hotelId)
    );
    setDayUseSelectedHotelActivities(
      dayUseActivitiesData.filter((row) => row.hotelId === sel.hotelId)
    );
    setDayUseSelectedActivityDetails(newSelectedActivityDetails);
    const dayUseDetailsObj = {
      hotelId: sel.hotelId,
      activityId: sel.activityId,
      count: sel.quantity,
      date: sel.date,
      hotelTitle: sel.hotelTitle,
    };
    setDayUseDetails(dayUseDetailsObj);
    setDayUseAvailability(null);
    history.push(`${ROUTES.SEARCH_EXPERIENCE}/${dayUseSelectedHotel?.HotelId}/${dayUseSelectedHotel?.Title}`);
    await getDayUseAvailability(
      newSelectedActivityDetails.FacilityCode,
      sel.date,
      sel.hotelId
    );
  };

  const initiateBookingProcedure = async () => {
    setProcessedRecordsResults([]);
    setIsProcessingDayUseBookings(true);
    const preparedBookings = {};
    let data;
    try {
      dayUseSelections.forEach((row) => {
        if (!preparedBookings.hasOwnProperty(row.hotelId)) {
          preparedBookings[row.hotelId] = [];
        }

        preparedBookings[row.hotelId].push({
          FacilityCode: row.FacilityCode,
          FacilityTypeCode: row.FacilityTypeCode,
          FacilityDate: row.FacilityDate,
          FacilityQuantity: row.quantity,
          BuildingCode: row.BuildingCode,
          FacilityTypeDescription: row.FacilityTypeDescription,
          activityTitle: row.activityTitle,
          activityId: row.activityId,
          FirstName: registrationFormData.firstName,
          LastName: registrationFormData.lastName,
          ParticipantFirstName: registrationFormData.firstName,
          ParticipantLastName: registrationFormData.lastName,
          Phone: registrationFormData.phone,
          EmailAddress: registrationFormData.email,
          occasion: dayUseOccasions.find(
            (occ) => occ.id === registrationFormData.occasion
          )
            ? dayUseOccasions.find(
              (occ) => occ.id === registrationFormData.occasion
            ).Title
            : "",
          price: row.activityPricePP,
        });
      });

      data = await instance.post(
        `/Hotel/Booking/Facility/PublicAvailabilityIonic`,
        {
          preparedBookings,
          action: "book",
        }
      );

      setProcessedRecordsResults(data.data);
      if (!data?.data?.hasOwnProperty("error")) {
        revertDayUseForm();
        history.push(ROUTES.RESERVATION_SUMMARY);
        setDayUseSelections([]);
        sessionStorage.removeItem("basket");
      }
      setIsProcessingDayUseBookings(false);
    } catch (e) {
      console.error(
        `Failed to process records ${JSON.stringify(
          preparedBookings
        )} Error: ${JSON.stringify(e.message)}`
      );

      if (e?.response?.data.hasOwnProperty("error"))
        setProcessedRecordsResults(e.response.data);
      setIsProcessingDayUseBookings(false);
    }
  };

  const initiatedCancellation = async (reqData, setShowDialog) => {
    setShowDialog(false);
    setIsCancelled("Started");
    const preparedBookings = {
      BookingNumber: reqData.bookingId,
      LastName: reqData.lastName,
    };

    try {
      const { data } = await instance.post(
        `/Hotel/Booking/Facility/PublicAvailability`,
        {
          preparedBookings,
          action: "cancel",
          hotelId: reqData.hotelId,
          recordId: reqData.id,
        }
      );
      setIsCancelled(data);
    } catch (e) {
      setShowDialog(false);
      setIsCancelled("failure");
      console.error(e.message);
    }
  };

  const showHotelDetails = (hotelDetails) => {
    history.push(`${ROUTES.HOTEL_LOCATIONS}/${hotelDetails.HotelId}/${hotelDetails.Title}`);
    setDayUseHotelDetailsLocation(hotelDetails);
  };

  useEffect(() => {
    getDayUseTexts();
  },[]);

  useEffect(() => {
    getDayUseHotelData();
    getDayUseOccasions();

    const basket = sessionStorage.getItem("basket");
    if (basket) setDayUseSelections(JSON.parse(basket));
  }, []);

  useEffect(() => {
    if (dayUseDetails && dayUseDetails.hotelId) {
      if (!dayUseActivitiesData) {
        getDayUseActivities();
      } else {
        const selectedHotelActivitiesDetails = dayUseActivitiesData.filter(
          (row) => row?.hotelId === dayUseDetails?.hotelId
        );

        setDayUseSelectedHotelActivities(selectedHotelActivitiesDetails);
      }
    }
  }, [dayUseDetails]);

  useEffect(() => {
    if (processedRecordsResults.hasOwnProperty("error")) {
      setProcessedRecordsResults([]);
    }
  }, [location.pathname]);


  const getDayUseTexts = async () => {
    try {
      const { data } = await instance.get("/day-use-texts");
      setDayUseTexts(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDayUseHotelData = async () => {
    try {
      setIsLoadingLoginPage(true);
      let { data } = await instance.get("/hotel-data/day-use", {
        withCredentials: true,
      });

      const loginPageData = await instance.get("/login-page/initial?isDayUse=true");
      setDayUseDataForFirstHotel(data);
      setDayUseHotelData(data);
      setLoginPageData(loginPageData.data);
      setIsLoadingLoginPage(false);
    } catch (error) {
      console.error(error);
      setIsLoadingLoginPage(false);
    }
  };

  const setDayUseDataForFirstHotel = (data) => {
    setDayUseSelectedHotel(data[0]);
    setDayUseDetails({
      count: 1,
      hotelId: data[0].id,
      hotelTitle: data[0].Title,
      maestroHotelId: data[0].HotelId,
    });
  };

  const handleUrlRedirect = (data, hotelParam) => {
    const foundHotel = data.find(
      (row) => row.HotelId === hotelParam && row.AllowDayUseAppearance
    );
    if (foundHotel) {
      setDayUseSelectedHotel(foundHotel);
      const selectedHotelActivitiesDetails = dayUseActivitiesData.filter(
        (row) => row?.hotelId === foundHotel.id
      );
      setDayUseSelectedHotelActivities(selectedHotelActivitiesDetails);
      setDayUseDetails({
        count: 1,
        hotelId: foundHotel.id,
        hotelTitle: foundHotel.Title,
        maestroHotelId: foundHotel.HotelId,
      });
    } else {
      history.push(ROUTES.HOME);
    }
  };

  const getDayUseOccasions = async () => {
    try {
      let { data } = await instance.get("/day-use-occasions", {
        withCredentials: true,
      });

      data = data.sort((a, b) =>
        a.Title.toLowerCase().localeCompare(b.Title.toLowerCase())
      );

      setDayUseOccasions(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDayUseActivities = async () => {
    setIsLoadingDayUseActivities(true);
    try {
      let { data } = await instance.get("/facilities-data/day-use", {
        withCredentials: true,
      });

      setDayUseActivitiesData(data);
      setIsLoadingDayUseActivities(false);
    } catch (error) {
      setIsLoadingDayUseActivities(false);
      console.error(error);
    }
  };

  const getDayUseAvailability = async (facilityCode, date, hotelId) => {
    setIsLoadingDayUseAvailability(true);

    try {
      const { data } = await instance.post(
        `/Hotel/Booking/Facility/Availability/Day-use`,
        {
          params: {
            hotelId: hotelId ? hotelId : dayUseDetails.hotelId,
            date: date
              ? date
              : typeof dayUseDetails?.date === "string"
                ? dayUseDetails.date
                : format(dayUseDetails.date, "yyyy-MM-dd"),
            facilityCode: facilityCode
              ? facilityCode
              : dayUseSelectedActivityDetails.FacilityCode,
          },
        }
      );

      setDayUseAvailability(data);
      setIsLoadingDayUseAvailability(false);
    } catch (e) {
      console.error(
        `Failed to fetch day use availability for params: ${JSON.stringify(
          dayUseDetails
        )}. Error: ${e}`
      );
      setIsLoadingDayUseAvailability(false);
    }
  };

  const revertDayUseForm = () => {
    setDayUseCCDetails(null);
    // setProcessedRecordsResults([]);
    setRegistrationFormData({
      firstName: "",
      lastName: "",
      addressOne: "",
      addressTwo: "",
      city: "",
      county: "",
      zipCode: "",
      phone: "",
      email: "",
      occasion: "",
    });
  };

  return (
    <DayUseContext.Provider
      value={{
        dayUseTexts,
        setDayUseTexts,
        getDayUseTexts,
        dayUseDetails,
        dayUseHotelData,
        isLoadingDayUseActivities,
        dayUseSelectedHotel,
        dayUseSelectedHotelActivities,
        dayUseSelectedActivityDetails,
        isLoadingDayUseAvailability,
        dayUseAvailability,
        history,
        dayUseSelections,
        registrationFormData,
        dayUseOccasions,
        isProcessingDayUseBookings,
        processedRecordsResults,
        isCancelled,
        showMore,
        dayUseHotelDetailsLocation,
        dayUseCCDetails,
        setDayUseCCDetails,
        showCardDialog,
        loginPageData,
        isLoadingLoginPage,
        setShowCardDialog,
        setDayUseDetails,
        setDayUseHotelData,
        setDayUseOccasions,
        setIsProcessingDayUseBookings,
        setIsLoadingDayUseAvailability,
        setIsLoadingDayUseActivities,
        setDayUseHotelDetailsLocation,
        updateHotelSelection,
        updateDetailsSelection,
        updateGuestCount,
        handleShowMoreLess,
        setDayUseSelections,
        handleSelectionAction,
        refreshSearchParams,
        handleEditAction,
        setRegistrationFormData,
        initiateBookingProcedure,
        initiatedCancellation,
        setIsCancelled,
        showHotelDetails,
        setDayUseActivitiesData,
        dayUseActivitiesData,
        setDayUseSelectedHotel,
        getDayUseOccasions,
        getDayUseActivities,
        getDayUseAvailability,
        revertDayUseForm,
        handleUrlRedirect,
      }}
    >
      {children}
    </DayUseContext.Provider>
  );
};

export default DayUseProvider;
