import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import styles from "./SingleSelect.module.scss";
import FormControl from "@mui/material/FormControl";

const SingleSelect = ({
  manualDropdownOpen = false,
  setManualDropdownOpen,
  data,
  style,
  value,
  onChange,
  placeholder,
  customHeight,
  error = false,
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (manualDropdownOpen) {
      setOpen(manualDropdownOpen);
      setManualDropdownOpen(false);
    }
  }, [manualDropdownOpen]);

  // Check if the value is included in the list of ids
  const isValidValue = data.some((item) => item.id === value);

  // Set the value to an empty string if it's not valid
  const sanitizedValue = isValidValue ? value : "";

  return (
    <FormControl
      error={error}
      sx={{ m: 1, width: "100%", mt: 3, margin: 0, ...style }}
    >
      <Select
        fullWidth
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        style={{ height: customHeight }}
        displayEmpty
        variant="outlined"
        renderValue={(selected) => {
          if (!selected) {
            return <em style={{ color: "#adadad", fontStyle: 'normal' }}>{placeholder}</em>;
          }

          return <em style={{ color: "var(--ion-color-primary)", fontStyle: 'normal' }}>{data?.find((row) => row.id === value)?.Title}</em>;
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              boxShadow: "0 2px 4px -1px gray;",
              borderRadius: 0,
            },
          },
        }}
        sx={{
          "&.Mui-error": {
            "& .MuiOutlinedInput-notchedOutline": {
              border: "1px solid var(--ion-color-danger)"
            }
          },
          borderRadius: 0, 
        }}
        value={sanitizedValue}
        onChange={onChange}
        className={styles.SingleSelect}
        IconComponent={() => (
          <div onClick={() => setOpen(true)} style={{ cursor: 'pointer'}}>
            <img
              style={{ width: "20px", height: "20px", marginRight: 30, marginTop: 6 }}
              src={"/img/day_use_icons/downArrow.svg"}
              alt="no img"
            />
          </div>
        )}
      >
        {data?.map((item) => (
          <MenuItem key={item.id} value={item.id}>{item.Title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SingleSelect;
