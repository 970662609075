import React, { useContext, useEffect } from "react";
import Heading from "../../../../shared/Heading/Heading";
import DayUseConfirmationScreenCard from "../SectionComponents/DayUseConfirmationScreenCard";
import Button from "../../../../shared/Button/Button";
import LoadingContainer from "../../../../shared/LoadingContainer/LoadingContainer";
import { DayUseContext } from "../../../../context/DayUseContext";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import { ROUTES } from "../../../../constants/constants";

const ConfirmationScreenView = () => {
  const {
    refreshSearchParams,
    isProcessingDayUseBookings,
    processedRecordsResults,
    history,
    dayUseCCDetails,
    dayUseOccasions,
    dayUseTexts
  } = useContext(DayUseContext);

  useEffect(() => {
    if (!isProcessingDayUseBookings && !processedRecordsResults?.length) {
      history.push(ROUTES.BASKET);
    }
  }, []);

  return (
    <>
      {isProcessingDayUseBookings ? (
        <LoadingContainer />
      ) : (
        <>
          <Heading
            txt={"Confirmation"}
            new_styles={{
              paddingBottom: "24px",
              textAlign: "center",
              fontFamily: "Georgia",
              fontSize: "26px",
              textTransform: "uppercase",
              color: "var(--ion-color-primary)",
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <p style={{ color: "var(--ion-color-primary)", textAlign: "center" }}>{`Please find your ${
              processedRecordsResults.length === 1
                ? "reservation"
                : "reservations"
            } summary below`}</p>
          </div>

          {processedRecordsResults.map((row, index) => (
            <DayUseConfirmationScreenCard
              key={row.bookingId}
              row={row}
              index={index}
              source={"confScreen"}
              dayUseCCDetails={dayUseCCDetails}
              dayUseOccasions={dayUseOccasions}
            />
          ))}

          {processedRecordsResults.filter((row) => row.status !== "Failed")
            .length > 0 && (
            <div
              style={{
                display: "flex",
                paddingTop: "16px",
                paddingBottom: "16px",
              }}
            >
              <BLParagraph
                txt={dayUseTexts?.Paragraph_EmailConfirmationText}
                newStyles={{
                  font: "normal normal bold 18px/24px Arial",
                  color: "var(--ion-color-primary)",
                  textAlign: "center",
                }}
                markDown
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <Button
              txt="Close"
              type="primary-inverse"
              onClick={() => history.push(ROUTES.HOME)}
            />

            <Button
              txt={"Add another experience"}
              onClick={() => refreshSearchParams(true)}
              newStyles={{ marginLeft: "15px" }}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ConfirmationScreenView;
