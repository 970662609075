
import { IMAGE_FORMATS } from "../constants/constants";

const ascendingFormatSizes = Object.freeze([
  IMAGE_FORMATS.SMALL,
  IMAGE_FORMATS.MEDIUM,
  IMAGE_FORMATS.LARGE,
  IMAGE_FORMATS.XLARGE,
  IMAGE_FORMATS.XXLARGE,
]);

/**
	getImageFormatURL
	
	@brief Get the URL of the closest available image format 
	@params
		* image - Strapi image object
		* strapiFormat - image format
	@return String containing the URL of the closest available image format
**/

const getImageFormatURL = (image, strapiFormat) => {
  if (image?.formats && image.formats[strapiFormat]) {
    return image.formats[strapiFormat].url;
  }
  const smallerFormatIndex = ascendingFormatSizes.findIndex((item) => item === strapiFormat) - 1;
  return smallerFormatIndex >= 0
    ? getImageFormatURL(image, ascendingFormatSizes[smallerFormatIndex])
    : image.url;
};

export { getImageFormatURL };