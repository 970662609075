import { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { DayUseContext } from "../../../../context/DayUseContext";
import { ROUTES } from "../../../../constants/constants";

const LoginFormLogic = () => {
  const { setDayUseDetails, dayUseHotelData, updateHotelSelection } =
    useContext(DayUseContext);
  const [dayUseHotel, setDayUseHotel] = useState(null);
  const history = useHistory();

  const showExperiences = (hotelDetails) => {
    let selectedHotel;
    if (hotelDetails) {
      selectedHotel = hotelDetails;
    } else {
      selectedHotel = dayUseHotelData?.find((row) => row.id === dayUseHotel);
    }

    setDayUseDetails({
      hotelId: dayUseHotel,
      count: 1,
      hotelTitle: selectedHotel.Title,
      maestroHotelId: selectedHotel.HotelId,
    });

    updateHotelSelection(dayUseHotel);

    history.push(`${ROUTES.SEARCH_EXPERIENCE}/${selectedHotel?.HotelId}/${selectedHotel?.Title}`);
  };

  const showViewCancelDayUseBooking = () => {
    history.push(ROUTES.MY_BOOKING);
  };

  return {
    setDayUseHotel,
    showExperiences,
    showViewCancelDayUseBooking,
    dayUseHotel,
  };
};

export default LoginFormLogic;
