import React from "react";

import BlurryWrapper from "../../components/BlurryWrapper/BlurryWrapper";
import Heading from "../../shared/Heading/Heading";
import InformationText from "../../shared/InformationText/InformationText";

const InfoBox = ({ title, dsc, loginInfoClickHandler, isLoginInfoOpened }) => {
  const infoHighlighter = () => {
    loginInfoClickHandler(!isLoginInfoOpened);
  };

  return (
    <BlurryWrapper
      new_styles={{
        transition: "1s all ease-in-out",
        width: "600px",
        minHeight: "200px",
        position: "absolute",
        bottom: "-85px",
        marginLeft: "50vw",
        background: "var(--ion-color-secondary) 0% 0% no-repeat padding-box",
        color: "var(--ion-color-primary)",
        backdropFilter: "blur(0px)",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden",
        cursor: "pointer",
      }}
      clickFunction={infoHighlighter}
      whiteShadowStyle={{
        padding: "40px 25px 90px",
        background: `rgba(255, 255, 255, ${isLoginInfoOpened ? "0.5" : "0.2"})`,
      }}
    >
      <Heading
        txt={title}
        new_styles={{ fontSize: "28px", color: "var(--ion-color-primary)", textAlign: "center" }}
      />
      <InformationText
        markdown
        content={dsc}
        new_styles={{
          fontSize: "16px",
          textAlign: "center",
          marginTop: "35px",
        }}
      />
    </BlurryWrapper>
  );
};

export default InfoBox;
