import React, { useContext } from "react";

import LoadingContainer from "../../../shared/LoadingContainer/LoadingContainer";
import ImageCarousel from "../../../shared/ImageCarousel/ImageCarousel";
import LoginForm from "./components/LoginForm";
import WindowWidth from "../../../hooks/WindowWidth";
import { DayUseContext } from "../../../context/DayUseContext";

import styles from "./LoginPage.module.scss";
import { IMAGE_FORMATS } from "../../../constants/constants";

const LoginPage = () => {
  const { loginPageData, isLoadingLoginPage } = useContext(DayUseContext);
  const { width, height } = WindowWidth();
  if (isLoadingLoginPage) {
    return <LoadingContainer loaderWrapperStyle={{ flex: "1", height: "100%" }} loaderSize={"100px"} />;
  }

  const widthAndHeightDifference = width - height;
  const addHeight = widthAndHeightDifference > 900 ? 500 : 250;
  const minHeight = height >= 800 ? "100%" : window.innerHeight + addHeight;
  sessionStorage.removeItem("basket");

  if (width > 800) {
    return (
      <>
        <div className={styles.Login} id={"Login"}  style={{ minHeight }}>
          <LoginForm />
          <ImageCarousel
            sliderImages={loginPageData.SliderContent}
            autoSlide
            removeBorderRadius
            new_styles={{
              flex: "1",
              borderRadius: "0",
              cursor: "default",
              width: "100.05%",
            }}
            view={"Login"}
            format={IMAGE_FORMATS.XXLARGE}
          />
        </div>
      </>
    );
  } else {
    return (
      <div
        className={styles.Login}
        id={"Login"}
        style={{
          background: `url(${loginPageData?.MobileBackgroundImage?.formats?.medium?.url}) center / cover transparent`,
          minHeight,
        }}
      >
        <LoginForm />
      </div>
    );
  }
};

export default LoginPage;
