import React from "react";
import Heading from "../../../../../../shared/Heading/Heading";
import ImageCarousel from "../../../../../../shared/ImageCarousel/ImageCarousel";
import Button from "../../../../../../shared/Button/Button";
import Markdown from "markdown-to-jsx";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../constants/constants";

const LocationDetailsDesktop = ({
  hotelDetailsLocation,
  updateHotelSelection,
  setHotelDetailsLocation,
}) => {
  const history = useHistory();

  return (
    <>
      <Heading
        txt={hotelDetailsLocation?.Title}
        new_styles={{
          paddingBottom: "24px",
          textAlign: "center",
          fontFamily: "Georgia",
          fontSize: "26px",
          textTransform: "uppercase",
          color: "var(--ion-color-primary)",
        }}
      />
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <div style={{ width: "50%", height: "500px" }}>
          <ImageCarousel
            sliderImages={hotelDetailsLocation?.Images}
            customImageStyles={{ borderRadius: "0" }}
          />
        </div>
        <div
          style={{
            width: "50%",
            height: "500px",
            padding: "0px 5px 0px 15px",
            overflow: "auto",
          }}
        >
          <div style={{ paddingTop: "5px" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                style={{ width: "24px", height: "32px" }}
                src={`/img/day_use_icons/location-pin-blue.svg`}
                alt=""
              />
              <span
                style={{
                  fontSize: "16px",
                  paddingLeft: "5px",
                }}
              >
                {hotelDetailsLocation?.Address}
              </span>
            </div>

            {hotelDetailsLocation?.LongDescription && (
              <p style={{ paddingTop: "20px" }}>
                <Markdown>{hotelDetailsLocation?.LongDescription}</Markdown>
              </p>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          gap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          txt="Back"
          type="primary-inverse"
          onClick={() => {
            history.push(ROUTES.HOTEL_LOCATIONS);
            setHotelDetailsLocation(null);
          }}
        />

        <Button
          txt={"Switch to this hotel"}
          onClick={() => {
            updateHotelSelection(hotelDetailsLocation?.id);
            history.push(`${ROUTES.SEARCH_EXPERIENCE}/${hotelDetailsLocation.HotelId}/${hotelDetailsLocation.Title}`);
          }}
          opacity={!hotelDetailsLocation?.AllowDayUseAppearance ? "0.4" : 1}
          pointerEvents={!hotelDetailsLocation?.AllowDayUseAppearance && "none"}
        />
      </div>
    </>
  );
};

export default LocationDetailsDesktop;
