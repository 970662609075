import React from "react";

import RegisteredCardLogic from "./RegisteredCardLogic";

import styles from "./RegisteredCard.module.scss";
import { Dialog, DialogContent } from "@mui/material";

const RegisteredCard = ({ removeModal, showCardDialog }) => {
  const { currentPage } = RegisteredCardLogic(removeModal);

  return (
    <Dialog
      open={showCardDialog}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            minWidth: "100%",
            '@media(min-width: 750px)': {
              minWidth: "400px",
              width: "400px",
            },
          },
        },
      }}
    >
      <DialogContent
        sx={{
          padding: 0
        }}
      >
        <div className={styles.RegisteredCard}>
          {currentPage}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RegisteredCard;
