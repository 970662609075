import React from "react";

const Thumbnail = ({
  subTitle,
  size,
  background = "var(--ion-color-primary)",
  active,
  enableBlueprint,
  blueprintContent,
  icon,
  onClick,
  thumbnailNewStyles,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: subTitle ? "space-between" : "",
        cursor: "pointer",
      }}
    >
      <div
        onClick={onClick ? onClick : null}
        style={{
          ...size,
          background: background,
          borderRadius: "50%",
          opacity: !active ? "0.4" : "1",
          pointerEvents: !active ? "none" : "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...thumbnailNewStyles,
        }}
      >
        {enableBlueprint && (
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              width: "30px",
              height: "30px",
              position: "absolute",
              top: "-10px",
              right: "0",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              alignItems: "center",
              background: "var(--ion-color-primary)",
              borderRadius: "50%",
            }}
          >
            {blueprintContent}
          </span>
        )}
        {icon}
      </div>
      {subTitle && (
        <p
          style={{
            marginTop: "5px",
            color: "var(--ion-color-primary)",
            minWidth: "80px",
            textAlign: "center",
            font: "normal normal 600 16px/22px Arial"
          }}
        >
          {subTitle}
        </p>
      )}
    </div>
  );
};

export default Thumbnail;
