import { useEffect, useRef } from "react";
import WindowWidth from "../../hooks/WindowWidth";

const BlurryWrapperLogic = (isLoginForm) => {
  const reference = useRef(null);
  const { height, width } = WindowWidth();
  useEffect(() => {
    if (!isLoginForm || height > 1000 || width > 1000) return;

    const loginWrapper = document.getElementById("Login");
    const loginBlurryHeight = reference.current.offsetHeight;
    const topPosition = reference.current.offsetTop;
    const bottomSpace = 100;
    const addHeight = loginBlurryHeight + topPosition + bottomSpace - height;

    if (loginBlurryHeight + topPosition + bottomSpace > height)
      loginWrapper.style.minHeight = `calc(100vh + ${addHeight}px)`;
  }, [height, width]);
  return { reference };
};

export default BlurryWrapperLogic;
