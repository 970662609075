import React, { useEffect, useState } from "react";

import axiosInstance from "../../../../../url/createAxios";

import AddCard from "../../modalViews/AddCard/AddCard";
import CardRegistration from "../../modalViews/CardRegistration/CardRegistration";
import CardConfirmation from "../../modalViews/CardConfirmation/CardConfirmation";
import CardRetry from "../../modalViews/CardRetry/CardRetry";
import LoadingContainer from "../../../../../shared/LoadingContainer/LoadingContainer";
import { useLocation } from "react-router-dom";
import { ROUTES } from "../../../../../constants/constants";

const RegisteredCardLogic = (removeModal) => {
  const instance = axiosInstance();
  const location = useLocation();

  const [modal, setModal] = useState({});
  const [currentPage, setCurrentPage] = useState(<LoadingContainer
    loaderWrapperStyle={{ marginTop: "100px" }}
    new_styles={{ width: "100px", height: "100px" }}
  />);

  const changeModalPage = (modalData) => {
    try {
      setModal(modalData);
    } catch (error) {
      console.error(
        `${new Date()} Error in changeModalPage func inside RegisteredCardLogic.js file ${error.message
        }`
      );
    }
  };

  const pxpPage = async () => {
    try {
      const { data } = await instance.get(`/pxp/checkout?isDayUse=true`, {
        withCredentials: true,
      });

      if (data.hasRegisteredCard) {
        if (location.pathname.includes(ROUTES.RESERVATION_SECURE)) {
          changeModalPage({ page: "CardConfirmation", details: data });
        } else {
          changeModalPage({ page: "CardRetry", details: data });
        }
      } else {
        changeModalPage({ page: "AddCard", details: data });
      }
    } catch (error) {
      console.error(
        `${new Date()} Error in pxpPage in RegisteredCardLogic.js ${error.message
        }`
      );
    }
  };

  const pageDisplayHandler = async () => {
    try {
      let page = null;
      switch (modal.page) {
        case "AddCard": {
          page = (
            <>
              <AddCard
                handleClick={changeModalPage.bind(this, {
                  page: "CardRegistration",
                  details: modal.details,
                })}
                removeModal={removeModal}
              />
            </>
          );
          break;
        }
        case "CardRegistration": {
          page = (
            <CardRegistration
              changeModalPage={changeModalPage}
              details={modal.details}
              removeModal={removeModal}
            />
          );
          break;
        }
        case "CardConfirmation": {
          page = (
            <>
              <CardConfirmation
                changeModalPage={changeModalPage}
                details={modal.details}
                removeModal={removeModal}
              />
            </>
          );
          break;
        }
        case "CardRetry": {
          page = (
            <>
              <CardRetry
                changeModalPage={changeModalPage}
                removeModal={removeModal}
              />
            </>
          );
          break;
        }
        default: {
          page = (<LoadingContainer />);
        }
      }

      setCurrentPage(page);
    } catch (error) {
      console.error(
        `${new Date()} Error in pageDisplayHandler func inside RegisteredCardLogic.js file ${error.message
        }`
      );
    }
  };

  useEffect(() => {
    pxpPage();
  }, []);

  useEffect(() => {
    if (modal !== null) {
      pageDisplayHandler();
    }
  }, [modal]);


  return { currentPage };
};

export default RegisteredCardLogic;
