import React, { useState, createContext } from "react";

export const MaintenanceContext = createContext({});

export const MaintenanceProvider = ({ children }) => {
  const [maintenanceData, setMaintenanceData] = useState(null);

  return (
    <MaintenanceContext.Provider
      value={{ maintenanceData, setMaintenanceData }}
    >
      {children}
    </MaintenanceContext.Provider>
  );
};
