import React from "react";

import InputLabel from "./InputLabel";

import styles from "./Input.module.scss";

const Input = ({
  txt,
  icon,
  children,
  tooltipContent,
  isRedBorder = null,
  newStylesWrapper,
  labelNewStyles,
  tooltipNewStyles,
  loginInputNewStyles
}) => {
  return (
    <div className={styles.LoginInput} style={{...loginInputNewStyles}}>
      <div
        className={`${styles.LoginInput__action} ${
          !isRedBorder ? styles.LoginInput__action_border : ""
        }`}
        style={{ ...newStylesWrapper }}
      >
        <InputLabel
          txt={txt}
          tooltipContent={tooltipContent}
          tooltipNewStyles={tooltipNewStyles}
          icon={icon}
          labelNewStyles={labelNewStyles}
        />
        {children}
      </div>
    </div>
  );
};

export default Input;
