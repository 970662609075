import React from "react";

import styles from "../BLMarkdown.module.scss";

const MarkdownBreakLine = () => {

  return (
    <hr className={styles.MarkdownBreakLine} />
  );

};

export default MarkdownBreakLine;