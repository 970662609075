/**
	replacePlaceholders
	
	@brief Replaces all occurrences of strapi placeholders
	@params
		* text - String containing placeholders
		* replaceObject - Key-value pairs:
    - the keys are the placeholders coming from strapi for ex. SERVICE_NAME ({{SERVICE_NAME}} in Strapi)
    - the values are the replace texts
	@return String containing the replaced placeholders
**/
const replacePlaceholders = (text = "", replaceObject) => {
  const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };
  for (const key in replaceObject) {
    text = text.replace(
      new RegExp(escapeRegExp(`{{${key}}}`), "g"),
      typeof replaceObject[key] === "string"
        ? replaceObject[key].trim()
        : replaceObject[key]
    );
  }
  return text;
};

export { replacePlaceholders };
