import React from "react";
import Button from "../../../../shared/Button/Button";
import ImageCarousel from "../../../../shared/ImageCarousel/ImageCarousel";

import styles from "./DayUseActivityDetailsSection.module.scss";
import { formatPrice } from "../../../../helpingFunctions/formatPrice";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";

const DayUseActivityDetailsSection = ({
  selectedActivityDetails,
  handleShowMoreLess,
  fetchAvailability,
  fadeButton,
  showMore,
  isDesktop,
  isLoadingDayUseAvailability,
}) => {
  return (
    <>
      <div
        className={styles.day_use_activity_details_section_wrapper}
        style={{
          flexDirection: !isDesktop && showMore ? "column" : "row",
          transition: "all 0.2s ease-in-out",
        }}
      >
        <div
          style={{
            height: isDesktop
              ? showMore
                ? "400px"
                : "200px"
              : showMore
                ? "500px"
                : "195px",
            width: isDesktop
              ? showMore
                ? "50%"
                : "40%"
              : showMore
                ? "100%"
                : "40%",
            transition: "all 0.2s ease-in-out",
          }}
        >
          <ImageCarousel
            sliderImages={
              selectedActivityDetails.Images.length > 0
                ? selectedActivityDetails.Images
                : []
            }
            autoSlide
          />
        </div>
        <div
          style={{
            padding: "10px",
            width: isDesktop
              ? showMore
                ? "50%"
                : "60%"
              : showMore
                ? "100%"
                : "60%",
            transition: "all 0.2s ease-in-out",
          }}
        >
          <h1 className={styles.day_use_activity_details_title}>
            {selectedActivityDetails.Title}
          </h1>

          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            <img
              style={{
                width: "24px",
                height: "24px",
              }}
              src={`/img/day_use_icons/pricetag.svg`}
              alt=""
            />
            <p className={styles.day_use_activity_details_price}>
              {` ${formatPrice(selectedActivityDetails.Price || 0)} / person`}
            </p>
          </div>

          {showMore ? (
            <div
              style={{
                height: "auto",
                maxHeight: "270px",
                overflow: "auto",
                padding: "10px 10px 10px 0",
                gap: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  font: "normal normal normal 16px/20px Arial",
                  color: "var(--ion-color-primary)",
                }}
              >
                {selectedActivityDetails.ShortDescription}
              </p>
              <p
                style={{
                  font: "normal normal normal 16px/20px Arial",
                  color: "var(--ion-color-primary)",
                }}
              >
                <BLParagraph
                  txt={selectedActivityDetails?.LongDescription}
                  color="primary"
                  markDown
                />
              </p>
            </div>
          ) : (
            <p className={styles.day_use_activity_details_description}>
              {selectedActivityDetails.ShortDescription}
            </p>
          )}

          <p
            className={styles.day_use_activity_details_read_more}
            onClick={() => handleShowMoreLess()}
          >
            {showMore ? "See less" : "See more"}
          </p>
        </div>
      </div>

      <div
        className={`${styles.day_use_activity_details_avail_button_wrapper} ${!fadeButton || isLoadingDayUseAvailability ? styles.fade : null}`}
      >
        <Button
          txt="Show availability"
          onClick={() => fetchAvailability()}
          new_styles={{
            paddingLeft: "20px",
            paddingRight: "20px",
            border: "none",
            height: "55px",
            minHeight: "55px",
          }}
        />
      </div>
    </>
  );
};

export default DayUseActivityDetailsSection;
