const CARD_AUTHORIZATION_STEP = Object.freeze({
  headline: "Step 1 - Card Authorization",
  desc: "Your card is being authorized!",
  status: "initial",
});

const CARD_ADD_TO_RESERVATION_STEP = Object.freeze({
  headline: "Step 2 - Card added to booking",
  desc: "Adding your card to reservation!",
  status: "initial",
});

const CARD_ADD_DAY_USE = Object.freeze({
  headline: "Verifying. . .",
  desc: "We are verifying the details provided!",
  status: "initial",
});

export {
  CARD_AUTHORIZATION_STEP,
  CARD_ADD_TO_RESERVATION_STEP,
  CARD_ADD_DAY_USE,
};
