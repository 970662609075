import React, { useState, useContext } from "react";
import InformationalBoxHeavy from "../../../../shared/Containers/InformationalBoxHeavy";
import Button from "../../../../shared/Button/Button";
import { DayUseContext } from "../../../../context/DayUseContext";

import ImageCarousel from "../../../../shared/ImageCarousel/ImageCarousel";

import styles from "./DayUseBasketCard.module.scss";
import { formatPrice } from "../../../../helpingFunctions/formatPrice";
import format from "date-fns/format";

const BasketDetails = ({ selection, index, isDesktop, hideActionIcons }) => {
  const { setDayUseSelections, handleEditAction } = useContext(DayUseContext);

  const [showDialog, setShowDialog] = useState(false);

  const getWhenDetails = () => {
    return format(new Date(`${selection.date} ${selection.time.slice(0, 8)}`), "dd.MM.yyyy p");
  };

  return (
    <>
      <div className={styles.day_use_basket_card_top_wrapper}>
        <div className={styles.day_use_basket_card_details}>
          <div className={styles.day_use_basket_card_image}>
            <ImageCarousel
              sliderImages={
                selection.activityImages.length > 0
                  ? selection.activityImages
                  : []
              }
              autoSlide
            />
          </div>
          <div className={styles.day_use_basket_card_summary}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: hideActionIcons ? "100%" : isDesktop ? "75%" : "90%",
                overflow: "auto",
              }}
            >
              <h1
                className={`${styles.day_use_basket_card_title}`}
                style={{ textAlign: "left", paddingBottom: "8px" }}
              >
                {selection.activityTitle}
              </h1>

              <p
                className={`${styles.day_use_basket_card_sub_title}`}
              >
                {selection.hotelTitle}
              </p>

              <div style={{ display: "flex", gap: "16px", paddingBottom: "16px", paddingTop: "24px" }}>
                {isDesktop && (
                  <img
                    style={{
                      paddingLeft: "5px",
                      width: "24px",
                      height: "24px",
                    }}
                    src={`/img/day_use_icons/calendar.svg`}
                    alt=""
                  />
                )}
                <p>{getWhenDetails()}</p>
              </div>
              <div style={{ display: "flex", gap: "16px", paddingBottom: "16px" }}>
                {isDesktop && (
                  <img
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                    src={`/img/day_use_icons/open-people.svg`}
                    alt=""
                  />
                )}
                <p>{`${selection.quantity} ${selection.quantity > 1 ? "adults" : "adult"}`}</p>
              </div>
              <div style={{ display: "flex", gap: "16px" }}>
                {isDesktop && (
                  <img
                    style={{
                      width: "24px",
                      height: "24px",
                    }}
                    src={`/img/day_use_icons/pricetag.svg`}
                    alt=""
                  />
                )}
                <p
                  style={{
                    fontWeight: "bold",
                    width: "80%",
                    textAlign: "left",
                  }}
                >{`Total: ${formatPrice(parseFloat(selection.activityPricePP) * selection.quantity || 0)}`}</p>
              </div>
            </div>
            {!hideActionIcons && (
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  width: isDesktop ? "110px" : "25px",
                  alignItems: "end",
                }}
              >
                {!hideActionIcons && (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowDialog(true)}
                  >
                    <img
                      style={{
                        paddingLeft: "5px",
                        width: "25px",
                        height: "25px",
                      }}
                      src={`/img/day_use_icons/error_teal.svg`}
                      alt=""
                    />
                    {isDesktop && (
                      <p
                        style={{
                          color: "var(--ion-color-primary)",
                          textDecoration: "underline",
                          font: "normal normal 600 18px/22px Arial"
                        }}
                      >
                        {"Remove"}
                      </p>
                    )}
                  </div>
                )}
                {!hideActionIcons && (
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleEditAction(index)}
                  >
                    <img
                      style={{
                        paddingLeft: "5px",
                        width: "25px",
                        height: "25px",
                      }}
                      src={`/img/day_use_icons/edit-pencil_teal.svg`}
                      alt=""
                    />
                    {isDesktop && (
                      <p
                        style={{
                          color: "var(--ion-color-primary)",
                          textDecoration: "underline",
                          font: "normal normal 600 18px/22px Arial"
                        }}
                      >
                        {"Edit"}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {showDialog ? (
        <InformationalBoxHeavy
          title={
            "Are you sure you want to remove the experience from your basket?"
          }
          content={
            <div
              style={{
                gap: "20px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                txt={"No"}
                onClick={() => setShowDialog(false)}
                color={"black"}
                type="primary-inverse"
              />
              <Button
                txt={"Yes"}
                onClick={() => {
                  setDayUseSelections((prevState) => {
                    const filteredSelections = prevState.filter(
                      (row, indexRow) => indexRow !== index
                    );
                    sessionStorage.setItem(
                      "basket",
                      JSON.stringify(filteredSelections)
                    );
                    return filteredSelections;
                  });
                  setShowDialog(false);
                }}
                type="primary-inverse"
              />
            </div>
          }
          boxNewStyles={{ marginTop: "20px", marginBottom: "20px" }}
        />
      ) : null}
    </>
  );
};

export default BasketDetails;
