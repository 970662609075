import React, { useState, useMemo } from "react";

import SingleSelect from "../../../../components/SingleSelect/SingleSelect";

import DetailsDialog from "./ActivityDetailsPopupBox/DetailsDialog";
import styles from "./DayUseHeaderSection.module.scss";

const DayUseHeaderSection = ({
  dayUseDetails,
  hotelData,
  updateHotelSelection,
  selectedHotel,
  hideSelection,
  manualDropdownOpen,
  setManualDropdownOpen,
  isDesktop
}) => {
  const [showHotelDetails, setShowHotelDetails] = useState(false);

  const getSelHotelImage = () => {
    return selectedHotel?.Images.length > 0
      ? {
        backgroundImage: `url(${selectedHotel.Images[0].url})`,
      }
      : { backgroundColor: "var(--ion-color-primary)" };
  };

  const filteredAndSortedHotels = useMemo(() => {
    return hotelData
      ?.filter((hotel) => hotel.AllowDayUseAppearance)
      .sort((a, b) => a.Title.localeCompare(b.Title));
  }, [hotelData]);

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className={styles.day_use_header_section_header_wrapper}
          style={getSelHotelImage()}
        />
        <div className={styles.day_use_header_section_hotel_sel_wrapper}>
          {!hideSelection && (
            <>
              {selectedHotel && (
                <img
                  onClick={() => setShowHotelDetails(true)}
                  className={styles.day_use_header_section_icon}
                  src={`/img/day_use_icons/info-information.svg`}
                  alt=""
                />
              )}
              <div
                className={styles.day_use_header_section_hotel_dropdown_wrapper}
              >
                {hotelData ? (
                  <SingleSelect
                    manualDropdownOpen={manualDropdownOpen}
                    setManualDropdownOpen={setManualDropdownOpen}
                    data={filteredAndSortedHotels}
                    placeholder={"Select a hotel"}
                    onChange={(event) =>
                      updateHotelSelection(event.target.value)
                    }
                    disabled={
                      hotelData.filter((hotel) => hotel.AllowDayUseAppearance)
                        .length === 1
                    }
                    value={dayUseDetails?.hotelId}
                    style={{
                      marginLeft: "16px",
                      marginRight: "16px",
                      marginTop: 0,
                    }}
                  />
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>

      {showHotelDetails ? (
        <DetailsDialog
          hotelDetails={selectedHotel}
          handleReadMore={setShowHotelDetails}
          isDesktop={isDesktop}
          showHotelDetails={showHotelDetails}
        />
      ) : null}
    </>
  );
};

export default DayUseHeaderSection;
