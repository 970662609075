import React,{ useContext } from "react";

import LogoAndTitle from "../../components/LogoAndTitle/LogoAndTitle";
import Button from "../../../../../shared/Button/Button";
import InformationText from "../../../../../shared/InformationText/InformationText";
import styles from "./CardConfirmation.module.scss";
import { DayUseContext } from "../../../../../context/DayUseContext";

const CardConfirmation = ({ details = {}, removeModal }) => {
  const { dayUseTexts } = useContext(DayUseContext);
  return (
    <>
      <LogoAndTitle
        removeModal={removeModal}
        title={"Registered Credit Card"}
      />
      <div className={styles.CardConfirmation}>
        <ul className={styles.CardConfirmation__items}>
          <li className={styles.CardConfirmation__item}>
            <h4>Date of registration</h4>
            <p>{details.transactionDate}</p>
          </li>
          <li className={styles.CardConfirmation__item}>
            <h4>Description</h4>
            <p>Registration of Credit Card</p>
          </li>
          <li className={styles.CardConfirmation__item}>
            <h4>Card Type</h4>
            <p>{details.cardType}</p>
          </li>
          <li className={styles.CardConfirmation__item}>
            <h4>Card Last 4 Digits</h4>
            <p>{details.cardNums}</p>
          </li>
        </ul>
      </div>
      <InformationText
        content={dayUseTexts?.CreditCard?.Paragraph_ConfirmationText}
        new_styles={{
          marginTop: "25px",
          padding: "16px",
        }}
        markdown
      />
      <div className={styles.CardConfirmation__button}>
        <Button
          txt="Proceed"
          onClick={removeModal}
          new_styles={{ backgroundColor: "var(--ion-color-primary)" }}
        />
      </div>
    </>
  );
};

export default CardConfirmation;
