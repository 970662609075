import { useState, useEffect } from "react";

import axiosInstance from "../../../../../url/createAxios";

const BookedDayUseViewLogic = (
  isCancelled,
  setIsCancelled,
  bookedDayUseForm,
  setBookedDayUseForm
) => {
  const instance = axiosInstance();
  const [bookedDayUse, setBookedDayUse] = useState({});
  const [isLoading, setisLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [isValidForm, setIsValidForm] = useState(true);

  const manageForm = (key, value) => {
    const updatedForm = JSON.parse(JSON.stringify(bookedDayUseForm));
    updatedForm[key].data = value ? value : "";
    setBookedDayUseForm(updatedForm);
  };

  const handleForm = async () => {
    setBookedDayUse({});
    const isFormValid = validateForm();
    setIsCancelled(null);
    setShowDialog(false);
    if (isFormValid) {
      await getBooked();
    }
  };

  const validateForm = () => {
    const formCopy = JSON.parse(JSON.stringify(bookedDayUseForm));

    let isValid = true;

    if (formCopy.hotelId.data === "") {
      formCopy.hotelId.hasError = true;
      isValid = false;
    } else formCopy.hotelId.hasError = false;

    formCopy.lastName.hasError = false;

    if (formCopy.bookingId.data.trim() === "") {
      formCopy.bookingId.hasError = true;
      isValid = false;
    } else formCopy.bookingId.hasError = false;

    setBookedDayUseForm(formCopy);
    setIsValidForm(isValid);
    return isValid;
  };

  const getBooked = async () => {
    try {
      setisLoading(true);
      let { data } = await instance.post(
        "/day-use-bookings/fetch",
        {
          hotelId: bookedDayUseForm.hotelId.data,
          bookingId: bookedDayUseForm.bookingId.data,
          lastName: bookedDayUseForm.lastName.data.trim(),
        },
        {
          withCredentials: true,
        }
      );

      setBookedDayUse(data);
      setisLoading(false);
    } catch (error) {
      console.error(error);
      setBookedDayUse({
        error: "Failed to load requested booking details. Please try again.",
      });
      setisLoading(false);
    }
  };

  useEffect(() => {
    setIsCancelled(null);
  }, [showDialog]);

  useEffect(() => {
    if (isCancelled === "success") {
      setBookedDayUse({});
    }
  }, [isCancelled]);

  return {
    bookedDayUseForm,
    isLoading,
    bookedDayUse,
    showDialog,
    isValidForm,
    manageForm,
    handleForm,
    setShowDialog,
    validateForm,
  };
};

export default BookedDayUseViewLogic;
