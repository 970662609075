import React, { useContext, useEffect } from "react";
import Heading from "../../../../shared/Heading/Heading";
import LoadingContainer from "../../../../shared/LoadingContainer/LoadingContainer";
import Button from "../../../../shared/Button/Button";
import Input from "../../../../components/Input/Input";
import SelectSearch from "../../../../components/SelectSearch/SelectSearch";
import RegistrationScreenNav from "../SectionComponents/Navigation/RegistrationScreenNav";

import RegistrationViewLogic from "./Logic/RegistrationViewLogic";
import styles from "./styles/RegistrationView.module.scss";
import { DayUseContext } from "../../../../context/DayUseContext";
import PersonalDetailsDisclaimer from "../../../../components/PersonalDetailsDisclaimer/PersonalDetailsDisclaimer";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../constants/constants";

const RegistrationView = ({ isDesktop }) => {
  const {
    registrationFormData,
    setRegistrationFormData,
    revertDayUseForm,
    initiateBookingProcedure,
    isProcessingDayUseBookings,
    processedRecordsResults,
    dayUseOccasions,
    dayUseSelections,
  } = useContext(DayUseContext);

  const history = useHistory();

  useEffect(() => {
    if (!dayUseSelections?.length) {
      history.push(ROUTES.BASKET);
    }
  }, []);

  const { COUNTRIES, autoGenerateFormFields, manageForm, validateForm, errMsg } =
    RegistrationViewLogic(
      registrationFormData,
      setRegistrationFormData,
      initiateBookingProcedure
    );

  const errBox = () => {
    return (
      <>
        {errMsg && (
          <div
            className={`${styles.day_use_register_view_wrapper} ${styles.day_use_reg_view_error_wrapper}`}
          >
            <img
              className={styles.day_use_params_sel_section_icon}
              src={`/img/info_icons/exclamation.svg`}
              alt="no img"
            />
            <p style={{ paddingLeft: "15px" }}>{errMsg}</p>
          </div>
        )}

        {isProcessingDayUseBookings ? (
          <LoadingContainer />
        ) : processedRecordsResults.hasOwnProperty("error") ? (
          <div
            className={`${styles.day_use_register_view_wrapper} ${styles.day_use_reg_view_error_wrapper}`}
          >
            <img
              className={styles.day_use_params_sel_section_icon}
              src={`/img/info_icons/exclamation.svg`}
              alt="no img"
            />
            <p style={{ color: "white", paddingLeft: "15px" }}>
              {processedRecordsResults.error}
            </p>
          </div>
        ) : null}
      </>
    );
  };

  const generateForm = () => {
    const rows = {
      row1: [],
      row2: [],
    };

    autoGenerateFormFields.forEach(
      (
        {
          label,
          key,
          placeholder,
          hasError,
          dataType,
          desktopRowPos,
          desktopWidth,
        },
        index
      ) => {
        const keyWord = "row" + desktopRowPos;
        let comp;
        if (key !== "county") {
          comp = (
            <Input
              key={`${key}_${index}`}
              txt={label}
              newStylesWrapper={{
                paddingTop: "16px",
                paddingBottom: "16px",
              }}
              labelNewStyles={{
                paddingBottom: "8px",
                font: "normal normal 600 16px/22px Arial",
                color: "var(--ion-color-primary)",
              }}
              loginInputNewStyles={{ width: isDesktop ? desktopWidth : "100%" }}
              isRedBorder={hasError}
            >
              <input
                type={dataType}
                placeholder={placeholder}
                style={{
                  border: hasError ? "1px solid var(--ion-color-danger)" : "",
                  height: "48px",
                }}
                value={registrationFormData[key]}
                onChange={(e) => manageForm(key, e.target.value)}
              />
            </Input>
          );
        } else {
          comp = (
            <Input
              txt={label}
              newStylesWrapper={{
                paddingTop: "16px",
                paddingBottom: "16px",
              }}
              loginInputNewStyles={{ width: isDesktop ? desktopWidth : "100%" }}
              isRedBorder={hasError}
              labelNewStyles={{
                paddingBottom: "8px",
                font: "normal normal 600 16px/22px Arial",
                color: "var(--ion-color-primary)",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <SelectSearch
                  data={COUNTRIES}
                  placeholder={COUNTRIES[0].Title}
                  manageFormData={(value) => manageForm("county", value)}
                  selectedValueId={registrationFormData.county}
                  newAutoCompleteWrapperStyles={{
                    width: "100%",
                    border: hasError ? "1px solid var(--ion-color-danger)" : "",
                  }}
                  textFieldWrapperHeight={48}
                />
              </div>
            </Input>
          );
        }

        if (isDesktop) {
          rows[keyWord].push(comp);
        } else {
          rows["row1"].push(comp);
        }
      }
    );
    return rows;
  };

  return (
    <>
      <RegistrationScreenNav />

      <Heading
        txt={"Your contact details"}
        new_styles={{
          paddingBottom: "24px",
          textAlign: "center",
          fontFamily: "Georgia",
          fontSize: "26px",
          textTransform: "uppercase",
          color: "var(--ion-color-primary)",
        }}
      />
      <div className={styles.day_use_register_view_wrapper}>
        {isDesktop
          ? Object.entries(generateForm()).map(([key, value]) => {
            return (
              <div
                key={key}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {value}
              </div>
            );
          })
          : generateForm().row1.map((row) => {
            return (
              <div key={row.key} style={{ display: "flex", justifyContent: "center" }}>
                {row}
              </div>
            );
          })}
        {dayUseOccasions.length > 0 &&
          <Input
            txt={"Occasion"}
            newStylesWrapper={{ paddingTop: "15px", paddingBottom: "15px" }}
            loginInputNewStyles={{ width: isDesktop ? "56%" : "100%" }}
            labelNewStyles={{
              paddingBottom: "8px",
              font: "normal normal 100 16px/22px Arial",
              color: "var(--ion-color-primary)",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <SelectSearch
                data={dayUseOccasions}
                placeholder={dayUseOccasions[0]?.Title}
                manageFormData={(value) => manageForm("occasion", value)}
                selectedValueId={registrationFormData.occasion}
                newAutoCompleteWrapperStyles={{
                  width: "100%",
                }}
                textFieldWrapperHeight={48}
              />
            </div>
          </Input>
        }
        <div style={{marginTop: "16px"}}>
          <PersonalDetailsDisclaimer />
        </div>
      </div>

      {errBox()}

      <div className={styles.day_use_reg_view_btns_wrapper}>
        <Button
          txt="Cancel"
          type="primary-inverse"
          onClick={() => {
            revertDayUseForm();
            history.push(ROUTES.BASKET);
          }}
        />

        <Button
          txt={"Continue"}
          onClick={() => {
            const numIssues = validateForm();

            if (numIssues === 0) {
              history.push(ROUTES.RESERVATION_SECURE);
            }
          }}
        />
      </div>
    </>
  );
};

export default RegistrationView;
