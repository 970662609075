import { IonContent } from "@ionic/react";
import DayUseProvider from "../context/DayUseContext";
import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import ScrollToTop from "../hooks/ScrollToTop";
import MaintenancePage from "../views/children/MaintenancePage/MaintenancePage";
import LoginPage from "../views/children/LoginPage/LoginPage";
import RouteLogic from "./RouteLogic";
import PrivacyAndPolicies from "../views/children/PrivacyAndPolicies/PrivacyAndPolicies";
import CookiePolicy from "../views/children/CookiePolicy/CookiePolicy";
import MainFooter from "../components/MainFooter/MainFooter";
import DayUseWrapper from "../views/children/DayUsePage/DayUseWrapper";
import BookedDayUseView from "../views/children/DayUsePage/Views/BookedDayUseView";
import LocationsDetailsView from "../views/children/DayUsePage/Views/LocationsDetailsView";
import LocationDetailsView from "../views/children/DayUsePage/Views/LocationDetailsView";
import BasketDetailsView from "../views/children/DayUsePage/Views/BasketDetailsView";
import ActivitiesSelectionsView from "../views/children/DayUsePage/Views/ActivitiesSelectionsView";
import RegistrationView from "../views/children/DayUsePage/Views/RegistrationView";
import RegistrationViewAddCardView from "../views/children/DayUsePage/Views/RegistrationViewAddCardView";
import RegistrationViewPreConfirmView from "../views/children/DayUsePage/Views/RegistrationViewPreConfirmView";
import ConfirmationScreenView from "../views/children/DayUsePage/Views/ConfirmationScreenView";
import { ROUTES } from "../constants/constants";

const Index = () => {
  const { scrollRef, isDesktop, scrollToTop } = RouteLogic();
  
  const [manualDropdownOpen, setManualDropdownOpen] = useState(false);

  return (
    <>
      <ScrollToTop />

      <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex", flex: "1" }}>
          <IonContent forceOverscroll={false} id="content" className="key-board-offset-reset">
            <Switch>
              <Route exact path={ROUTES.MAINTENANCE} component={MaintenancePage} />
              <Route path={ROUTES.PRIVACY} component={PrivacyAndPolicies} />
              <Route path={ROUTES.COOKIE} component={CookiePolicy} />
              <DayUseProvider>
                <Route path={[
                  ROUTES.MY_BOOKING, 
                  ROUTES.HOTEL_LOCATIONS, 
                  ROUTES.HOTEL_LOCATION, 
                  ROUTES.BASKET, 
                  ROUTES.SEARCH_EXPERIENCE_HOTEL,
                  ROUTES.RESERVATION_REGISTER,
                  ROUTES.RESERVATION_SECURE,
                  ROUTES.RESERVATION_CONFIRM,
                  ROUTES.RESERVATION_SUMMARY,
                ]}>
                  <DayUseWrapper
                    scrollRef={scrollRef}
                    manualDropdownOpen={manualDropdownOpen}
                    setManualDropdownOpen={setManualDropdownOpen}
                  >
                    <Route exact path={ROUTES.MY_BOOKING}>
                      <BookedDayUseView />
                    </Route>
                    <Route path={ROUTES.MY_BOOKING_SEARCH}>
                      <BookedDayUseView />
                    </Route>
                    <Route exact path={ROUTES.HOTEL_LOCATIONS}>
                      <LocationsDetailsView
                        manualDropdownOpen={manualDropdownOpen}
                        setManualDropdownOpen={setManualDropdownOpen}
                      />
                    </Route>
                    <Route path={ROUTES.HOTEL_LOCATION}>
                      <LocationDetailsView
                        isDesktop={isDesktop}
                        setManualDropdownOpen={setManualDropdownOpen}
                        manualDropdownOpen={manualDropdownOpen}
                      />
                    </Route>
                    <Route path={ROUTES.SEARCH_EXPERIENCE_HOTEL}>
                      <ActivitiesSelectionsView
                        scrollToTop={scrollToTop}
                        isDesktop={isDesktop}
                      />
                    </Route>
                    <Route path={ROUTES.BASKET}>
                      <BasketDetailsView isDesktop={isDesktop} />
                    </Route>
                    <Route path={ROUTES.RESERVATION_REGISTER}>
                      <RegistrationView isDesktop={isDesktop} />
                    </Route>
                    <Route path={ROUTES.RESERVATION_SECURE}>
                      <RegistrationViewAddCardView />
                    </Route>
                    <Route path={ROUTES.RESERVATION_CONFIRM}>
                      <RegistrationViewPreConfirmView isDesktop={isDesktop} />
                    </Route>
                    <Route path={ROUTES.RESERVATION_SUMMARY}>
                      <ConfirmationScreenView />
                    </Route>
                  </DayUseWrapper>
                </Route>
                <Route
                  exact
                  path={ROUTES.HOME}
                  render={() => <LoginPage/>}
                />
              </DayUseProvider>
            </Switch>
          </IonContent>
        </div>
        <div style={{ flex: "0" }}>
          <MainFooter />
        </div>
      </div>
    </>
  );
};

export default Index;
