import React from "react";

import Heading from "../../../../../shared/Heading/Heading";
import { xSign } from "../../../../../assets/SVGIcons";
import BLParagraph from "../../../../../shared/Ionic/Paragraph/BLParagraph";
import styles from "./LogoAndTitle.module.scss";

const LogoAndTitle = ({removeModal, title}) => {
  return (
    <>
      <div className={styles.LogoAndTitle__imageWrapper}>
        <BLParagraph txt="Day Experiences" color="secondary" newClasses="x-large georgia"/>
        <div
          className={styles.LogoAndTitle__cancel}
          onClick={removeModal}
        >
          {xSign("24px", "var(--ion-color-secondary)", "var(--ion-color-primary)")}
        </div>
      </div>
      <Heading
        txt={title}
        new_styles={{ fontSize: "22px", paddingTop: "35px" }}
      />
    </>
  );
};

export default LogoAndTitle;
