import React, { useContext } from "react";
import { DayUseContext } from "../../../../context/DayUseContext";

import styles from "./styles/BasketDetailsView.module.scss";
import Heading from "../../../../shared/Heading/Heading";
import DayUseBasketCard from "../SectionComponents/DayUseBasketCard";
import InformationalBox from "../../../../shared/Containers/InformationalBox";
import Button from "../../../../shared/Button/Button";
import BLParagraph from "../../../../shared/Ionic/Paragraph/BLParagraph";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../constants/constants";

const BasketDetailsView = ({ isDesktop }) => {
  const {
    dayUseTexts,
    refreshSearchParams,
    dayUseSelections,
    setDayUseSelections,
    handleEditAction,
  } = useContext(DayUseContext);

  const history = useHistory();

  return (
    <>
      <Heading
        txt={"Your reservation basket"}
        new_styles={{
          paddingBottom: "24px",
          textAlign: "center",
          fontFamily: "Georgia",
          fontSize: "26px",
          textTransform: "uppercase",
          color: "var(--ion-color-primary)",
        }}
      />

      {dayUseSelections.length > 0 ? (
        <div>
          {dayUseSelections?.map((sel, index) => (
            <DayUseBasketCard
              key={index}
              selection={sel}
              index={index}
              setSelections={setDayUseSelections}
              handleEditAction={handleEditAction}
              isDesktop={isDesktop}
            />
          ))}
          <div style={{ textAlign: "center", marginBottom: 16 }}>
            <BLParagraph 
              txt={dayUseTexts?.Paragraph_AdultOnlyDisclaimer1} 
              color="primary"
              markDown  
            />
          </div>
          <div className={styles.day_use_basket_details_btns_wrapper}>
            <Button 
              txt="Add more experiences"
              onClick={() => refreshSearchParams()}
              type="primary-inverse"
            />

            <Button
              txt={"Reserve now"}
              onClick={() => history.push(ROUTES.RESERVATION_REGISTER)}
            />
          </div>
        </div>
      ) : (
        <div className={styles.day_use_empty_basket_wrapper}>
          <InformationalBox
            content={`Your reservation basket is currently empty!`}
            boxNewStyles={{ width: "100%", justifyContent: "center" }}
          />
          <div style={{ paddingTop: "20px" }}>
            <Button
              txt={"Add more experiences"}
              onClick={() => refreshSearchParams()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BasketDetailsView;
